import {Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paperClass: {
        padding: theme.spacing(4),
        height: "100vh"
    }
}));

export const NotFound = () => {
    const classes = useStyles();
    return (
        <Paper elevation={3} className={classes.paperClass}>
            <h1>404 Page introuvable</h1>
        </Paper>
    )
}

export default NotFound;