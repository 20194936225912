import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid, DialogActions, Button, TextField, Select, Chip, Radio, Checkbox, FormControlLabel, RadioGroup, FormGroup, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import http from "../../http-common";
import generateStyledExcelFile from './exportExcel';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from "@material-ui/lab/Autocomplete";
import titleFromRecherche from './titreExport';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    filterDates: {
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        marginLeft: 15,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    transition: {
        overflow: 'hidden',
        transition: 'all 1s ease-in-out', // Ajouter une transition de 1 seconde
    }
}));




export const Statistiques = () => {
    const defaultRecherche = { typeData: "adherent", sortie: "ListeFiches" }
    const classes = useStyles();
    const [personName, setPersonName] = React.useState([]);
    const dispatch = useDispatch();

    const texts = useSelector(state => state.texts);
    // const langueCharger = texts.langueCharger;
    const [rechercheCourrante, setRechercheCourrante] = useState(defaultRecherche)//current row
    const [loading, setLoading] = useState(true);
    const [snack, setSnack] = React.useState({});
    const [activeTableIsActivity, setActiveTableIsActivity] = React.useState(false);
    const [activeTableIsAAttribution, setActiveTableIsAttribution] = React.useState(false);
    const [activeTableIsAdherent, setActiveTableIsAdherent] = React.useState(true);


    const [categoriesList, setCategoriesList] = useState([]);
    const [catChoosen, setCatChoosen] = React.useState([]);
    const [disciplinesList, setDisciplineList] = useState([]);
    const [discChoosen, setDiscChoosen] = React.useState([]);
    const [periodList, setPeriodsList] = useState([]);
    const [periodChoosen, setPeriodsChoosen] = useState([]);
    const [localiteList, setLocaliteList] = useState([]);
    const [localiteChoosen, setLocaliteChoosen] = useState([]);

    const [buttonDisabled, setDisabled] = useState(false);

    const [placeHolderLabel, setPlaceHolderLabel] = useState('');

    const [valueCat, setValueCat] = useState([]);
    const [valueDisc, setValueDisc] = useState([]);
    const [valuePeriod, setValuePeriod] = useState([]);
    const [valueLoc, setValueLoc] = useState([]);
    const getInputLabel = () => {
        return !rechercheCourrante.periode || rechercheCourrante.periode?.length === 0
            ? texts.label_placeholder_periode //"Si pas de période choisie, seules les périodes de l'année en cours seront affichées";
            : '';
    }

    useEffect(() => {
        setLoading(true);
        const getCategoriesPromise = http.get(`/portail/enum/getCategories`);
        const getPeriodesPromise = http.get(`portail/enum/getPeriod`);
        const getLocalitePromise = http.get(`portail/enum/getLocalites`);
        const getDisciplinePromise = http.get(`/portail/enum/getDisciplines`);

        Promise.all([
            getCategoriesPromise,
            getPeriodesPromise,
            getLocalitePromise,
            getDisciplinePromise,
        ]).then(
            function (values) {
                setCategoriesList(values[0].data);
                setPeriodsList(values[1].data);
                setLocaliteList(values[2].data);
                setDisciplineList(values[3].data);
                setLoading(false);
                setPersonName('ListeFiches');
            },
            (error) => {
                console.log(error);
                setLoading(false)
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        setPlaceHolderLabel(getInputLabel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rechercheCourrante]);

    const cleanedRecherche = (ob) => {
        const filteredEntries = Object.entries(ob).filter(([key, value]) => {
            if ((key === "actif" || key === "genreF" || key === "genreM") && value === false) {
                return false;
            }
            if ((key === "localite" || key === "categorie" || key === "discipline" || key === "periodes") && value.length === 0) {
                return false;
            }
            return value !== ""

        });// Filtrer les entrées
        const cleanedObject = Object.fromEntries(filteredEntries);// Créer un nouvel objet à partir des entrées filtrées

        return cleanedObject;
    };

    const getRecherche = (e) => {
        setLoading(true);
        let params = { recherche: JSON.stringify(cleanedRecherche(rechercheCourrante)) };
        setDisabled(true);

        http.post('/portail/satistiques/get', params).then((response) => {
            if (response.data.error) {
                setSnack({ message: texts[response.data.message], show: true, severity: "error" })
            } else {
                setSnack({ message: texts[response.data.message], show: true, severity: "success" });
                if (response.data.liste && response.data.liste.length > 0) {
                    // xls file 
                    let title = titleFromRecherche(cleanedRecherche(rechercheCourrante), periodList, categoriesList, disciplinesList, texts);
                    generateStyledExcelFile(response.data.liste, rechercheCourrante.typeData, title);
                } else {
                    setSnack({ message: texts.errorStatistiquesRetour, show: true, severity: "error" });
                }
            }
            setLoading(false);
            //reable the button
            setDisabled(false);
        }, (error) => {
            setLoading(false);
            //reable the button
            setDisabled(false);
            console.log(error);
        });
    };

    const handleChangeForm = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setRechercheCourrante({ ...rechercheCourrante, [name]: value });
    };

    const handleChangeTable = (event) => {
        setActiveTableIsActivity(event.target.value === 'activites');
        setActiveTableIsAttribution(event.target.value === 'attribution');
        setActiveTableIsAdherent(event.target.value === 'adherent');
        handleChangeSelect({ target: { type: 'radio', name: 'typeData', value: 'ListeFiches' } });
        handleChangeForm(event);
        restForm(event.target.value);
    }

    const restForm = (value) => {
        setValueCat([]);
        setValueDisc([]);
        setValuePeriod([]);
        setValueLoc([]);
        setRechercheCourrante({
            ...defaultRecherche,
            typeData: value,
            genreM: false,
            genreF: false,
            actif: false,
            DateDemission: '',
            DateDemissionFin: '',
            DateNaissanceDebut: '',
            DateNaissanceFin: '',
        });
    }

    const handleChangeSelect = (event) => {
        let val = event.target.value === '' ? 'ListeFiches' : event.target.value;
        setPersonName(val);
        handleChangeForm(event);
    };

    const handleChangeCat = (event, value) => {
        setValueCat(value);
        event.choose = catChoosen;
        setCatChoosen(value);
        let val = value.map(val => val.id);
        let name = 'categorie';
        setRechercheCourrante({ ...rechercheCourrante, [name]: val });
    };

    const handleChangeDisc = (event, value) => {
        setValueDisc(value);
        event.choose = discChoosen;
        setDiscChoosen(value);
        let val = value.map(val => val.id);
        let name = 'discipline';
        setRechercheCourrante({ ...rechercheCourrante, [name]: val });
    };

    const handleChangePeriode = (event, value) => {
        setValuePeriod(value);
        event.periodChoos = periodChoosen;
        setPeriodsChoosen(value);
        let val = value.map(val => val.id);
        let name = 'periode';
        setRechercheCourrante({ ...rechercheCourrante, [name]: val });
    };

    const handleChangeLocalite = (event, value) => {
        setValueLoc(value);
        event.localiteChoos = localiteChoosen;
        setLocaliteChoosen(value);
        let val = value.map(val => val.id);
        let name = 'localite';
        setRechercheCourrante({ ...rechercheCourrante, [name]: val });
    };

    return (
        <React.Fragment>
            <MySnackbar params={snack} />
            <LoadingIndicator open={loading} />
            <main className={classes.content}>
                <FormControl
                    // fullScreen
                    fullWidth={true}
                    open={true}
                    aria-labelledby="max-width-dialog-title"
                    style={{ boxShadow: "6px 9px 18px 1px rgba(0,0,0,0.75)", borderRadius: "10px" }}
                >
                    <FormLabel style={{ background: "#564146", color: "#FFFFFF", padding: "30px", borderRadius: "10px 10px 0px 0px" }}>{texts.title_stat_search}</FormLabel>
                    <Grid container spacing={2} style={{ position: "relative", padding: " 0 20px" }}>
                        <Grid item md={12} xs={12}>
                            <h3>Données souhaitées</h3>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <RadioGroup row aria-label="conscerne" name="typeData" defaultValue="adherent" onChange={handleChangeTable}>
                                <FormControlLabel value="adherent" control={<Radio color="primary" />} label={texts.title_members} labelPlacement="start" />
                                <FormControlLabel value="attribution" control={<Radio color="primary" />} label={texts.label_attribution} labelPlacement="start" />
                                <FormControlLabel value="activites" control={<Radio color="primary" />} label={texts.title_activities} labelPlacement="start" />
                            </RadioGroup>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="demo-sortie-select-required-label">{texts.label_sortie}</InputLabel>
                                <Select
                                    labelId="demo-sortie-select-label"
                                    id="demo-sortie-select"
                                    value={personName}
                                    onChange={handleChangeSelect}
                                    label={texts.label_sortie}
                                    fullWidth
                                    name="sortie"
                                >
                                    <MenuItem value="ListeFiches">{texts.label_ListeFiches}</MenuItem>
                                    {!activeTableIsAdherent && <MenuItem value="NombreFichesCategoriePeriode">{texts.label_nbFileByCat}</MenuItem>}
                                    {!activeTableIsAdherent && <MenuItem value="NombreFichesDiciplinePeriode">{texts.label_nbFileByDisc}</MenuItem>}
                                    {!activeTableIsActivity && <MenuItem value="NombreFichesTranchesAgePeriode">{texts.label_nbFileByTranche}</MenuItem>}
                                    {!activeTableIsActivity && <MenuItem value="NombreFichesLocalitePeriode">{texts.label_nbFileByLocation}</MenuItem>}
                                    {!activeTableIsActivity && <MenuItem value="NombreFichesGenrePeriode">{texts.label_nbFileByGenre}</MenuItem>}
                                    {activeTableIsActivity && <MenuItem value="NombreFichesPersonnesInscritesActivite">{texts.label_nbMeberINActivity}</MenuItem>}
                                    {activeTableIsActivity && (periodList.length > 0) && <MenuItem value="NombreFichesInscriptionsParTemps">{texts.label_nbInscriptioParTemps}</MenuItem>}
                                    {activeTableIsActivity && <MenuItem value="NombreFichesListeDattente">{texts.label_nbListeDattente}</MenuItem>}
                                    {activeTableIsActivity && <MenuItem value="NombreFichesAdherentsPapierOuPortail">{texts.label_nbAdherantPapierPortails}</MenuItem>}
                                    {activeTableIsAdherent && <MenuItem value="NombreFichesAdherantsInscrits">{texts.label_AdherantInscrit}</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <h3>{texts.label_filtres}</h3>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControl className={classes.formControl} width="70%" fullWidth>
                                <Autocomplete
                                    multiple
                                    id="vSelectPeriodes"
                                    value={valuePeriod}
                                    options={periodList}
                                    getOptionLabel={(option) => option.text}
                                    defaultValue={[]}
                                    name="periodes"
                                    // disabled={!(activeTableIsActivity || activeTableIsAAttribution)}
                                    onChange={handleChangePeriode}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            name="periodes"
                                            label={texts.visitForm_autoCompPeriodLabel}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option.id}
                                                color="primary"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                />
                            </FormControl>
                            <Typography style={{ marginBottom: '-20px', color: '#aaa', fontSize: ' 0.9em' }}>{placeHolderLabel}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl className={classes.formControl} width="70%" fullWidth>
                                <Autocomplete
                                    multiple
                                    value={valueCat}
                                    id="categorie"
                                    options={categoriesList}
                                    getOptionLabel={(option) => option.text}
                                    defaultValue={[]}
                                    onChange={handleChangeCat}
                                    name="categorie"
                                    disabled={!(activeTableIsActivity || activeTableIsAAttribution)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            id="categorie"
                                            name="categorie"
                                            label={texts.categories}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option.id}
                                                color="primary"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl className={classes.formControl} width="70%" fullWidth>
                                <Autocomplete
                                    multiple
                                    id="discipline"
                                    value={valueDisc}
                                    options={disciplinesList}
                                    getOptionLabel={(option) => option.text}
                                    defaultValue={[]}
                                    onChange={handleChangeDisc}
                                    name="discipline"
                                    disabled={!(activeTableIsActivity || activeTableIsAAttribution)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            id="discipline"
                                            name="discipline"
                                            label={texts.disciplines}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option.id}
                                                color="primary"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid container md={12} xs={12} style={{
                            opacity: activeTableIsActivity ? 0 : 1,
                            maxHeight: activeTableIsActivity ? 0 : '1000px'
                        }}
                            className={classes.transition}>
                            <Grid item md={3} xs={12} style={{ padding: '4px' }}>
                                <h5>{texts.label_dateResignation}</h5>
                                <TextField
                                    name="DateDemission"
                                    type="date"
                                    label={texts.label_befor_date}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChangeForm}
                                    value={rechercheCourrante['DateDemission'] || ''}
                                    disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ padding: '4px' }}>
                                <h5 style={{ color: "#fff" }}>.</h5>
                                <TextField
                                    name="DateDemissionFin"
                                    type="date"
                                    label={texts.label_after_date}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChangeForm}
                                    value={rechercheCourrante['DateDemissionFin'] || ''}
                                    disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ padding: '4px' }}>
                                <h5>{texts.label_birth}</h5>
                                <TextField
                                    name="DateNaissanceDebut"
                                    type="date"
                                    label={texts.label_befor_date}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChangeForm}
                                    value={rechercheCourrante['DateNaissanceDebut'] || ''}
                                    disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ padding: '4px' }}>
                                <h5 style={{ color: "#fff" }}>.</h5>
                                <TextField
                                    name="DateNaissanceFin"
                                    type="date"
                                    label={texts.label_after_date}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChangeForm}
                                    value={rechercheCourrante['DateNaissanceFin'] || ''}
                                    disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <h5 style={{ position: "relative" }}>{texts.title_genre}</h5>
                                <FormGroup row aria-label="genre" onChange={handleChangeForm}>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={rechercheCourrante['genreM'] || false} />}
                                        label={texts.label_mal_short}
                                        labelPlacement="start"
                                        name="genreM"
                                        value={rechercheCourrante['genreM']}
                                        disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                    />
                                    <FormControlLabel
                                        value="F"
                                        control={<Checkbox color="primary" checked={rechercheCourrante['genreF'] || false} />}
                                        label={texts.label_femmal_short}
                                        labelPlacement="start"
                                        name="genreF"
                                        disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <h5 style={{ position: "relative" }}>{texts.label_actif_filter_tab}</h5>
                                <FormGroup row aria-label="actif" onChange={handleChangeForm}>
                                    <FormControlLabel
                                        value="true"
                                        control={<Checkbox color="primary" checked={rechercheCourrante['actif'] || false} />}
                                        label={texts.label_actif_filter_tab}
                                        labelPlacement="start"
                                        name="actif"
                                        disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl className={classes.formControl} width="70%" fullWidth>
                                    <h5 style={{ color: "#fff" }}>.</h5>
                                    <Autocomplete
                                        multiple
                                        id="vSelectLocalite"
                                        value={valueLoc}
                                        options={localiteList}
                                        getOptionLabel={(option) => option.text}
                                        defaultValue={[]}
                                        name="localite"
                                        disabled={!(activeTableIsAdherent || activeTableIsAAttribution)}
                                        onChange={handleChangeLocalite}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                name="localite"
                                                label={texts.label_city}
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    key={option.id}
                                                    color="primary"
                                                    label={option.text}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions style={{ marginTop: '30px' }}>
                        <Button disabled={buttonDisabled} className={classes.btnClass} variant="contained" onClick={getRecherche} color="primary">{texts.label_export} <GetAppIcon /></Button>
                    </DialogActions>
                </FormControl>
            </main>

        </React.Fragment>
    )

}
export default Statistiques;