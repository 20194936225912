import {
    RETRIEVE_VERSION,
} from "./types";

import VersionDataService from "../services/VersionService";

export const retrieveVersion = () => async (dispatch) => {
    try {
        const res = await VersionDataService.versionPortail();
        
        dispatch({
            type: RETRIEVE_VERSION,
            payload: res.data,
        });
        return res
    } catch (err) {
        console.log(err);
    }
};