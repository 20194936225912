import http from "../http-common";

const sessionUser = () => {
    return http.get('/portail/user/session');
};

const SessionService = {
    sessionUser
};

export default SessionService;
