import React, { useState, useEffect }from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { retrieveSession } from '../../actions/session'

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sessionChecked, setSessionChecked] = useState(false);
    const [authOk, setAuthOk] = useState(false);

    useEffect(() => {
        let promise = dispatch(retrieveSession());
        promise.then((response) => {
            if (response.data.connected){
                if (response.data.users.members.length > 1) {
                    setAuthOk(false);
                } else {
                    if (response.data.connected) {
                        setAuthOk(true);
                    } else {
                        setAuthOk(false);
                    }
                }
            } else {
                setAuthOk(false);
            }
           
            setSessionChecked(true);
        });
    }, [history, dispatch, history.location.pathname]);

    
    return (
        sessionChecked?
        <Route {...rest} render={
            props => {
                if (authOk) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
        :
            <LoadingIndicator open={!sessionChecked} />
    )
}

export default ProtectedRoute;