import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import PersonIcon from '@material-ui/icons/Person';
import { blue } from '@material-ui/core/colors';
import http from "../../http-common";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function MemberChoice(props) {
    const classes = useStyles();
    const history = useHistory();
    const texts = useSelector(state => state.texts);
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props]);


    const handleListItemClick = (value) => {
        setOpen(false);
        var param = {
            memberId: value.id,
        }
        http.post(`/portail/user/sessionMemberChoice`, param).then((response) => {
                history.push('/home');
        }, (error) => {
            console.log(error);
        });
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            aria-labelledby="memberChoiceDialog"
            open={open}>
            <DialogTitle id="memberChoiceDialog">{texts.member_choice_title}</DialogTitle>
            <List>
                {props.members.map((membre) => (
                    <ListItem disabled={membre.inactif} button onClick={() => handleListItemClick(membre)} key={membre.id}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={[membre.name.toUpperCase(), " ", membre.firstname]} />
                        {membre.inactif? <DesktopAccessDisabledIcon/>:null}
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );

}