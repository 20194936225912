import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useStyles } from "./Cotisations.css";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import MUIDataTable from "mui-datatables";
import http from "../../http-common";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { Grid } from '@material-ui/core';
import moment from 'moment'

export const Cotisations = () => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [tableTexts, setTableTexts] = useState("")
    const userSession = useSelector(state => state.session.users);
    const [contributionsList, setConrtributionsList] = useState([])//tableau données datatable
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        setLoading(true)
        var param = {
            vMember: userSession.members[0].id,
        }
        http.post(`/portail/members/getContribution`, param).then((response) => {
            setConrtributionsList(response.data.Cotisation)
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
    }, [userSession.members])

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: texts.contribution_tab_motif,
            name: "Motif",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: texts.contribution_tab_date,
            name: "Date_paiement",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    moment(new Date(value)).format('DD.MM.YYYY')
                ),
            },
        },
        {
            label: texts.contribution_tab_amount,
            name: "Montant",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: texts.contribution_tab_sendMC,
            name: "Envoi_Cm",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                display: 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => (
                    moment(new Date(value)).format('DD.MM.YYYY')
                ),
            },
        },
        {
            label: texts.contribution_tab_modality,
            name: "Modalite_EnvoiCM",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
    ];

    const options = {
        filter: false,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        responsive: 'simple',
        sortOrder: { name: 'Date_paiement', direction: 'desc' },
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        download:false,
        viewColumns:false,
        textLabels: tableTexts,
       
    };

    return (
        <>
            <LoadingIndicator open={loading} />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <MUIDataTable
                            title={texts.title_cotisation}
                            data={contributionsList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </main>
        </>
    )

}

export default Cotisations;