import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useStyles } from "./Rubriques.css";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import ListRubrique from "./listRubrique/ListRubrique"
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const Rubriques = () => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [tableTexts, setTableTexts] = useState("")
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState('rubriquePanel');

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        setLoading(false)
      
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <LoadingIndicator open={loading} />
            <main className={classes.content}>
                <Accordion square expanded={expanded === 'disciplinePanel'} onChange={handleChange('disciplinePanel')}>
                    <AccordionSummary aria-controls="disciplinePanel-content" id="disciplinePanel-header">
                        <Typography variant="h5">{texts.disciplines}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListRubrique type="discipline" url="/portail/enum/getDisciplines"/>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'categoryPanel'} onChange={handleChange('categoryPanel')}>
                    <AccordionSummary aria-controls="categoryPanel-content" id="categoryPanel-header">
                        <Typography variant="h5">{texts.categories}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListRubrique type="categorie" url="/portail/enum/getCategories" />
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'keyWordPanel'} onChange={handleChange('keyWordPanel')}>
                    <AccordionSummary aria-controls="keyWordPanel-content" id="keyWordPanel-header">
                        <Typography variant="h5">{texts.keyWord}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListRubrique type="keyWord" url="/portail/enum/getKeyWord" />
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'rubriquePanel'} onChange={handleChange('rubriquePanel')}>
                    <AccordionSummary aria-controls="rubriquePanel-content" id="rubriquePanel-header">
                        <Typography variant="h5">{texts.rubriqueSite}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListRubrique type="rubrique" url="/portail/enum/getRubriques" />
                    </AccordionDetails>
                </Accordion>
            </main>
        </>
    )

}

export default Rubriques;