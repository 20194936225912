import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './Preference.css'
import TextTable_EN from '../../../components/customComponent/textTable_EN.json'
import TextTable_FR from '../../../components/customComponent/textTable_FR.json'
import MUIDataTable from 'mui-datatables'
import http from '../../../http-common'
import moment from 'moment'
import {
  Grid,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Container,
  Avatar
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator'
import { MySnackbar } from '../../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../../components/customDialog/CustomDialog'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import StatusLegend from '../../statusLegend/StatusLegend'
import InfoIcon from '@material-ui/icons/Info'
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles'

export const Preference = () => {
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const userSession = useSelector((state) => state.session)
  const langueCharger = texts.langueCharger
  const [tableTexts, setTableTexts] = useState('')
  const [registrationsList, setRegistrationsList] = useState([]) //tableau données datatable
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [snack, setSnack] = React.useState({})
  const [categoriesList, setCategoriesList] = useState([]); //MI 29.04.24,#5153096401
  const dispatch = useDispatch();
  const [myDialog, setMyDialog] = React.useState({
    show: false,
    title: '',
    body: '',
    extraButtons: null,
    subtitle: null,
    jsxComponent: null,
  })
  //persist table state
  const [sortOrder, setSortOrder] = useState({ name: 'nom', direction: 'asc' })
  const [rowsPerPage, setRowsPerPage] = useState(10)

  //MI 13.01.2022, #2127031892
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12',
          color: 'white',
          backgroundColor: '#d80669',
        },
      },
    },
  })

  useEffect(() => {
    if (langueCharger === 'FR') {
      setTableTexts(TextTable_FR)
    } else {
      setTableTexts(TextTable_EN)
    }
  }, [tableTexts, langueCharger])

  useEffect(() => {
    setLoading(true)
    http.get('/portail/registration/getPreference').then(
      (response) => {
        //console.log(response.data.liste) // rt 01 09 2022
        setRegistrationsList(response.data.liste)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      },
    )
  }, [reload])

    //MI 16.02.24, #5153096401
  useEffect(() => {
       http.get(`/portail/enum/getCategories`).then((response) => {
            setCategoriesList(response.data)
        }, (error) => {
            console.log(error);
        });
        }, [dispatch]);

  const getStatusLegend = (stat) => {
    let retour = ''
    let status = [
      {
        id: 1,
        title: 'En traitement',
        content: 'préinscription en cours de traitement.',
      },
      {
        id: 2,
        title: "Sur liste d'attente",
        content: "vous êtes sur liste d'attente.",
      },
      { id: 3, title: 'Activité annulée', content: "l'activité est annulée." },
      {
        id: 4,
        title: 'Confirmé',
        content:
          'Inscription confirmée. Si activité payante, la facture suivra.',
      },
      {
        id: 5,
        title: "En attente d'annulation",
        content: "vous avez demandé l'annulation de votre inscription.",
      },
      {
        id: 6,
        title: 'Inscription annulée',
        content: 'votre inscription a été annulée à votre demande.',
      },
      {
        id: 7,
        title: 'Demande envoyée',
        content: 'votre inscription a été envoyée.',
      },
    ]

    for (let index = 0; index < status.length; index++) {
      if (status[index].title === stat) retour = status[index].content
    }
    return retour
  }

  function checkRole(role) {
    let retour = false
    for (let index = 0; index < userSession.users.roles.length; index++) {
      const element = userSession.users.roles[index]
      if (element.Titre === role) {
        retour = true
      }
    }
    return retour
  }

  const cancelPref = (prefID) => {
    let pTitle = texts.delete_pref_confirm_btn //JC 22.11.21 delete_confirm_btn;
    let pBody = texts.delete_pref_confirm_btn_text //JC 22.11.21 delete_confirm_btn_text;

    function deleteConfirm() {
      setLoading(true)
      let params = { preferenceID: prefID }

      http.post('/portail/registration/cancelPreference', params).then(
        (response) => {
          if (response.data.error) {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'error',
            })
          } else {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'success',
            })
            setReload(!reload)
            setMyDialog({ show: false })
          }
          setLoading(false)
        },
        (error) => {
          setLoading(false)
          console.log(error)
        },
      )
    }
    let pExtraButtons = (
      <Button
        onClick={deleteConfirm}
        style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}
        variant="contained"
        type="button"
      >
        {texts.confirm_delete_btn} {/*JC 22.11.21 {texts.delete_btn}*/}
      </Button>
    )
    setMyDialog({
      show: true,
      title: pTitle,
      body: pBody,
      extraButtons: pExtraButtons,
      subtitle: null,
      jsxComponent: null,
    })
  }

  function SubRow(props) {
    // console.log(props);// rt 01 09 2022
    const colSpan = props.rows.length + 1
    return (
      <TableRow>
        <TableCell colSpan={colSpan} className={classes.subRowCell}>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justify="center"
            spacing={2}
          >
            {props.rows.map((item, index) =>
              item.DatesCours !== null ? ( //alors dates cours
                <Grid key={item.ID} item xs={12} sm={12}>
                  {item.DateAnnulation !== '0000-00-00' ? (
                    <Tooltip title={texts.canceled_session}>
                      <EventBusyIcon color="primary" />
                    </Tooltip>
                  ) : null}
                  {item.DatesCours.vcDatesCours.map(
                    (item2, index2) =>
                      moment(new Date(item2)).format('DD.MM.YYYY') +
                      (index2 < item.DatesCours.vcDatesCours.length - 1
                        ? ', '
                        : ' / ' + item.Heure_debut + ' - ' + item.Heure_fin),
                  ) +
                    (item.Salle !== '' ? ' / ' + item.Salle : '') +
                    (item.DateAnnulation !== '0000-00-00'
                      ? ' / ' + texts.canceled_session
                      : '')}
                </Grid>
              ) : //alors date début

              item.Date_Debut !== '0000-00-00T00:00:00.000Z' ? (
                <Grid key={item.ID} item xs={12} sm={12}>
                  {item.DateAnnulation !== '0000-00-00' ? (
                    <Tooltip title={texts.canceled_session}>
                      <EventBusyIcon color="primary" />
                    </Tooltip>
                  ) : null}
                  {moment(new Date(item.Date_Debut)).format('DD.MM.YYYY')}{' '}
                  {item.Heure_debut}-{item.Heure_fin}
                  {item.DateAnnulation !== '0000-00-00'
                    ? ' / ' + texts.canceled_session
                    : ''}
                </Grid>
              ) : null,
            )}
          </Grid>
          <br />
          <Grid>
            {props.TexteCoupon !== '' ? ( //MI 01.12.2021 pour afficher la remarque /information // rt 19.10.2022 bug undefined
              <span>{props?.rows[0]?.TexteCoupon || ''}</span>
            ) : null}
          </Grid>
        </TableCell>

        {props.rows.length === 0 ? ( //MI 15.10.2021 pour indiquer les indisponibilités créées
          <span>Indisponibilités aux dates proposées</span>
        ) : null}
      </TableRow>
    )
  }


      //MI 16.02.24, #5153096401 fonction pour récupérer la catégorie de l'activité
  const getCategoryAttribute = (ref, type) => {
 // console.log(categoriesList);
    let retour = ''
    for (let index = 0; index < categoriesList.length; index++) {
      if (ref !== null) {
        if (categoriesList[index].text === ref)
          retour = categoriesList[index][type]
        console.log(categoriesList[index][type]);
      }
    }

    return retour
  }

  const columns = [
    {
      label: 'ID',
      name: 'ID',
      options: {
        viewColumns: false,
        filter: false,
        display: 'excluded',
        download: false,
      },
    },
    {
      label: texts.preferenceTable_columnName,
      name: 'Nom',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          //tableMeta.rowData[3] === "Accompagnateur" ? <div><Tooltip title="Accompagnant"><AccessibilityNewIcon color="primary" /></Tooltip><span>{value}</span></div> : value
          //JC 01.07.22 mis avec objet et attributs
          registrationsList[tableMeta.rowIndex].detail.length === 0 ? (
            <div>
              <span>{value}</span>
              <Tooltip title="Indisponibilités">
                <EventBusyIcon color="primary" />
              </Tooltip>
            </div>
          ) : registrationsList[tableMeta.rowIndex].Accompagnateur ? (
            <div>
              <Tooltip title="Accompagnant">
                <AccessibilityNewIcon color="primary" />
              </Tooltip>
              <span>{value}</span>
            </div>
          ) : (
            value
          ),
      },
    },
    {
      label: 'Catégorie',
      name: 'Cat',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
                 customHeadRender: (columnMeta, updateDirection) => ( //MI 28.02.24, #5153184889
                <th
                    className="MuiTableCell-root MuiTableCell-head"
                    key={columnMeta.index}
                    style={{textAlign: "center",}}
                >
                    Catégorie
                </th>
            ),
        customBodyRender: (value, tableMeta, updateValue) => (  //MI 29.04.24, #5153096401                  
                   value ? <> 
                   <Container className={classes.pastille} maxWidth ="xs" style={{
                    display: "flex",
                    justifyContent: "center",
                    margin:"0"}}> 
                   <Avatar
                      aria-label="recipe"
                     className={classes.avatarPreference}
                      style={{                  
                        background: getCategoryAttribute(
                          value,
                          'color',
                        ),
                      }}
                    >                   
                      &nbsp;                     
                    </Avatar> 
                    </Container >              
                    <div style={{textAlign: "center",}}>
                    {value}
                    </div>
                    
                    </>: "" //value.text : ""
                   
                )
      },
    },
    {
      label: texts.preferenceTable_columnPeriod,
      name: 'period',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
      },
    },
    {
      label: 'En tant que',
      name: 'Accompagnateur',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: checkRole('Accompagnateur'),
        display: checkRole('Accompagnateur'),
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? 'Accompagnateur' : 'Participant',
      },
    },
    {
      label: 'Statut',
      name: 'statut',
      options: {
        sortThirdClickReset: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          //value === "Annulée" ? texts.preferenceTable_canceled_activity : value,

          <div>
            <span>{value}</span>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={getStatusLegend(
                  value === 'Annulée'
                    ? texts.preferenceTable_canceled_activity
                    : value,
                )}
              >
                <InfoIcon fontSize="small" htmlColor="#564146" />
              </Tooltip>
            </MuiThemeProvider>
          </div>
        ),
      },
    },
    {
      label: 'Type',
      name: 'Nature',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    // { //MI 13.12.2021 #1918704263
    //   label: "Pour",
    //   name: "ID_Membre_10_ID",
    //   options: {
    //     sortThirdClickReset: true,
    //     sort: true,
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       value.Type_membre.includes("Couple cotisant") ? <div><span>{value.Nom+" "+value.Prenom}</span></div>:""

    //    ),
    //   },
    // },
    {
      label: texts.preferenceTable_columnAction,
      name: 'ID',
      options: {
        viewColumns: false,
        filter: false,
        download: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          //MI 12.02.24, #5153078378 prendre le dernier élément de la collection pour l'indice du tableau pour avoir la dernière date
          //JC 01.07.22 mis avec objet et attributs
          registrationsList[tableMeta.rowIndex].detail.length > 0 ? (
            registrationsList[tableMeta.rowIndex].detail[
              registrationsList[tableMeta.rowIndex].detail.length - 1
            ].DatesCours !== null ? (
              //on compare la dernière date de cours à la date du jour : formule un peu compliquée
              registrationsList[tableMeta.rowIndex].detail[
                registrationsList[tableMeta.rowIndex].detail.length - 1
              ].DatesCours.vcDatesCours[
                registrationsList[tableMeta.rowIndex].detail[
                  registrationsList[tableMeta.rowIndex].detail.length - 1
                ].DatesCours.vcDatesCours.length - 1
              ] > moment(new Date()).format('YYYY-MM-DD') &&
              registrationsList[tableMeta.rowIndex].statut !== 'Annulée' ? (
                <Tooltip title="Annuler la demande d'inscription">
                  <IconButton
                    onClick={() => {
                      cancelPref(value)
                    }}
                    color="primary"
                    component="span"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ) : null
            ) : registrationsList[tableMeta.rowIndex].detail[
                registrationsList[tableMeta.rowIndex].detail.length - 1
              ].Date_Debut > moment(new Date()).format('YYYY-MM-DD') &&
              registrationsList[tableMeta.rowIndex].statut !== 'Annulée' ? (
              <Tooltip title="Annuler">
                <IconButton
                  onClick={() => {
                    cancelPref(value)
                  }}
                  color="primary"
                  component="span"
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            ) : null
          ) : null,
      },
    },
    {
      label: 'Detail',
      name: 'detail',
      options: {
        viewColumns: false,
        filter: false,
        display: 'excluded',
        download: false,
      },
    },
  ]

  const options = {
    filter: checkRole('Accompagnateur'),
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    selectToolbarPlacement: 'none',
    // onRowSelectionChange: handleLineSelection,
    responsive: 'simple',
    sortOrder: sortOrder,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    tableBodyHeight: 'auto',
    fixedHeader: true,
    download: false,
    viewColumns: false,
    downloadOptions: {
      filename: 'export_' + texts.title_preference_list + '.csv',
      separator: ';',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data)
    },
    textLabels: tableTexts,
    onTableChange: (action, tableState) => {
      //persist table state
      switch (action) {
        case 'sort':
          setSortOrder(tableState.sortOrder)
          break
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage)
          break
        case 'propsUpdate':
          tableState.sortOrder = sortOrder
          tableState.rowsPerPage = rowsPerPage
          break
        default:
          break
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // if (registrationsList[dataIndex].detail.length > 0) {
      //         return true;
      //     } else {

      //     return false;

      //     }
      return true
    },
    renderExpandableRow: (rowData, rowMeta) => {
      //JC 30.06.22, on utilise l'objet au lieu du numéro du champ, return <SubRow rows={rowData[7]} />;
      return <SubRow rows={registrationsList[rowMeta.rowIndex].detail} />
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
  }

  return (
    <>
      <CustomDialog params={myDialog} />
      <MySnackbar params={snack} />
      <LoadingIndicator open={loading} />
      {/* MI 13.01.2022, #2127031892 legende plus utile*/}
      {/* <StatusLegend text="Preference"/> */}

      <MUIDataTable
        title={texts.title_preference_list}
        data={registrationsList}
        columns={columns}
        options={options}
      />
    </>
  )
}

export default Preference
