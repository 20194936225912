import React from 'react'
import { useSelector } from 'react-redux'
import InfoIcon from '@material-ui/icons/Info'
import { Toolbar, Tooltip } from '@material-ui/core'
import { styled, makeStyles } from '@material-ui/styles'
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  //test
  bigbulle: {
    height: '20%',
    fontSize: '16px',
  },
}))

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '10',
        color: 'white',
        backgroundColor: '#d80669',
      },
    },
  },
})

export default function StatusLegend(props) {
  const classes = useStyles()

  const texts = useSelector((state) => state.texts)
  const typeregistration = props
  let statusComplement = ''
  const status = []

  if (typeregistration.text === 'Preference') {
    const statusP = status.push(
      {
        id: 1,
        title: '[En traitement]: ',
        content: 'préinscription en cours de traitement.',
      },
      {
        id: 2,
        title: "[Sur liste d'attente]: ",
        content: "vous êtes sur liste d'attente.",
      },
      {
        id: 3,
        title: '[Activité annulée]: ',
        content: "l'activité est annulée.",
      },
      //{id: 3, title: '[En attente de confirmation]: ', content: 'vous êtes inscrit mais pas encore validé.'},
    )
    statusComplement = texts.status_legend_preference_title
  } else {
    //Registration
    const statusR = status.push(
      {
        id: 1,
        title: '[Confirmé]: ',
        content:
          'Inscription confirmée. Si activité payante, la facture suivra.',
      },
      {
        id: 2,
        title: "[En attente d'annulation]: ",
        content: "vous avez demandé l'annulation de votre inscription.",
      },
      {
        id: 3,
        title: '[Inscription annulée]: ',
        content: 'votre inscription a été annulée à votre demande.',
      },
      {
        id: 4,
        title: '[Activité annulée]: ',
        content:
          "la session de l'activité à laquelle vous êtes inscrit est annulée.",
      },
    )

    statusComplement = texts.status_legend_registration_title
  }

  const listStatus = status.map((stat) => (
    //MI 12.01.2022, #2127031892

    <li key={stat.id}>
      {stat.title}
      <MuiThemeProvider theme={theme}>
        <Tooltip title={stat.content}>
          <InfoIcon fontSize="small" htmlColor="#564146" />
        </Tooltip>
      </MuiThemeProvider>
    </li>
  ))
  return (
    <>
      <div>{statusComplement}</div>
      <ul>{listStatus}</ul>
    </>
  )
}
