import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    subRowCell: {
        paddingLeft: theme.spacing(4),
        backgroundColor: "#dedede"
    },
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
}));

