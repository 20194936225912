import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from '@material-ui/core';

const defaultToolbarStyles = {
    iconButton: {
        marginLeft: 15,
    },
    filterCheck: {
        marginLeft: 5,
    }
};


class MuiDatatableToolBar extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {!this.props.hideFilter ? <FormControlLabel
                    className={classes.filterCheck}
                    control={
                        <Checkbox
                            checked={this.props.actifFilter}
                            onChange={this.props.handleActifFilter}
                            name="actifFilter"
                            id="actifFilter"
                            color="primary"
                        />
                    }
                    label={this.props.label}
                /> : null}
                {this.props.extraBtn ? this.props.extraBtn:null}
            </React.Fragment>
        );
    }

}

export default withStyles(defaultToolbarStyles, { name: "MuiDatatableToolBar" })(MuiDatatableToolBar);