import http from "../http-common";

const versionPortail = () => {
    
    return http.get("/portail/version");
};

const VersionService = {
    versionPortail
};

export default VersionService;