import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './Profil.css'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import {
  Tooltip,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
} from '@material-ui/core'
import http from '../../http-common'
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import InfoIcon from '@material-ui/icons/Info'
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles'

export const Profil = () => {
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const [loading, setLoading] = useState(true)
  const [login, setLogin] = useState('')
  const [loginNewAccount, setLoginNewAccount] = useState('')
  const [allowSeparation, setAllowSeparation] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [loginNewAccountError, setLoginNewAccountError] = useState(false)
  const [snack, setSnack] = useState({})
  const [showSeparateEmail, setShowSeparateEmail] = useState(false)
  const userSession = useSelector((state) => state.session)
  const [paieOnLine, setPaieOnLine] = useState(false)
  const [bulletinElectronique, setBulletinElectronique] = useState(false)

  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12',
          color: 'white',
          backgroundColor: '#d80669',
        },
      },
    },
  })

  useEffect(() => {
    http.get('/portail/user/infos').then(
      (response) => {
        setLogin(response.data.login)
        setAllowSeparation(response.data.membersSeparate)
        setLoading(false)
        setPaieOnLine(response.data.paieOnLine)
        setBulletinElectronique(response.data.bulletinElectronique)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      },
    )
  }, [])

  const handleLoginChange = (event) => {
    const target = event.target
    setLogin(target.value)
  }
  const handleLoginNewAccountChange = (event) => {
    const target = event.target
    setLoginNewAccount(target.value)
  }
  const handleSaveLogin = () => {
    setLoginError(false)
    if (login === '') {
      setLoginError(true)
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(login)) {
        setSnack({
          message: texts.login_email_invalide,
          show: true,
          severity: 'error',
        })
      } else {
        setLoading(true)
        let params = {}
        params.newLogin = login
        http.post('/portail/user/changeLogin', params).then(
          (response) => {
            if (response.data.error) {
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'error',
              })
            } else {
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'success',
              })
            }
            setLoading(false)
          },
          (error) => {
            console.log(error)
            setLoading(false)
          },
        )
      }
    }
  }

  const handleChangePsw = () => {
    setLoading(true)
    let params = {}
    http.post('/portail/user/changePsw', params).then(
      (response) => {
        if (response.data.error) {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'error',
          })
        } else {
          window.open('/resetPsw?code=' + response.data.code, '_blank')
        }
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      },
    )
  }
  const handleSeparateAccount = () => {
    setShowSeparateEmail(true)
  }
  const handleValidNewAccount = () => {
    setLoginNewAccountError(false)
    if (loginNewAccount === '') {
      setLoginNewAccountError(true)
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginNewAccount)) {
        setSnack({
          message: texts.login_email_invalide,
          show: true,
          severity: 'error',
        })
      } else {
        setLoading(true)
        let params = {}
        params.newLogin = loginNewAccount
        http.post('/portail/user/separateAccount', params).then(
          (response) => {
            if (response.data.error) {
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'error',
              })
            } else {
              setShowSeparateEmail(false)
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'success',
              })
              setTimeout(function () {
                window.location.reload()
              }, 6000)
            }
            setLoading(false)
          },
          (error) => {
            console.log(error)
            setLoading(false)
          },
        )
      }
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    setShowSeparateEmail(false)
  }

  const handlePaieOnLineChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    // console.log(value);
    let params = {}
    params.paieMode = value
    http.post('/portail/user/changePaieMode', params).then(
      (response) => {
        if (response.data.error) {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'error',
          })
        } else {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'success',
          })
          setPaieOnLine(response.data.paieOnLine)
          console.log(texts[response.data])
        }
      },
      (error) => {
        console.log(error)
      },
    )
  }
  {
    /* MI 30.03.2022, #2482820833 coche bulletin électronique*/
  }
  const handleBulletinElectroniqueChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    // console.log(value);
    let params = {}
    params.bulletinElectronique = value
    http.post('/portail/user/changeBulletinElecChoise', params).then(
      (response) => {
        if (response.data.error) {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'error',
          })
        } else {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'success',
          })
          setBulletinElectronique(response.data.bulletinElectronique)
          console.log(texts[response.data])
        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  return (
    <>
      <LoadingIndicator open={loading} />
      <MySnackbar params={snack} />
      <Paper elevation={3} className={classes.paperClass}>
        <div>
          <span style={{ fontSize: 16 }}>
            <br />
            - Le mot de passe de votre compte peut être changé en cliquant sur
            le bouton ci-dessous.
            <br />
            <br />
            {allowSeparation ? ( //si compte partagé
              <div>
                {/* - Vous pouvez, par le bouton "Me séparer de ce compte", avoir votre propre compte dans le cas où
                                    vous utilisiez un compte partagé. */}
                <br />
                <br />
              </div>
            ) : null}
          </span>
        </div>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={1} md={1}>
            {/*    
                        <TextField
                            label={texts.login_modified_field_label}
                            id="email"
                            name="email"
                            required={true}
                            value={login}
                            onChange={handleLoginChange}
                            error={loginError}
                            helperText={loginError?texts.required_field:null}
                        />
                        <Button onClick={handleSaveLogin} style={{ marginTop: "10px", marginLeft: "10px" }} variant="contained" color="primary">{texts.resource_save_btn}</Button>
                    */}{' '}
          </Grid>
          <Grid item xs={6} md={5} style={{ textAlign: 'center' }}>
            <Button
              onClick={handleChangePsw}
              style={{ marginTop: '10px' }}
              variant="contained"
              color="primary"
            >
              {texts.psw_modified_button}
            </Button>
          </Grid>
          {/* {allowSeparation ? <Grid item xs={6} md={4} style={{ textAlign: "right" }}>
                        <Button onClick={handleSeparateAccount} style={{ marginTop: "10px" }} variant="contained" color="primary">{texts.separate_account_button}</Button>
                    </Grid> : null} */}
          {/* MI 22.02.2022 ajout de la coche désabonner papier*/}
          <Grid item xs={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paieOnLine}
                  onChange={handlePaieOnLineChange}
                  name="DesabonnerPapier"
                  id="DesabonnerPapier"
                  color="primary"
                />
              }
              label={
                <>
                  <span>{texts.label_paiemode}</span>
                  <MuiThemeProvider theme={theme}>
                    <Tooltip title="Cochez cette case, si vous souhaitez recevoir vos confirmations d'inscription et vos factures par voie électronique.">
                      <InfoIcon fontSize="small" htmlColor="#564146" />
                    </Tooltip>
                  </MuiThemeProvider>
                </>
              }
            />
          </Grid>
          {/* MI 30.03.2022, #2482820833 ajout de la coche bulletin électronique*/}
          <Grid item xs={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bulletinElectronique}
                  onChange={handleBulletinElectroniqueChange}
                  name="E_Bulletin"
                  color="primary"
                />
              }
              label={texts.label_bulletin_electronique}
              //labelPlacement="start"
            />
          </Grid>
        </Grid>

        <Dialog fullWidth={true} open={showSeparateEmail} onClose={handleClose}>
          <DialogTitle>{texts.separate_account_title_modal}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={texts.login_modified_field_label}
                  id="emailNewAccount"
                  name="emailNewAccount"
                  fullWidth
                  required={true}
                  value={loginNewAccount}
                  onChange={handleLoginNewAccountChange}
                  error={loginNewAccountError}
                  helperText={
                    loginNewAccountError ? texts.required_field : null
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={handleValidNewAccount}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  )
}

export default Profil
