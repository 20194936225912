import { combineReducers } from 'redux';
import texts from './textReducer';
import session from './sessionReducer';

import loading from './loadingReducer';
import version from './versionReducer';

export default combineReducers({
    texts,
    session,
    loading,
    version
});