import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import http from '../../../http-common'
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import CustomAutocomp from '../../customAutocomp/CustomAutocomp'
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MySnackbar } from '../../mySnackbar/MySnackbar'
import { CustomDialog } from '../../customDialog/CustomDialog'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import ListDates from '../../../components/listDates/ListDates'
import cloneDeep from 'lodash/cloneDeep'
import Calendar from 'rc-year-calendar'
import 'rc-year-calendar/locales/rc-year-calendar.fr'

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    width: '100%',
  },
  activityCardTop: {
    background: '#F5F5F5',
  },
}))

const VisiteForm = (props) => {
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const selectedVisite = props.vSelectedVisite
  const [loadingInfos, setLoadingInfos] = useState(false)
  const [snack, setSnack] = useState({})
  const [myDialog, setMyDialog] = React.useState({
    show: false,
    title: '',
    body: '',
    extraButtons: null,
    subtitle: null,
    jsxComponent: null,
  })
  const [detailVisite, setDetailVisite] = useState({
    ID: 0,
    period: null,
    Date_Debut: null,
    Heure_debut: null,
    Heure_fin: null,
    Transport: '',
    Places: 0,
    Nb_accompagnateurs: 0,
    PointRDV: '',
    NoBulletin: 0,
    intervenant: [],
    Salle: '',
    DateAnnulation: null,
    FermerInscription: false, //MI 18.11.22, #3402715172
    TitreSession: '', //MI 15.02.24, #5153124597
  })
  //gestion erreur champ obligatoire
  const [fieldError, setFieldError] = useState([false, false, false]) //period, Date début, Capacité
  const [selectedCourseDates, setSelectedCourseDates] = useState([])

  useEffect(() => {
    setLoadingInfos(true)
    var param = {
      visiteNum: selectedVisite.ID,
    }
    http.post('/portail/activities/getVisitDetail', param).then(
      (response) => {
        response.data.Heure_debut = response.data.Heure_debut
          ? moment(response.data.Heure_debut)
          : response.data.Heure_debut
        response.data.Heure_fin = response.data.Heure_fin
          ? moment(response.data.Heure_fin)
          : response.data.Heure_fin
        setDetailVisite(response.data)
        if (response.data.DatesCours !== null) {
          if (response.data.DatesCours.vcDatesCours) {
            let listeDates = response.data.DatesCours.vcDatesCours
            for (let index = 0; index < listeDates.length; index++) {
              listeDates[index] = {
                id: index + 1,
                startDate: new Date(listeDates[index]),
                endDate: new Date(listeDates[index]),
                color: '#D80669',
              }
            }
            setSelectedCourseDates(listeDates)
          }
        }
        setLoadingInfos(false)
      },
      (error) => {
        setLoadingInfos(false)
        console.log(error)
      },
    )
  }, [selectedVisite])

  const handleFormChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setDetailVisite({ ...detailVisite, [name]: value })
  }

  const duplicateVisit = () => {
    setDetailVisite({ ...detailVisite, ID: 0 })
    setSnack({
      message: 'Session dupliquée avec succès',
      show: true,
      severity: 'success',
    })
  }

  const saveVisit = () => {
    let fError = [false, false, false]

    if (detailVisite.period === null) {
      fError[0] = true
    } else if (detailVisite.period.id === 0) {
      fError[0] = true
    }

    if (props.selectedActivityNature === 'GRPE') {
      if (selectedCourseDates.length === 0) {
        fError[1] = true
      }
    } else {
      if (detailVisite.Date_Debut === null) {
        fError[1] = true
      }
    }
    if (detailVisite.Places <= 0 || detailVisite.Places === '') {
      fError[2] = true
    }

    let checkError = fError.every((v) => v === false)

    if (!checkError) {
      setFieldError(fError)
    } else {
      //SAVE
      setLoadingInfos(true)
      let listeDatesCours = []
      for (let index = 0; index < selectedCourseDates.length; index++) {
        const element = selectedCourseDates[index]
        listeDatesCours.push(element.startDate)
      }
      detailVisite.dateCours = listeDatesCours
      for (let index = 0; index < detailVisite.dateCours.length; index++) {
        detailVisite.dateCours[index] = moment(
          detailVisite.dateCours[index],
        ).format('DD.MM.YYYY')
      }
      let params = {
        row: JSON.stringify(detailVisite),
        typeVisitID: props.selectedActivityID,
      }

      http.post('/portail/activities/saveVisit', params).then(
        (response) => {
          if (response.data.error) {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'error',
            })
          } else {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'success',
            })
            props.setReload(!props.reload) //reload parent
            props.setReloadTypeVisit(!props.reloadTypeVisit)
            props.closeVisitForm()
          }
          setLoadingInfos(false)
        },
        (error) => {
          setLoadingInfos(false)
          console.log(error)
        },
      )
    }
  }

  const deleteVisit = () => {
    let pTitle = texts.delete_confirm_btn
    let pBody = texts.delete_confirm_btn_text
    function deleteConfirm() {
      setLoadingInfos(true)
      let params = { row: JSON.stringify(detailVisite) }

      http.post('/portail/activities/deleteVisit', params).then(
        (response) => {
          if (response.data.error) {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'error',
            })
          } else {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: 'success',
            })
            props.setReload(!props.reload) //reload parent
            props.setReloadTypeVisit(!props.reloadTypeVisit)
            setMyDialog({ show: false })
            props.closeVisitForm()
          }
          setLoadingInfos(false)
        },
        (error) => {
          setLoadingInfos(false)
          console.log(error)
        },
      )
    }
    let pExtraButtons = (
      <Button
        onClick={deleteConfirm}
        style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}
        variant="contained"
        type="button"
      >
        Supprimer
      </Button>
    )
    setMyDialog({
      show: true,
      title: pTitle,
      body: pBody,
      extraButtons: pExtraButtons,
      subtitle: null,
      jsxComponent: null,
    })
  }

  const handleAutocompChange = (autocompName) => (event, value) => {
    if (autocompName === 'period') {
      setDetailVisite({ ...detailVisite, Date_Debut: null })
      detailVisite.Date_Debut = null
      setSelectedCourseDates([])
    }
    setDetailVisite({ ...detailVisite, [autocompName]: value })
  }

  const handleDateChange = (date) => {
    setDetailVisite({ ...detailVisite, Date_Debut: date.format('YYYY-MM-DD') })
  }

  const handleDateAnnulationChange = (date) => {
    if (date !== null) {
      setDetailVisite({
        ...detailVisite,
        DateAnnulation: date.format('YYYY-MM-DD'),
      })
    } else {
      setDetailVisite({ ...detailVisite, DateAnnulation: null })
      detailVisite.DateAnnulation = null
    }
  }

  const handleTimeStartChange = (time) => {
    setDetailVisite({ ...detailVisite, Heure_debut: time })
  }

  const handleTimeEndChange = (time) => {
    setDetailVisite({ ...detailVisite, Heure_fin: time })
  }

  const autoCompPeriod = {}
  autoCompPeriod.url = '/portail/enum/getPeriod'
  autoCompPeriod.autoCompState = detailVisite.period
  autoCompPeriod.autoCompLabel = texts.visitForm_autoCompPeriodLabel
  autoCompPeriod.autoCompName = 'period'
  autoCompPeriod.multiple = false
  autoCompPeriod.error = fieldError[0]
  autoCompPeriod.handleAutocompChange = handleAutocompChange

  const autoCompIntervenant = {}
  autoCompIntervenant.url = '/portail/enum/getIntervenant'
  autoCompIntervenant.autoCompState = detailVisite.intervenant
  autoCompIntervenant.autoCompLabel = texts.visitForm_autoCompIntervenantLabel
  autoCompIntervenant.autoCompName = 'intervenant'
  autoCompIntervenant.multiple = true
  autoCompIntervenant.handleAutocompChange = handleAutocompChange

  //calendar
  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return (
        moment(ele.startDate).format('DD.MM.YYYY') !==
        moment(value).format('DD.MM.YYYY')
      )
    })
  }

  function isInArray(array, value) {
    return !!array.find((item) => {
      return (
        moment(item.startDate).format('DD.MM.YYYY') ===
        moment(value).format('DD.MM.YYYY')
      )
    })
  }

  const updateSelectedCalendarDates = (date) => {
    let newDate = {}
    newDate.startDate = date.date
    newDate.endDate = date.date
    newDate.color = '#D80669'
    setSelectedCourseDates((preArray) => {
      if (!isInArray(preArray, date.date)) {
        let dateArray = cloneDeep(preArray)
        // console.log(dateArray);// rt 01 09 2022
        newDate.id = preArray.length + 1
        dateArray.push(newDate)
        return dateArray
      } else {
        console.log('REMOVE')
        return arrayRemove(preArray, date.date)
      }
    })
  }

  return (
    <>
      <LoadingIndicator open={loadingInfos} />
      <CustomDialog params={myDialog} />
      <MySnackbar params={snack} />
      <Grid container alignItems="flex-start" spacing={2}>
        <Card className={classes.cardStyle}>
          <CardHeader
            title={
              detailVisite.ID > 0
                ? texts.edit_modal_title
                : texts.new_modal_title
            }
            className={classes.activityCardTop}
          />
          <CardContent>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomAutocomp params={autoCompPeriod} />
              </Grid>
              {/*MI 15.02.24, #5153124597*/}
              <Grid item xs={12} md={6}>
                <TextField
                  label={texts.visitForm_titreSession}
                  id="TitreSession"
                  name="TitreSession"
                  fullWidth
                  value={detailVisite.TitreSession}
                  onChange={handleFormChange}
                />
              </Grid>
              {props.selectedActivityNature === 'GRPE' ? (
                <>
                  <Grid item xs={8} md={8}>
                    <FormLabel error={fieldError[1]}>Date(s) Cours</FormLabel>
                    <FormHelperText error={fieldError[1]}>
                      {texts.required_field}
                    </FormHelperText>
                    <Calendar
                      style="background"
                      minDate={
                        detailVisite.period
                          ? new Date(moment(detailVisite.period.Date_debut))
                          : new Date()
                      }
                      maxDate={
                        detailVisite.period
                          ? new Date(detailVisite.period.Date_fin)
                          : new Date()
                      }
                      dataSource={selectedCourseDates}
                      year={
                        detailVisite.period
                          ? parseInt(
                              moment(detailVisite.period.Date_debut).format(
                                'YYYY',
                              ),
                            )
                          : parseInt(moment(new Date()).format('YYYY'))
                      }
                      onDayClick={updateSelectedCalendarDates}
                      language="fr"
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography variant="h6" className={classes.title}>
                      Date(s) Sélectionnée(s) :
                    </Typography>
                    <div>
                      {/* MI 29.09.2021 sinon trop d'espace entre les dates en colonne*/}
                      <ListDates dates={selectedCourseDates} />
                    </div>
                  </Grid>{' '}
                </>
              ) : (
                <Grid item xs={12} md={4}>
                  <MuiPickersUtilsProvider key={1} utils={MomentUtils}>
                    <DatePicker
                      required={true}
                      disabled={detailVisite.period === null}
                      cancelLabel={texts.datepicker_annuler}
                      autoOk
                      id="Date_Debut"
                      name="Date_Debut"
                      label={texts.visitForm_dateLabel}
                      format="DD/MM/YYYY"
                      value={detailVisite.Date_Debut}
                      onChange={handleDateChange}
                      error={fieldError[1]}
                      fullWidth
                      helperText={texts.required_field}
                      minDate={
                        detailVisite.period
                          ? new Date(detailVisite.period.Date_debut)
                          : null
                      }
                      maxDate={
                        detailVisite.period
                          ? new Date(detailVisite.period.Date_fin)
                          : null
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              <Grid item xs={6} md={4}>
                <MuiPickersUtilsProvider key={1} utils={MomentUtils}>
                  <TimePicker
                    clearable
                    autoOk
                    fullWidth
                    ampm={false}
                    label={texts.visitForm_deLabel}
                    value={detailVisite.Heure_debut}
                    onChange={handleTimeStartChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6} md={4}>
                <MuiPickersUtilsProvider key={1} utils={MomentUtils}>
                  <TimePicker
                    clearable
                    autoOk
                    fullWidth
                    ampm={false}
                    label={texts.visitForm_aLabel}
                    value={detailVisite.Heure_fin}
                    onChange={handleTimeEndChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={texts.visitForm_transportLabel}
                  id="Transport"
                  name="Transport"
                  fullWidth
                  value={detailVisite.Transport}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={
                    props.selectedActivityNature === 'Billetterie'
                      ? texts.visitForm_placesLabelB
                      : texts.visitForm_placesLabelA
                  }
                  id="Places"
                  name="Places"
                  fullWidth
                  required={true}
                  helperText={texts.required_field}
                  error={fieldError[2]}
                  value={detailVisite.Places}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={texts.visitForm_nbAccompLabel}
                  id="Nb_accompagnateurs"
                  name="Nb_accompagnateurs"
                  fullWidth
                  value={detailVisite.Nb_accompagnateurs}
                  onChange={handleFormChange}
                />
              </Grid>
              {/* MI 11.11.2021 plus utile car champ renseignements pratiques*/}
              {/* {props.selectedActivityNature === "VHC" ? 
                                <Grid item xs={12} md={6}>
                                <TextField
                                    label={texts.visitForm_rdvPlaceLabel}
                                    id="PointRDV"
                                    name="PointRDV"
                                    fullWidth
                                    value={detailVisite.PointRDV}
                                    onChange={handleFormChange}
                                />
                                </Grid> : null} */}
              <Grid item xs={12} md={6}>
                <TextField
                  label={texts.visitForm_bulletinLabel}
                  id="NoBulletin"
                  name="NoBulletin"
                  fullWidth
                  value={detailVisite.NoBulletin}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={texts.visitForm_salleLabel}
                  id="Salle"
                  name="Salle"
                  fullWidth
                  value={detailVisite.Salle}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomp params={autoCompIntervenant} />
              </Grid>
              {/* MI 05.11.2021 pour l'annulation de la session*/}
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider key={1} utils={MomentUtils}>
                  <DatePicker
                    disabled={detailVisite.period === null}
                    cancelLabel={texts.datepicker_annuler}
                    //clearLabel={"Effacer"}
                    clearable={true}
                    autoOk
                    id="DateAnnulation"
                    name="DateAnnulation"
                    label={texts.visitForm_date_annulation}
                    format="DD/MM/YYYY"
                    value={detailVisite.DateAnnulation}
                    onChange={handleDateAnnulationChange}
                    //error={fieldError[1]}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/* MI 18.11.2022, #3402715172*/}
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detailVisite.FermerInscription}
                      onChange={handleFormChange}
                      name="FermerInscription"
                      id="FermerInscription"
                      color="primary"
                    />
                  }
                  label={texts.visitForm_CloseRegistration}
                />
              </Grid>
            </Grid>
          </CardContent>
          {/* JC 22.11.2021 passé boutons image en texte*/}
          <CardActions>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              spacing={2}
            >
              <Grid item>
                {detailVisite.ID > 0 ? (
                  <Button
                    className={classes.btnClass}
                    variant="contained"
                    onClick={deleteVisit}
                    style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}
                  >
                    {texts.delete_btn}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {detailVisite.ID > 0 ? (
                  <Button
                    variant="contained"
                    onClick={duplicateVisit}
                    color="primary"
                  >
                    {texts.label_registration_copy}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  className={classes.btnClass}
                  variant="contained"
                  onClick={props.closeVisitForm}
                >
                  {texts.label_registration_cancel}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={saveVisit} color="primary">
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </>
  )
}

export default VisiteForm
