import React from 'react';
import { Box, Checkbox, DialogContentText, FormControlLabel, FormGroup, FormLabel, FormControl } from '@material-ui/core';
import { Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import ListDates from '../../components/listDates/ListDates'
import moment from 'moment'
import GetRandomKey from '../../components/general/randomKey';

export const DialogContentComponent = ({
    act,
    setActChoosen,
    classes,
    texts,
    handleDateSelectionCheck,
    infosAdherent,
    isAccompagnateur,
    userSession,
}) => {
    const formatDate = 'dddd DD.MM.YYYY';
    const handleChangeAcompagnateur = (e, action) => {
        const accomp = e.target.value === 'ouiaccompagnateur' ? true : false; // accompagnateur
        setActChoosen(prevActChoosen => {
            const newArr = [...prevActChoosen];
            return newArr.map(act => {
                if (act.ID === action.ID) {
                    return { ...act, accompagnateur: accomp };
                }
                return act;
            });
        });
    }
    const handelRemarqueMembre = (e, action) => {
        let valueremarque = e.target.value
        if (valueremarque.length > 180) {
            valueremarque = valueremarque.substr(0, 180);
        }

        setActChoosen(prevActChoosen => {
            const newArr = [...prevActChoosen];
            return newArr.map(act => {
                if (act.ID === action.ID) {
                    return { ...act, remarqueMembre: valueremarque };
                }
                return act;
            });
        });
    }

    return (
        <Box key={GetRandomKey(10)} className='DialogBoxActivity'>
            <DialogContentText>
                <Typography variant="h4" component="span">
                    {act.Nom}
                </Typography>
            </DialogContentText>
            <hr className={classes.line} />
            <DialogContentText>
                <Typography variant="h6" component="span">
                    {act.Categorie}
                </Typography>
                {/*MI 23.11.22, 3408334255 */}
                {act.AttributionMultiple ? (
                    <Typography
                        variant="subtitle2"
                        component="span"
                        style={{
                            fontSize: 16,
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                            marginTop: '20px',
                        }}
                    >
                        {texts.label_registration_choosingText}
                    </Typography>
                ) : null}
            </DialogContentText>
            <Box style={{ position: 'relative' }}>
                <FormGroup>
                    {act?.SessionsProposees?.map((vDate, indexDate) => {
                        const dateConflict = vDate.datesConflit ? vDate.datesConflit : [];
                        const conflitDateSession = dateConflict.some(dateConflict => dateConflict?.dateAttribuee === vDate.Date_Debut );
                        const classConflict = conflitDateSession ? '#d80669' : '';
                        return (vDate.DateAnnulation === '0000-00-00' &&
                            vDate.FermerInscription === false ?
                            <FormGroup key={GetRandomKey(9)}>
                                <FormControlLabel
                                    key={vDate.ID}
                                    control={
                                        <Checkbox
                                            onClick={(_) => handleDateSelectionCheck(act.ID, indexDate)}
                                            checked={!!(vDate.cotisationOK && !vDate.notChecked)}
                                            disabled={!vDate.cotisationOK}
                                            name={moment(new Date(vDate.Date_Debut)).format(formatDate)}
                                            value={vDate.ID}
                                        />
                                    }
                                    // rt 01 09 2022
                                    label={[
                                        vDate.DatesCours !== null
                                            ? (vDate.DatesCours.vcDatesCours.length > 1
                                                ? (vDate.Message_Complet !== ''
                                                    ? vDate.Message_Complet + ' / '
                                                    : '') + 'Session dès le : '
                                                : 'Session du : ') +
                                            moment(new Date(vDate.Date_Debut)).format(formatDate) +
                                            ' / ' +
                                            vDate.Heure_debut +
                                            ' / ' +
                                            (vDate.Salle !== ''
                                                ? [' ' + vDate.Salle + ' / ']
                                                : '') //MI 17.11.22 #3499879986 déplacer au debut + visite.Message_Complet
                                            : (vDate.Message_Complet !== ''
                                                ? vDate.Message_Complet + ' / '
                                                : '') +
                                            moment(new Date(vDate.Date_Debut)).format(formatDate) +
                                            ' / ' +
                                            vDate.Heure_debut +
                                            ' / ' +
                                            (vDate.Salle !== ''
                                                ? [' ' + vDate.Salle + ' / ']
                                                : ''), // MI 17.11.22, #3499879986 + visite.Message_Complet,
                                    ]}
                                    style={{color:classConflict}}
                                />

                                {vDate.DatesCours !== null ? (
                                    <>
                                    <Box>
                                        {texts.label_all_date_registration}
                                        <ListDates special={true} dates={vDate.DatesCours.vcDatesCours} small={true} dateConflict={dateConflict} />
                                    </Box>
                                    <br/>
                                    </>
                                ) : null}
                                {vDate.conflictuel && <Typography style={{ color: '#d80669' }} sx={{ whiteSpace: 'pre-line' }}>
                                    <span dangerouslySetInnerHTML={{ __html: conflitDateSession ? texts.warning_existe_conflict_simple : texts.warning_existe_conflict.replace("\\n", "<br/>") }} />
                                </Typography>}
                            </FormGroup>
                            : vDate.DateAnnulation !== '0000-00-00' ||
                                vDate.FermerInscription ?
                                <FormGroup key={GetRandomKey(9)}>
                                    <FormControlLabel
                                        key={vDate.ID}
                                        control={
                                            <Checkbox
                                                disabled
                                                onClick={(_) => handleDateSelectionCheck(indexDate)}
                                                checked={!!vDate.notChecked}
                                                name={moment(new Date(vDate.Date_Debut)).format(formatDate)}
                                                value={vDate.ID}
                                            />
                                        }
                                        label={[
                                            vDate.DatesCours !== null
                                                ? (vDate.Message_Complet !== ''
                                                    ? vDate.Message_Complet + ' / '
                                                    : '') +
                                                (vDate.DatesCours.vcDatesCours.length > 1
                                                    ? 'Session dès le : '
                                                    : 'Session du : ') +
                                                moment(new Date(vDate.Date_Debut)).format(formatDate) +
                                                ' / ' +
                                                vDate.Heure_debut +
                                                ' / ' +
                                                (vDate.Salle !== ''
                                                    ? [' ' + vDate.Salle + ' / ']
                                                    : '') + // MI 17.11.22, #3499879986 déplacer au debut
                                                // (visite.Message_Complet !== ""
                                                //   ? visite.Message_Complet + " / "
                                                //   : "") +
                                                (vDate.PreInscrit !== ''
                                                    ? vDate.PreInscrit + ' / '
                                                    : '') +
                                                (vDate.DateAnnulation !== '0000-00-00'
                                                    ? 'Session Annulée'
                                                    : '')
                                                : (vDate.Message_Complet !== ''
                                                    ? vDate.Message_Complet + ' / '
                                                    : '') +
                                                moment(new Date(vDate.Date_Debut)).format(formatDate) +
                                                ' / ' +
                                                vDate.Heure_debut +
                                                ' / ' +
                                                (vDate.Salle !== ''
                                                    ? [' ' + vDate.Salle + ' / ']
                                                    : '') + // MI 17.11.22, #3499879986 déplacer au debut
                                                // (visite.Message_Complet !== ""
                                                //   ? visite.Message_Complet + " / "
                                                //   : "") +
                                                (vDate.PreInscrit !== ''
                                                    ? vDate.PreInscrit + ' / '
                                                    : '') +
                                                (vDate.DateAnnulation !== '0000-00-00'
                                                    ? 'Session Annulée'
                                                    : ''),
                                        ]}
                                    />

                                    {vDate.DatesCours !== null ? (
                                        <Box key={GetRandomKey(9)}>
                                            {texts.label_all_date_registration}
                                            <ListDates special={true} dates={vDate.DatesCours.vcDatesCours} small={true} datesConflit={dateConflict} />
                                        </Box>
                                    ) : null}
                                    {vDate.conflictuel && <Typography>{conflitDateSession ? texts.warning_existe_conflict_simple : texts.warning_existe_conflict}</Typography>}
                                </FormGroup>
                                : null
                        )
                    })
                    }
                </FormGroup>
            </Box>

            {/*MI 14.07.2021 choix participant ou accompagnateur */}
            {
                infosAdherent.CategoryCode === 'B-A' && isAccompagnateur(userSession.users.roles, 'Accompagnateur') ? (
                    <FormControl key={GetRandomKey(9)} component="fieldset" style={{ marginTop: '20px' }}>
                        <FormLabel
                            required={true}
                            component="legend"
                        >
                            Pré-inscription en tant qu'accompagnateur?
                        </FormLabel>

                        <RadioGroup
                            aria-label="accompagnateur"
                            name="accompagnateur"
                            value={act.accompagnateur ? "ouiaccompagnateur" : "nonaccompagnateur"}
                            onChange={(e) => handleChangeAcompagnateur(e, act)}
                        >
                            <FormControlLabel
                                value="ouiaccompagnateur"
                                control={<Radio size="small" color="default" />}
                                label="Oui"
                            />
                            <FormControlLabel
                                value="nonaccompagnateur"
                                control={<Radio size="small" color="default" />}
                                label="Non"
                            />
                        </RadioGroup>
                    </FormControl>
                ) : null}

            {/*MI 08.07.2021 afficher et modifier la remarque*/}
            <TextField key={GetRandomKey(9)}
                id="remarque"
                name="remarque"
                label={texts.label_registration_remarque} //MI 02.12.22, #3609944464 "Remarque" //pour " + actChoosen.FullNature}
                variant="standard"
                type="text"
                fullWidth
                defaultValue={act?.remarqueMembre || ''}
                onBlur={(e) => handelRemarqueMembre(e, act)}
            />
        </Box>
    )
};