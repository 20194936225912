import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    paperClass: {
        padding: theme.spacing(4),
    },
}));
