import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
  Radio,
  FormLabel,
  RadioGroup,
  Button,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
// import { red } from '@material-ui/core/colors';
import http from '../../http-common'
import { useSelector } from 'react-redux'
import { MySnackbar } from '../mySnackbar/MySnackbar'
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const useStyles = makeStyles((theme) => ({
  /* formStyle: {
         padding: theme.spacing(0),
     },
     formControl: {
         width: "100%",
     },*/
  paperClass: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  btnBottomMargin: {
    //MI 02.12.2021,#1961080343
    marginBottom: '3%',
  },
  selectPays: {
    //MI 10.05.23,#4222921932
    height: '33px',
  },
}))

export default function AdheringForm() {
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const [state, setState] = useState({
    sexe: '', //M ou F
    titre: '', //n'existe pas sur le site (énumération dans 4D)
    nom: '',
    prenom: '',
    adresse1: '',
    adresse2: '',
    codepostal: '',
    pays: '',
    localite: '',
    email: '',
    telephone: '',
    mobile: '',
    datenaissance: null,//'', //MI 03.05.24, #5153103288 
    formation: '', //case à cocher à choix (énumération dans 4D)
    profession: '',
    connaissanceuni3: '', //case à cocher à choix (énumération dans 4D)
    conjointadherent: 0, //case à cocher oui, non
    numconjointadherent: '',
    nomconjointadherent: '',
    bulletinelectronique: false,
    desabonnepapier: false,
    prestationcomplementaire: '', //case à cocher oui, non (? dans 4D)
    typecotisation: '', //case à cocher à choix (énumération dans 4D)
    autorisetransfertdata: false, //case à cocher (n'existe pas dans 4D)
    googleToken: '',
  })
  const [listeFormation, setListeformation] = useState([])
  const [listeDiffusionUni3, setListediffusionUni3] = useState([])
  const [listeTypeCotisation, setListetypeCotisation] = useState([])
  const [sexeError, setSexeError] = useState(false)
  const [sexeTextError, setSexeTextError] = useState([])
  const [nomError, setNomError] = useState(false)
  const [nomTextError, setNomTextError] = useState([])
  const [preNomError, setPrenomError] = useState(false)
  const [preNomtextError, setPrenomtextError] = useState([])
  const [dateNaissanceError, setDateNaissanceError] = useState(false)
  const [minimumAgeAdhesion, setminimumAgeAdhesion] = useState(false)
  const [snackError, setSnackError] = useState(false)
  const [dateNaissancetextError, setDateNaissancetextError] = useState('')
  const [adresse1Error, setAdresse1Error] = useState(false)
  const [adresse1TextError, setAdresse1TextError] = useState([])
  const [codepostalError, setCodepostalError] = useState(false)
  const [paysError, setPaysError] = useState(false)
  const [paysTextError, setPaysTextError] = useState([])
  const [codepostalTextError, setCodepostalTextError] = useState([])
  const [localiteError, setLocaliteError] = useState(false)
  const [localiteTextError, setLocaliteTextError] = useState([])
  const [emailError, setEmailError] = useState(false)
  const [emailTextError, setEmailTextError] = useState([])
  const [formationError, setFormationError] = useState(false)
  const [formationTextError, setFormationTextError] = useState([])
  const [connaissanceUni3Error, setConnaissanceUni3Error] = useState(false)
  const [connaissanceUni3TextError, setConnaissanceUni3TextError] = useState([])
  const [conjointadherentError, setConjointadherentError] = useState(false)
  const [conjointadherentTextError, setConjointadherentTextError] = useState([])
  const [numconjointadherentError, setNumconjointadherentError] = useState(
    false,
  )
  const [
    numconjointadherentTextError,
    setNumconjointadherentTextError,
  ] = useState([])
  // const [prestationcomplementaireError, setPrestationcomplementaireError]  = useState(false);
  // const [prestationcomplementaireTextError, setPrestationcomplementaireTextError]  = useState([]);
  const [typecotisationError, setTypecotisationError] = useState(false)
  const [typecotisationTextError, setTypecotisationTextError] = useState([])
  const [nomconjointadherentError, setNomconjointadherentError] = useState(
    false,
  )
  const [
    nomconjointadherentTextError,
    setNomconjointadherentTextError,
  ] = useState([])
  const [autorisetransfertdataError, setAutorisetransfertdataError] = useState(
    false,
  )
  const [
    autorisetransfertdataTextError,
    setAutorisetransfertdataTextError,
  ] = useState([])
  const [snack, setSnack] = React.useState({})
  const [showField, setShowField] = React.useState(false)
  const [showFieldCouple, setShowFieldCouple] = React.useState(false)
  const [showFieldCotisation, setShowFieldCotisation] = React.useState(false)

  useEffect(() => {
    http.get(`/portail/enum/getFormation`).then(
      (response) => {
        setListeformation(response.data.liste)
      },
      (error) => {
        console.log(error)
      },
    )
  }, [])

  useEffect(() => {
    http.get(`/portail/enum/getDiffusionUni3`).then(
      (response) => {
        setListediffusionUni3(response.data.liste)
      },
      (error) => {
        console.log(error)
      },
    )
  }, [])

  useEffect(() => {
    http.get(`/portail/enum/getTypeCotisation`).then(
      (response) => {
        setListetypeCotisation(response.data.liste)
        // console.log(response.data.liste);// rt 01 09 2022
      },
      (error) => {
        console.log(error)
      },
    )
  }, [])

  useEffect(()=>{
    console.log('test =============================>>>>>>>>>>>>', snackError, minimumAgeAdhesion);
    if(snackError)
      setSnack({
        message: minimumAgeAdhesion ? texts.error_age_minimum_adhesion :texts.error_empty_required_adhering,
        show: true,
        severity: 'error',
      })
  }, 
  [dateNaissanceError, snackError]);
  //reCaptcha
  const handleLoaded = (_) => {
    // window.grecaptcha.ready(_ => {
    //     window.grecaptcha
    //         .execute("6LdRvDocAAAAACBWK3BT7xgl2OKQz3mnb0OzVSse", { action: "homepage" })
    //         .then(token => {
    //             console.log("token", token)
    //             setState({ ...state, "googleToken": token });
    //         })
    // })
  }
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src =
      'https://www.google.com/recaptcha/api.js?render=6LdRvDocAAAAACBWK3BT7xgl2OKQz3mnb0OzVSse'
    script.addEventListener('load', handleLoaded)
    document.body.appendChild(script)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


    const handleDateChange = (date) => { //MI 03.05.24, #5153103288
      console.log(date)
      if(date != null){
        setState({ ...state, datenaissance: date.format('YYYY-MM-DD') })
      }
  
  
  }

  const handleFormChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setState({ ...state, [name]: value })
    if ('conjointadherent' === name) {
      setShowField(target.value === 'ouiconjointadherent' ? true : false) //contruction "Elvis"
    }
    if ('typecotisation' === name) {
      setShowFieldCotisation(
        target.value.startsWith('Cotisation SPC') ? true : false,
      ) //==="Cotisation SPC"
      setShowFieldCouple(target.value.startsWith('Couple') ? true : false) //==="Couple"
    }
  }

  const handleSubmitFormClick = () => {
    console.log('state ==================>>>>>>>>>>>>>>> ', state)
    var error
    if (state.sexe === '') {
      setSexeTextError(texts.filed_required)
      setSexeError(true)
      error = true
    } else {
      setSexeError(false)
      // error = false;
    }
    if (state.nom === '') {
      setNomTextError(texts.filed_required)
      setNomError(true)
      error = true
    } else {
      setNomError(false)
      //  error = false;
    }
    if (state.prenom === '') {
      setPrenomtextError(texts.filed_required)
      setPrenomError(true)
      error = true
    } else {
      setPrenomError(false)
      //  error = false;
    }
    if (state.datenaissance === null) { //MI 03.05.24, #5153103288
      setDateNaissancetextError(texts.filed_required);      
      setDateNaissanceError(true)
      error = true
    } else {
      const dateDenaissance = new Date(state.datenaissance);
      const currentDate = new Date();
      //console.log("année Date naiss ===>",dateDenaissance.getFullYear())
      if(currentDate.getFullYear() - dateDenaissance.getFullYear() > 54 ){
        setDateNaissanceError(false);   
        setminimumAgeAdhesion(false);
      } else {
        setDateNaissanceError(true);// error = true;
        setminimumAgeAdhesion(true);
        error = true;
      }
      
    }
    if (state.adresse1 === '') {
      setAdresse1TextError(texts.filed_required)
      setAdresse1Error(true)
      error = true
    } else {
      setAdresse1Error(false)
      // error = false;
    }
    if (state.codepostal === '') {
      setCodepostalTextError(texts.filed_required)
      setCodepostalError(true)
      error = true
    } else {
      setCodepostalError(false)
      //error = false;
    }
    if (state.localite === '') {
      setLocaliteTextError(texts.filed_required)
      setLocaliteError(true)
      error = true
    } else {
      setLocaliteError(false)
      //error = false;
    }
    //MI 10.05.23,#4222921932
    if (state.pays === '') {
      setPaysTextError(texts.filed_required)
      setPaysError(true)
      error = true
    } else {
      setPaysError(false)
    }
    //Email obligatoire
    if (state.email === '') {
      setEmailTextError(texts.filed_required)
      setEmailError(true)
      error = true
    } else {
      setEmailError(false)
    }

    if (state.formation === '') {
      setFormationTextError(texts.filed_required)
      setFormationError(true)
      error = true
    } else {
      setFormationError(false)
      //error = false;
    }
    if (state.connaissanceuni3 === '') {
      setConnaissanceUni3TextError(texts.filed_required)
      setConnaissanceUni3Error(true)
      error = true
    } else {
      setConnaissanceUni3Error(false)
      // error = false;
    }
    if (state.conjointadherent === 0) {
      //MI 06.04.2022, #2510362443
      setConjointadherentTextError(texts.filed_required)
      setConjointadherentError(true)
      error = true
    } else {
      if (state.conjointadherent === 'ouiconjointadherent') {
        if (state.numconjointadherent === '') {
          setNumconjointadherentTextError(texts.filed_required)
          setNumconjointadherentError(true)
          error = true
        } else {
          setNumconjointadherentError(false)
        }
      } else {
        setConjointadherentError(false)
      }
    }
    //MI 07.04.2022, #2520122307 inutile
    // if(state.prestationcomplementaire===""){
    //     setPrestationcomplementaireTextError(texts.filed_required);
    //     setPrestationcomplementaireError(true);
    //     error = true;
    // }else{
    //     setPrestationcomplementaireError(false);
    //     //error = false;
    // }
    if (state.typecotisation === '') {
      setTypecotisationTextError(texts.filed_required)
      setTypecotisationError(true)
      error = true
    } else {
      setTypecotisationError(false)
      //error = false;
    }
    //MI 12.01.23, #3797422337
    if (
      state.typecotisation.startsWith('Couple') &&
      state.nomconjointadherent === ''
    ) {
      setNomconjointadherentTextError(texts.filed_required)
      setNomconjointadherentError(true)
      error = true
    } else {
      setNomconjointadherentError(false)
    }
    if (state.autorisetransfertdata === false) {
      setAutorisetransfertdataTextError(texts.filed_required)
      setAutorisetransfertdataError(true)
      error = true
    } else {
      setAutorisetransfertdataError(false)
      //error = false;
    }
    if (error) {
      setSnackError(true);
      return false
    } else {
      if (state.email !== '') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(state.email)) {
          setEmailError(true)
          setSnack({
            message: texts.email_invalide,
            show: true,
            severity: 'error',
          })
          return false
        }
      }
    }
    
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute('6LdRvDocAAAAACBWK3BT7xgl2OKQz3mnb0OzVSse', {
          action: 'homepage',
        })
        .then((token) => {
          // console.log("token", token)
          // setState({ ...state, "googleToken": token });
          state.googleToken = token
          http.post(`/portail/adhering/`, state).then(
            (response) => {
              if (response.data.error) {
                setSnack({
                  message: texts[response.data.message],
                  show: true,
                  severity: 'error',
                })
              } else {
                setSnack({
                  message: texts.success_adhering,
                  show: true,
                  severity: 'success',
                })
                setTimeout(function () {
                  window.location.reload(false)
                }, 8000)
                window.scrollTo(0, 0) //MI 17.08.23, #4695284685
              }
            },
            (error) => {
              console.log(error)
            },
          )
        })
    })

    //return error;
    //vider les champs après soumission si pas d'erreur
    if (error === false) {
      setState({
        sexe: '',
        titre: '',
        nom: '',
        prenom: '',
        adresse1: '',
        adresse2: '',
        codepostal: '',
        localite: '',
        email: '',
        telephone: '',
        mobile: '',
        datenaissance: null, //'', //MI 03.05.24, 5153103288 null, //
        formation: '',
        profession: '',
        connaissanceuni3: '',
        conjointadherent: 0,
        numconjointadherent: '',
        bulletinelectronique: false,
        desabonnepapier: false, //MI 22.02.24, #6079212643
        prestationcomplementaire: '',
        typecotisation: '',
        autorisetransfertdata: false,
        googleToken: '',
      })
    }
  }

  return (
    <Paper className={classes.paperClass} elevation={3}>
      <MySnackbar params={snack} onClose={() => setSnackError(false)} />
      <div>
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>Adhésion</span>
      </div>
      <div>
        <p>
          Les activités d’Uni3 sont ouvertes à toute personne de 55 ans et plus.
          Découvrez tous les avantages réservés aux adhérentes et adhérents Uni3
          sur notre site web : &nbsp;
          <a
            href="https://www.unige.ch/uni3/a-propos/avantages" //MI 15.11.22,#3493621293
            target="_blank"
            rel="noreferrer"
            style={{ color: '#d80669' }}
          >
            cliquez ici.
          </a>
        </p>
        <p>
          Les cotisations pour une année académique (du 1er septembre au 31
          août) s’élèvent à :
          <br></br>- CHF 120.00 pour une personne
          <br></br>- CHF 170.00 pour un couple
          <br></br>- CHF 0.00 pour les bénéficiaires de prestations
          complémentaires
        </p>
        <p>
          <strong style={{ color: 'red' }}>
            IMPORTANT : dans le cas d’une adhésion en couple, merci au/à la
            conjoint-e de remplir un second formulaire.
          </strong>
        </p>
      </div>
      <div className="text-adheringForm">
        <span style={{ fontSize: 20, fontWeight: 'bold' }}>
          FORMULAIRE D'ADHESION
        </span>
        <div>
          <p>
            <span
              style={{
                fontSize: 16,
                fontWeight: 'normal',
                position: 'relative',
              }}
            >
              (1) J'autorise l'Uni3 à transmettre mes données personnelles à
              l'UNIGE (invitations à des événements tout public), à des
              prestataires de services qui participent à son offre, aux
              animateurs et aux enseignants dans le cadre des cours et ateliers
              Uni3.
            </span>
          </p>
        </div>
      </div>

      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              required={true}
              error={sexeError}
              helperText={sexeTextError}
            >
              Genre
            </FormLabel>
            <RadioGroup
              aria-label="genre"
              name="sexe"
              value={state.sexe}
              onChange={handleFormChange}
            >
              <FormControlLabel
                value="F"
                control={<Radio size="small" color="default" />}
                label="Mme"
              />
              <FormControlLabel
                value="M"
                control={<Radio size="small" color="default" />}
                label="M."
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="nom"
            name="nom"
            label="Nom"
            type="text"
            fullWidth
            required={true}
            value={state.nom}
            onChange={handleFormChange}
            error={nomError}
            helperText={nomTextError}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="prenom"
            name="prenom"
            label="Prénom"
            type="text"
            fullWidth
            required={true}
            value={state.prenom}
            onChange={handleFormChange}
            error={preNomError}
            helperText={preNomtextError}
          />
        </Grid>
        <Grid item xs={3}>
          {/* <TextField
            id="datenaissance"
            name="datenaissance"
            label="Date de naissance"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            //fullWidth
            required={true}
            value={state.datenaissance}
            onChange={handleFormChange}
            error={dateNaissanceError}
            helperText={dateNaissancetextError}
          /> */}
          {/*MI 03.05.24, #5153103288 */}
          <MuiPickersUtilsProvider key={1} utils={MomentUtils}>
            <KeyboardDatePicker
                    //disabled={false}
                   cancelLabel={texts.datepicker_annuler}
                   // clearLabel={"Effacer"}
                   // clearable={true}
                    autoOk
                    id="datenaissance"
                    name="datenaissance"
                    label="Date de naissance"
                    format="DD/MM/YYYY"
                    value={state.datenaissance}
                    onChange={handleDateChange}
                    //fullWidth
                   required={true}
                   KeyboardButtonProps = {{
                      'aria-label': 'change date',
                        }}
                   minDate={"1900.01.01"}
                   maxDate={"1999.12.31"}
                   invalidDateMessage={texts.error_invalidDateNaissance_adhesion}//"Saisie non terminée sinon date invalide"
                   minDateMessage={texts.error_annee_naissance_invalid_adhesion}
                   maxDateMessage={texts.error_annee_naissance_invalid_adhesion}
                  //  error={dateNaissanceError}
                  //  helperText={dateNaissancetextError}

                  />
                </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="adresse1"
            name="adresse1"
            label="Adresse 1"
            type="text"
            fullWidth
            required={true}
            value={state.adresse1}
            onChange={handleFormChange}
            error={adresse1Error}
            helperText={
              adresse1TextError + ' Exemple : Boulevard du Théâtre 10'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="adresse2"
            name="adresse2"
            label="Adresse 2"
            type="text"
            fullWidth
            value={state.adresse2}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="codepostal"
            name="codepostal"
            label="Code postal"
            type="text"
            fullWidth
            required={true}
            value={state.codepostal}
            onChange={handleFormChange}
            error={codepostalError}
            helperText={codepostalTextError}
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="localite"
            name="localite"
            label="Localité"
            type="text"
            fullWidth
            required={true}
            value={state.localite}
            onChange={handleFormChange}
            error={localiteError}
            helperText={localiteTextError}
          />
        </Grid>
        <Grid item xs={3}>
          {/* MI 09.05.23, #4222921932
                    <TextField
                        id="pays"
                        name="pays"
                        label="Pays"
                        type="text"
                        fullWidth
                        required={false}
                        value={state.pays}
                        onChange={handleFormChange}
                        error={paysError}
                        helperText={paysTextError}
                    /> */}
          <FormControl
            className={classes.formControl}
            error={paysError}
            fullWidth
          >
            <InputLabel id="demo-simple-select-filled-label">Pays*</InputLabel>
            <Select
              labelId="Pays"
              id="pays"
              name="pays"
              value={state.pays}
              onChange={handleFormChange}
              fullWidth={true}
              required={true}
              className={classes.selectPays}
              //error={paysError}
            >
              <MenuItem value="Suisse">Suisse</MenuItem>
              <MenuItem value="France">France</MenuItem>
            </Select>
            <FormHelperText>{paysTextError}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="text"
            fullWidth
            required={true}
            value={state.email}
            onChange={handleFormChange}
            error={emailError}
            helperText={
              emailTextError +
              " Si pas d'email : passer directement par le secrétariat pour votre adhésion."
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="telephone"
            name="telephone"
            label="Téléphone fixe"
            type="text"
            fullWidth
            value={state.telephone}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="mobile"
            name="mobile"
            label="Téléphone mobile"
            type="text"
            fullWidth
            value={state.mobile}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              required={true}
              error={formationError}
              helperText={formationTextError}
            >
              Formation
            </FormLabel>
            <RadioGroup
              aria-label="formation"
              name="formation"
              value={state.formation}
              onChange={handleFormChange}
            >
              {listeFormation.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  control={<Radio size="small" color="default" />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="profession"
            name="profession"
            label="Profession"
            type="text"
            fullWidth
            value={state.profession}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              required={true}
              error={connaissanceUni3Error}
              helperText={connaissanceUni3TextError}
            >
              Connaissance d'Uni3
            </FormLabel>
            <RadioGroup
              aria-label="connaissanceuni3"
              name="connaissanceuni3"
              value={state.connaissanceuni3}
              onChange={handleFormChange}
            >
              {listeDiffusionUni3.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  control={<Radio size="small" color="default" />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              required={true} //MI 06.04.2022, #2510362443
              error={conjointadherentError}
              helperText={conjointadherentTextError}
            >
              Conjoint-e déjà adhérent-e ?
            </FormLabel>
            <RadioGroup
              aria-label="conjointadherent"
              name="conjointadherent"
              value={state.conjointadherent}
              onChange={handleFormChange}
            >
              <FormControlLabel
                value="ouiconjointadherent"
                control={<Radio size="small" color="default" />}
                label="Oui"
              />
              <FormControlLabel
                value="nonconjointadherent"
                control={<Radio size="small" color="default" />}
                label="Non"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {showField ? (
          <Grid item xs={12}>
            <TextField
              id="numconjointadherent"
              name="numconjointadherent"
              label="si oui, numéro adhérent-e conjoint-e"
              type="text"
              fullWidth
              required={true} //MI 06.04.2022, #2510362443
              value={state.numconjointadherent}
              onChange={handleFormChange}
              error={numconjointadherentError}
              helperText={numconjointadherentTextError}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.bulletinelectronique}
                onChange={handleFormChange}
                name="bulletinelectronique"
                color="default"
                //required={true}
              />
            }
            label="Je désire recevoir le bulletin Uni3 uniquement en version électronique"
            labelPlacement="start"
          />
        </Grid>
        {/* MI 22.02.2024 ajout de la coche désabonner papier*/}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.desabonnepapier}
                  onChange={handleFormChange}
                  name="desabonnepapier"
                  color="default"
                />
              }
              label={texts.desabonnerpapier_adhering}//"Je ne souhaite plus recevoir les coupons et factures par papier"
              labelPlacement="start"
            />
          </Grid>
        {/* MI 07.04.2022, #2520122307 */}
        {/* <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel
                            component="legend"
                            required={true}
                            error={prestationcomplementaireError}
                            helperText={prestationcomplementaireTextError}
                        >Etes-vous au bénéfice de prestations complémentaires ?
                        </FormLabel>
                        <RadioGroup aria-label="prestationcomplementaire" name="prestationcomplementaire" value={state.prestationcomplementaire} onChange={handleFormChange}>
                            <FormControlLabel value="ouiprestation" control={<Radio size="small" color="default"/>} label="Oui" />
                            <FormControlLabel value="nonprestation" control={<Radio size="small" color="default"/>} label="Non" />

                        </RadioGroup>
                    </FormControl>
                </Grid> */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              required={true}
              error={typecotisationError}
              helperText={typecotisationTextError}
            >
              {' '}
              Type de cotisation
            </FormLabel>
            <RadioGroup
              aria-label="typecotisation"
              name="typecotisation"
              value={state.typecotisation}
              onChange={handleFormChange}
            >
              {listeTypeCotisation.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  control={<Radio size="small" color="default" />}
                  label={item}
                />
              ))}
            </RadioGroup>
            {showFieldCotisation ? (
              <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                Veuillez s’il vous plaît faire parvenir votre attestation SPC
                par mail à uni3@unige.ch | par courrier postal | ou la remettre
                au secrétariat.
              </span>
            ) : null}
            {showFieldCouple ? (
              <Grid item xs={12}>
                <TextField
                  id="nomconjointadherent"
                  name="nomconjointadherent"
                  label="si inscription en couple, merci d'indiquer le nom et prénom de votre conjoint-e. Attention! Faire une nouvelle adhésion pour votre conjoint-e."
                  type="text"
                  fullWidth
                  required={true}
                  value={state.nomconjointadherent}
                  onChange={handleFormChange}
                  error={nomconjointadherentError} //MI 12.01.23, #3797422337
                  helperText={nomconjointadherentTextError}
                />
              </Grid>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            required={true}
            error={autorisetransfertdataError}
            helperText={autorisetransfertdataTextError}
          >
            <FormLabel component="legend">
              Autorisation de transfert de données
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.autorisetransfertdata}
                  onChange={handleFormChange}
                  name="autorisetransfertdata"
                  color="default"
                  required={true}
                />
              }
              label="J'autorise la transmission de mes données personnelles selon point (1)*"
              labelPlacement="start"
            />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          variant="contained"
          href="/"
          className={classes.btnBottomMargin}
        >
          {texts.label_registration_cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitFormClick}
          className={classes.btnBottomMargin}
        >
          {texts.label_registration_submit}
        </Button>
      </DialogActions>
      <div
        className="g-recaptcha"
        data-sitekey="6LdRvDocAAAAACBWK3BT7xgl2OKQz3mnb0OzVSse"
        data-size="invisible"
      ></div>
    </Paper>
  )
}
