import './App.css';
import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import reducer from './reducers';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import LoadDatas from './pages/loadDatas/LoadDatas'
// use default theme
// const theme = createTheme();

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#d80669'
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      }
    },
    MuiTableRow: {//row selected
      root: {
        '&$selected': {
          backgroundColor: 'rgba(216, 6, 105, 0.1) !important'
        }
      }
    },
    MuiTableCell: {//header
      head: {
        backgroundColor: "#564146 !important",
        color:"#FFF !important",
      }
    },
    MUIDataTableHeadCell: {//header sort buttons
      sortAction: {
        '& path': {
          color: "#FFF !important" // or whatever you need
        },
        color: "#FFF !important"  // whatever you need
      },
      sortActive: {
        color: "#FFF !important"  // whatever you need
      },
    },
    MUIDataTableToolbar: {//toolbar
      root: {
        backgroundColor: "#F5F5F5",
      }
    },
    MuiTextField: {
      root: {
        "& .MuiInputBase-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.7)"
        }
      }
    },
  },
});

function App() {
  let middleware = null;
  if (process?.env.NODE_ENV !== 'production') {
    middleware = applyMiddleware(thunkMiddleware, logger);
  } else {
    middleware = applyMiddleware(thunkMiddleware);
  }
 
  const store  = createStore(reducer,middleware);
  
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <LoadDatas />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;