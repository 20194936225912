import ExcelJS from 'exceljs';

const generateStyledExcelFile = async (data, fileName, title) => {
    if (!data || data.length === 0)
        return
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title.replace(/[*?:/\\[\]]/g, ''));

    // Ajouter le titre à la première ligne
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { bold: true, size: 16 }; // Style du titre
    worksheet.mergeCells(`A${titleRow.number}:${String.fromCharCode(64 + titleRow.actualCellCount)}${titleRow.number}`); // Fusionner les cellules du titre
    // worksheet.mergeCells('A1:Z1'); // Fusionner les cellules du titre
    worksheet.addRow([]); // Ajouter une ligne vide après le titre

    generateHeaders(data[0], worksheet);// creer les cellules de titre et sous-titre

    // Données
    data.forEach(row => {
        const rowData = generateRowData(row);

        const dataRow = worksheet.addRow(rowData);
        dataRow.font = { bold: false }; // Appliquer des styles aux données

        // Ajouter des bordures à chaque cellule
        dataRow.eachCell(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        dataRow.getCell(2).alignment = { horizontal: 'center' };
    });

    // Générer le fichier Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Télécharger le fichier Excel
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
};

// Fonction pour générer un tableau d'en-têtes à partir d'un objet JSON
const generateHeaders = (tableFirstRow, worksheet) => {
    const columnHeader = Object.keys(tableFirstRow);
    let headerRows = [];
    let asSubTitle = false;
    let mergeCol = [];
    let col = 0;
    for (let i = 0; i < columnHeader.length; i++) {
        const columnValue = tableFirstRow[columnHeader[i]];
        // Check if the columnValue is an object to handle the second format
        if (columnValue && typeof columnValue === 'object' && columnValue.constructor === Object) {
            headerRows.push(columnHeader[i]);
            const subHeadersName = Object.keys(columnValue);
            subHeadersName.forEach((key, i) => {
                if (i !== 0) { headerRows.push(''); }
            });
            asSubTitle = true;
            mergeCol.push([1, col + 1, 1, (col + subHeadersName.length)]);
            col += subHeadersName.length;
        } else {
            // If the columnValue is not an object, add a single row for that column
            headerRows.push(columnHeader[i]);
            col++;
        }
    }

    // En-têtes de colonne
    const headerRow = worksheet.addRow(headerRows);

    // Appliquer des styles aux en-têtes 
    headerRow.font = { bold: true };
    headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EEEEEE' } };
    headerRow.eachCell(cell => {
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
    });

    if (asSubTitle) {
        //fusion des celulles 
        for (let i = 0; i < mergeCol.length; i++) {
            const element = mergeCol[i];
            worksheet.mergeCells(...element);
        }

        // Sous-titres
        const subheaders = generateSubHeaders(tableFirstRow);
        const subheaderRow = worksheet.addRow(subheaders);
        // Appliquer des styles aux sous-titres
        subheaderRow.font = { italic: true };
    }


    return headerRows;
};

const generateSubHeaders = (rowData) => {
    const subHeaders = [];

    Object.keys(rowData).forEach(key => {
        const value = rowData[key];

        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
            // Si la propriété est un sous-objet, ajouter les propriétés comme sous-titres
            Object.keys(value).forEach(subKey => {
                subHeaders.push(subKey);
            });
        } else {
            // Si la propriété n'est pas un sous-objet, ajouter une chaîne vide
            subHeaders.push('');
        }
    });

    return subHeaders;
};

const generateRowData = (row) => {
    const rowData = [];

    const processValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            // Si la valeur est un objet, traiter récursivement ses propriétés
            Object.values(value).forEach(subValue => {
                processValue(subValue);
            });
        } else {
            // Si la valeur n'est pas un objet, l'ajouter au tableau de données
            rowData.push(value);
        }
    };

    // Traitement initial pour chaque propriété de l'objet row
    Object.values(row).forEach(value => {
        processValue(value);
    });

    return rowData;
};


export default generateStyledExcelFile;
