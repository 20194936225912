import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    accDetail: {
        width: "100%"
    },
    actionBtn: {
        marginLeft: "5px",
        marginTop: "5px",
    },
});
