
/* export default function textReducer(state = {}, action) {
    switch (action.type) {
      case "RETRIEVE_TEXTS":
       return action.payload;
      default:
        return state;
    }
} */
import { RETRIEVE_TEXTS } from "../actions/types";

const initialState = {};

const textReducer = (texts = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_TEXTS:
      return payload;

    default:
      return texts;
  }
};

export default textReducer;