import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './Registrations.css'
//import { makeStyles } from '@material-ui/core/styles'
import TextTable_EN from '../../components/customComponent/textTable_EN.json'
import TextTable_FR from '../../components/customComponent/textTable_FR.json'
import MUIDataTable from 'mui-datatables'
import http from '../../http-common'
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../components/customDialog/CustomDialog'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import {
  Grid,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  TextField,
  Link,
  Container,
  Avatar
} from '@material-ui/core'
import moment from 'moment'
import PreferenceList from '../../components/adherent/preference/Preference'
import CancelIcon from '@material-ui/icons/Cancel'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import EventBusyIcon from '@material-ui/icons/EventBusy'
// import Fab from '@material-ui/core/Fab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image'
import FolderIcon from '@material-ui/icons/Folder'
//import ConditionsGenerales from '../../assets/ConditionGeneralesUni3.pdf'
import StatusLegend from '../../components/statusLegend/StatusLegend'
import InfoIcon from '@material-ui/icons/Info'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MuiDatatableToolBar from '../../components/muiDatatableToolBar/MuiDatatableToolBar'
//import PrintIcone from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/core/styles'

export const Registrations = () => {
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const userSession = useSelector((state) => state.session)
  const langueCharger = texts.langueCharger
  const [tableTexts, setTableTexts] = useState('')
  const [registrationsList, setRegistrationsList] = useState([]) //tableau données datatable
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [snack, setSnack] = React.useState({})
  const [categoriesList, setCategoriesList] = useState([]); //MI 29.04.24,#5153096401
  const dispatch = useDispatch();
  const [myDialog, setMyDialog] = React.useState({
    show: false,
    title: '',
    body: '',
    extraButtons: null,
    subtitle: null,
    jsxComponent: null,
  })
  //persist table state
  const [sortOrder, setSortOrder] = useState({
    name: 'Date_Debut',
    direction: 'desc',
  })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [clickPrint, setClickPrint] = useState(false) //MI 01.03.23, #3963967744
  //form demande annulation inscription
  let iban = React.createRef()
  let remarque = React.createRef()

  //MI 13.01.2022, #2127031892
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '12',
          color: 'white',
          backgroundColor: '#d80669',
        },
      },
    },
  })

 

  useEffect(() => {
    if (langueCharger === 'FR') {
      setTableTexts(TextTable_FR)
    } else {
      setTableTexts(TextTable_EN)
    }
  }, [tableTexts, langueCharger])

  useEffect(() => {
    setLoading(true)
    http.get('/portail/registration/getAttribution').then(
      (response) => {
        console.log(response.data.liste);// rt 01 09 2022
        setRegistrationsList(response.data.liste)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      },
    )

  }, [reload])

  //MI 16.02.24, #5153096401
  useEffect(() => {
       http.get(`/portail/enum/getCategories`).then((response) => {
            setCategoriesList(response.data)
        }, (error) => {
            console.log(error);
        });
        }, [dispatch]);

  const getStatusLegend = (stat) => {
    let retour = ''
    let status = [
      {
        id: 1,
        title: 'En traitement',
        content: 'préinscription en cours de traitement.',
      },
      {
        id: 2,
        title: "Sur liste d'attente",
        content: "vous êtes sur liste d'attente.",
      },
      { id: 3, title: 'Activité annulée', content: "l'activité est annulée." },
      {
        id: 4,
        title: 'Confirmé',
        content:
          'Inscription confirmée. Si activité payante, la facture suivra.',
      },
      {
        id: 5,
        title: "En attente d'annulation",
        content: "vous avez demandé l'annulation de votre inscription.",
      },
      {
        id: 6,
        title: 'Inscription annulée',
        content: 'votre inscription a été annulée à votre demande.',
      },
    ]

    for (let index = 0; index < status.length; index++) {
      if (status[index].title === stat) retour = status[index].content
    }
    return retour
  }

  function checkRole(role) {
    let retour = false
    for (let index = 0; index < userSession.users.roles.length; index++) {
      const element = userSession.users.roles[index]
      if (element.Titre === role) {
        retour = true
      }
    }
    return retour
  }

  //MI 27.01.2023, #3882577006
  function isAccompagnateur(arr, titre) {
    return arr.some(function (el) {
      return el.Titre === titre
    })
  }

  const cancelRegistration = (attrID, attrPaiement, price) => {
    let rowData = registrationsList.find((elm) => attrID === elm.ID)
    let pTitle = texts.cancel_inscr_modal_title
    let accompVisite =
      rowData.Accompagnateur && rowData.Cat === 'Visite culturelle' //MI 27.01.2023, #3882577006 //isAccompagnateur(userSession.users.roles, 'Accompagnateur')
    let billetterie = rowData.Cat === 'Billetterie' //MI 15.02.23, #3882577006

    let pBody = [
      <Grid key={1} container spacing={2}>
        <Grid key={1} item xs={12} sm={12}>
          <h5>{rowData.Nom}</h5>
          <p>
            Pour confirmer votre demande d’annulation et que celle-ci soit
            envoyée au secrétariat, cliquez sur le bouton rouge "Renoncer à
            cette activité".
          </p>
          {/* MI 15.10.2021 iban pas obligatoire si activité gratuite*/}
          {/* <TextField
                    label={texts.cancel_inscr_modal_iban_field}
                    id="iban"
                    name="iban"
                    fullWidth
                   // required={true}
                    inputRef={iban}
                    helperText={(<> Remboursement suivant les <a href ="https://www.unige.ch/uni3-new/conditions-generales" target="_blank" rel="noreferrer" style={{color:"#d80669"}}>Conditions générales.</a></>)}//{texts.cancel_inscr_modal_iban_helper}
                /> */}
          {
            price > 0 && accompVisite === false && billetterie === false ? ( //MI 27.01.2023, #3882577006//activité payante //attrPaiement !== "0000-00-00" ||
              <TextField
                label={texts.cancel_inscr_modal_iban_field}
                id="iban"
                name="iban"
                fullWidth
                required={true}
                inputRef={iban}
                helperText={
                  <>
                    {' '}
                    Les coordonnées bancaires sont à renseigner si vous avez
                    déjà réglé l’activité. Remboursement suivant les{' '}
                    <a
                      href="https://www.unige.ch/uni3/conditions-generales" //MI 15.11.22
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#d80669' }}
                    >
                      Conditions générales.
                    </a>
                  </>
                } //{texts.cancel_inscr_modal_iban_helper}
              />
            ) : null
            // <TextField
            // label={texts.cancel_inscr_modal_iban_field}
            // id="iban"
            // name="iban"
            // fullWidth
            // inputRef={iban}
            // disabled="true"
            // helperText={texts.cancel_inscr_modal_iban_helper_not_paid}
            // />
          }
        </Grid>
        <Grid key={2} item xs={12} sm={12}>
          <TextField
            label={texts.cancel_inscr_modal_remarque_field}
            id="remarque"
            name="remarque"
            fullWidth
            required={false}
            inputRef={remarque}
            helperText={texts.cancel_inscr_modal_remarque_helper}
          />
        </Grid>
      </Grid>,
    ]

    function deleteConfirm() {
      var error = false
      var invalideIban = false //MI 29.04.2022, #2609394719
      let regex = new RegExp(
        '^([A-Z]{2}[ ' +
          '\\' +
          '-]?[0-9]{2})(?=(?:[ ' +
          '\\' +
          '-]?[A-Z0-9]){9,30}$)((?:[ ' +
          '\\' +
          '-]?[A-Z0-9]{3,5}){2,7})([ ' +
          '\\' +
          '-]?[A-Z0-9]{1,3})?$',
      )

      if (iban.current !== null) {
        //&& price > 0
        // console.log(iban.current.value);
        if (
          iban.current.value === '' &&
          price > 0 &&
          attrPaiement !== '0000-00-00'
        ) {
          //MI 29.04.2022, #2609394719 iban vide pour activité payante et déjà payée
          error = true
        } else {
          //MI 29.04.2022, #2609394719
          // console.log(regex.test(iban.current.value));
          if (regex.test(iban.current.value) === false) {
            error = true
            invalideIban = true
          }
        }
      } else {
      }
      // rt 01 09 2022
      let valideAnnulation = true
      // if (remarque.current.value === "" || error) {
      if (error) {
        if (invalideIban) {
          //MI 29.04.2022, #2609394719
          setSnack({
            message: texts.iban_invalid,
            show: true,
            severity: 'error',
          })
        } else {
          setSnack({
            message: texts.iban_empty,
            show: true,
            severity: 'error',
          })
        }
        valideAnnulation = false
      }
      // else {
      //   setSnack({
      //     message: texts.remarque_renounce_empty,
      //     show: true,
      //     severity: "error",
      //   });
      // }
      // }
      // console.log(remarque.current.value);
      if (valideAnnulation) {
        let params = {}
        params.iban = iban.current ? iban.current.value : '' //iban.current === null ? "" : iban.current.value
        params.remarque = remarque.current.value
        params.attributionID = attrID
        setLoading(true)
        http.post('/portail/registration/cancelAttribution', params).then(
          (response) => {
            if (response.data.error) {
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'error',
              })
            } else {
              setSnack({
                message: texts[response.data.message],
                show: true,
                severity: 'success',
              })
              setReload(!reload)
              setMyDialog({ show: false })
            }
            setLoading(false)
          },
          (error) => {
            setLoading(false)
            console.log(error)
          },
        )
      } // rt 13 09 2022
    }

    let pExtraButtons = (
      <Button
        onClick={deleteConfirm}
        style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}
        variant="contained"
        type="button"
      >
        {texts.label_registration_renounce}
      </Button>
    )

    setMyDialog({
      show: true,
      title: pTitle,
      body: pBody,
      extraButtons: pExtraButtons,
      subtitle: null,
      jsxComponent: null,
    })
  }

  function SubRow(props) {
    //console.log("props=", props);
    return (
      <TableRow>
        <TableCell colSpan={7} className={classes.subRowCell}>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justify="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12}>
              {props.rows}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    )
  }

  const ReturnState = ({ rowData }) => {
    // console.log("rowData=", rowData);

    let result = ''

    if (rowData) {
      //JC 30.06.22, on travaille avec l'objet!!
      // let accompagnateur = rowData[6];
      // let cancelDemandDate = rowData[10];
      // let cancelDate = rowData[11];
      // let confirmDate = rowData[12];
      // let paiementDate = rowData[13];
      // let canceledActivity = rowData[15];

      let accompagnateur = rowData.Accompagnateur
      let cancelDemandDate = rowData.Demande_annulation
      let cancelDate = rowData.Annulee_le
      let confirmDate = rowData.Confirme_le
      let paiementDate = rowData.Date_paiement
      let canceledActivity = rowData.statut_activite

      if ((confirmDate !== '0000-00-00') | accompagnateur) {
        result = 'Confirmé'
      }
      if (
        cancelDemandDate === '0000-00-00' &&
        cancelDate === '0000-00-00' &&
        confirmDate === '0000-00-00'
      ) {
        result = 'En attente confirmation'
      }
      //&& (confirmDate === "0000-00-00")
      if (cancelDemandDate !== '0000-00-00' && cancelDate === '0000-00-00') {
        result = "En attente d'annulation"
      }
      if (cancelDate !== '0000-00-00') {
        result = 'Inscription annulée'
      }

      if (paiementDate !== '0000-00-00') {
        result = 'Payé'
      }
      if (canceledActivity) {
        result = texts.preferenceTable_canceled_activity
      }
    }
    // rt 1 09 2022
    let statusText = getStatusLegend(
      result === 'Annulée' ? texts.preferenceTable_canceled_activity : result,
    )

    return (
      <div>
        <span style={result === "Confirmé" ? {color: "#4CCD99", fontWeight:"bold"}: null}>{result}</span>
        {statusText != '' ? (
          <MuiThemeProvider theme={theme}>
            <Tooltip title={statusText}>
              <InfoIcon fontSize="small" htmlColor="#564146" />
            </Tooltip>
          </MuiThemeProvider>
        ) : (
          ''
        )}
      </div>
    )
  }

  const ReturnActionBtn = ({ rowData, value }) => {
    let result = ''
    if (rowData) {
      // let cancelDemandDate = rowData[10];
      // let cancelDate = rowData[11];
      // /* let confirmDate = rowData[12] */
      // let dateDebut = rowData[3];
      // let paiementDate = rowData[13];
      // let canceledActivity = rowData[15];
      // let priceActivity = rowData[16];

      //JC 30.06.22, on travaille avec l'objet!!
      let cancelDemandDate = rowData.Demande_annulation
      let cancelDate = rowData.Annulee_le
      let dateDebut = rowData.Date_Debut
      let paiementDate = rowData.Date_paiement
      let canceledActivity = rowData.statut_activite
      let priceActivity = rowData.Prix

      if (moment(dateDebut).isAfter(moment())) {
        //&& (confirmDate === "0000-00-00") car même confirmé l'adhérent peut renoncer à une activité
        if (
          cancelDemandDate === '0000-00-00' &&
          cancelDate === '0000-00-00' &&
          canceledActivity === false
        ) {
          result = (
            <Tooltip title="Renoncer à l'activité">
              <IconButton
                onClick={() => {
                  cancelRegistration(value, paiementDate, priceActivity)
                }}
                color="primary"
                component="span"
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )
        }
      }
    }

    return result
  }

  const handleFileOpen = (type, ID) => {
    // console.log(type, ID);
    window.open('/portail/activities/viewResource?resourceID=' + ID)
  }

  const ReturnResourcesBtn = ({ value }) => {
    let result = []
    for (let index = 0; index < value.length; index++) {
      const element = value[index]
      switch (element.Type) {
        case 'URL':
          result.push(
            <Tooltip key={index} title={element.Contenu}>
              <Link href={element.Contenu} rel="noopener" target="_blank">
                <IconButton color="primary" component="span" size="small">
                  {/* MI 29.09.2021 texte au lieu de l'icone lien #1684445106*/}
                  {texts.registrationTable_accesContenuLigne}
                  {/* <LinkIcon /> */}
                </IconButton>
              </Link>
            </Tooltip>,
          )
          break
        case 'document':
          result.push(
            <Tooltip key={index} title={element.Contenu}>
              <IconButton
                color="primary"
                component="span"
                onClick={(_) => handleFileOpen(element.Type, element.ID)}
              >
                <FolderIcon />
              </IconButton>
            </Tooltip>,
          )
          break
        case 'image':
          result.push(
            <Tooltip key={index} title={element.Contenu}>
              <IconButton
                color="primary"
                component="span"
                onClick={(_) => handleFileOpen(element.Type, element.ID)}
              >
                <ImageIcon fontSize="medium" />
              </IconButton>
            </Tooltip>,
          )
          break
        //MI 27.04.2022, #2597945764
        case 'text':
          result.push(element.Contenu)
          break
        default:
          //nothing
          break
      }
    }
    return result
  }

     //MI 16.02.24, #5153096401 fonction pour récupérer la catégorie de l'activité
  const getCategoryAttribute = (ref, type) => {
 // console.log(categoriesList);
    let retour = ''
    for (let index = 0; index < categoriesList.length; index++) {
      if (ref !== null) {
        if (categoriesList[index].text === ref)
          retour = categoriesList[index][type]
        console.log(categoriesList[index][type]);
      }
    }

    return retour
  }

  const columns = [
    {
      label: 'ID',
      name: 'ID',
      options: {
        viewColumns: false,
        filter: false,
        display: 'excluded',
        download: false,
      },
    },
    {
      label: texts.registrationTable_columnName,
      name: 'Nom',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // tableMeta.rowData[6]  ? <div><Tooltip title="Accompagnant"><AccessibilityNewIcon color="primary" /></Tooltip><span>{value}</span></div>:value
          //JC 30.06.22 on utilise l'objet et ses attributs tableMeta.rowData[15] ? (
          // rt 13 07 2022 rechercher l'element par son id car le tableMeta.rowIndex n'est pas corrélé
          let data = registrationsList.find(
            (elm) => tableMeta.rowData[0] == elm.ID,
          )
          // console.log('data => ', data, tableMeta.tableData[tableMeta.rowIndex]);
          return data.statut_activite ? (
            <div>
              <Tooltip title="Activité annulée">
                <EventBusyIcon color="primary" />
              </Tooltip>
              <span>{value}</span>
            </div>
          ) : //JC 30.06.22 on utilise l'objet et ses attributs  ) : tableMeta.rowData[6] ? (
          data.Accompagnateur ? (
            <div>
              <Tooltip title="Accompagnant">
                <AccessibilityNewIcon color="primary" />
              </Tooltip>
              <span>{value}</span>
            </div>
          ) : (
            value
          )
        },
      },
    },
    {
      label: 'Catégorie',
      name: 'Cat',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
         customHeadRender: (columnMeta, updateDirection) => ( //MI 28.02.24, #5153184889
                <th
                    className="MuiTableCell-root MuiTableCell-head"
                    key={columnMeta.index}
                    style={{textAlign: "center",}}
                >
                    Catégorie
                </th>
            ),
        customBodyRender: (value, tableMeta, updateValue) => (  //MI 29.04.24, #5153096401                  
                   value ? <> 
                   <Container className={classes.pastille} maxWidth ="xs" style={{
                    display: "flex",
                    justifyContent: "center",
                    margin:"0"}}> 
                   <Avatar
                      aria-label="recipe"
                     className={classes.avatarRegistration}
                      style={{                  
                        background: getCategoryAttribute(
                          value,
                          'color',
                        ),
                      }}
                    >                   
                      &nbsp;                     
                    </Avatar> 
                    </Container >              
                    <div style={{textAlign: "center",}}>
                    {value}
                    </div>
                    
                    </>: "" //value.text : ""
                   
                )
      },
    },
    {
      label: texts.registrationTable_columnPeriod,
      name: 'period',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
      },
    },
    {
      label: texts.registrationTable_columnDate,
      name: 'Date_Debut',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
        customBodyRender: (value, tableMeta, updateValue) =>
          moment(value).format('DD.MM.YYYY'),
      },
    },
    {
      label: texts.registrationTable_columnDe,
      name: 'Heure_debut',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: texts.registrationTable_columnA,
      name: 'Heure_fin',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'En tant que',
      name: 'Accompagnateur',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: checkRole('Accompagnateur'),
        display: checkRole('Accompagnateur'),
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? 'Accompagnateur' : 'Participant',
      },
    },
    {
      label: 'Type',
      name: 'Nature',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Date cours',
      name: 'DatesCours',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Detail',
      name: 'detail',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Statut',
      name: 'Demande_annulation',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          //JC 30.06.22, on travaille avec l'objet au lieu du tableau des contenus
          //<ReturnState rowData={tableMeta.rowData} />
          // rt 13 07 2022 rechercher l'element par son id car le tableMeta.rowIndex n'est pas corrélé
          let data = registrationsList.find(
            (elm) => tableMeta.rowData[0] == elm.ID,
          )
          return <ReturnState rowData={data} />
        },
      },
    },
    {
      label: 'Annulee_le',
      name: 'Annulee_le',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Confirme_le',
      name: 'Confirme_le',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Date_paiement',
      name: 'Date_paiement',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
        // customBodyRender: (paiementDate, tableMeta, updateValue) => (
        //     <ReturnActionBtn rowData={tableMeta.rowData} value={paiementDate} />
        // ),
      },
    },
    {
      label: texts.registrationTable_columnResources,
      name: 'resources',
      options: {
        viewColumns: false,
        filter: false,
        download: false,
        print: false,
        setCellProps: () => ({ style: { minWidth: '100px' } }),
        //   customBodyRender: (value, tableMeta, updateValue) =>
        //     //MI 02.05.2022, #2597945764 non annuléé ni en annulation et payé ou gratuit
        //     tableMeta.rowData[10] === "0000-00-00" &&
        //     tableMeta.rowData[11] === "0000-00-00" &&
        //     (tableMeta.rowData[13] != "0000-00-00" ||
        //       tableMeta.rowData[16] === 0) ? (
        //       <ReturnResourcesBtn value={value} />
        //     ) : null,
        // },
        //JC 30.06.22, on utilise objet et ses attributs
        customBodyRender: (value, tableMeta, updateValue) =>
          //MI 02.05.2022, #2597945764 non annuléé ni en annulation et payé ou gratuit
          tableMeta.tableData[tableMeta.rowIndex].Demande_annulation ===
            '0000-00-00' &&
          tableMeta.tableData[tableMeta.rowIndex].Annulee_le === '0000-00-00' &&
          (tableMeta.tableData[tableMeta.rowIndex].Date_paiement !=
            '0000-00-00' ||
            tableMeta.tableData[tableMeta.rowIndex].Prix === 0) ? (
            <ReturnResourcesBtn value={value} />
          ) : null,
      },
    },
    {
      label: 'Annulee',
      name: 'statut_activite',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Prix',
      name: 'Prix',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: texts.registrationTable_columnAction,
      name: 'ID',
      options: {
        viewColumns: false,
        filter: false,
        download: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //JC 30.06.22, on travaille avec l'objet au lieu du tableau des contenus
          //<ReturnActionBtn rowData={tableMeta.rowData} value={value} />
          // rt 13 07 2022 rechercher l'element par son id car le tableMeta.rowIndex n'est pas corrélé
          let data = registrationsList.find(
            (elm) => tableMeta.rowData[0] == elm.ID,
          )
          return <ReturnActionBtn rowData={data} value={value} />
        },
      },
    },
  ]

  const options = {
    filter: checkRole('Accompagnateur'),
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    selectToolbarPlacement: 'none',
    // onRowSelectionChange: handleLineSelection,
    responsive: 'vertical',
    sortOrder: sortOrder,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    tableBodyHeight: 'auto',
    fixedHeader: true,
    download: false,
    viewColumns: false,
    downloadOptions: {
      filename: 'export_' + texts.title_registration + '.csv',
      separator: ';',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data)
    },
    //  onRowClick: handleLineSelection,
    textLabels: tableTexts,
    onTableChange: (action, tableState) => {
      //persist table state
      switch (action) {
        case 'sort':
          setSortOrder(tableState.sortOrder)
          break
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage)
          break
        case 'propsUpdate':
          tableState.sortOrder = sortOrder
          tableState.rowsPerPage = rowsPerPage
          break
        default:
          break
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (
        registrationsList[dataIndex].DatesCours !== null ||
        registrationsList[dataIndex].Date_Debut !== ''
      ) {
        return true
      } else {
        return false
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      //JC 30.06.22, on utilise l'objet au lieu du numéro du champ
      // return <SubRow rows={registrationsList[rowMeta.rowIndex].detail} />;
      // RT 12.07.22 l'index est pas syncrone donc on utilise l'array
      return <SubRow rows={rowData[10]} />
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
    rowsExpanded: clickPrint ? registrationsList.map((_, i) => i) : [], //MI 01.03.23, #3963967744
    customToolbar: () => {
      const printExpanded = () => {
        if (clickPrint) {
          setClickPrint(false)
        } else {
          setClickPrint(true)
        }

        //console.log('clickPrint===>', clickPrint)
      }
      return (
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={
              (clickPrint ? 'Masquer' : 'Afficher') +
              " les compléments d'informations des inscriptions"
            }
          >
            <IconButton
              onClick={() => {
                printExpanded()
              }}
              component="span"
            >
              <ExpandMoreIcon
                style={
                  clickPrint
                    ? { transform: 'rotate(180deg)' }
                    : { transform: 'rotate(0deg)' }
                }
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      )
    },
  }

  // const components = {
  //     ExpandButton: function (props) {
  //         if (props.dataIndex) {
  //             return <Fab size="small" color="primary" aria-label="add">
  //                         <ExpandMoreIcon />
  //                     </Fab>
  //         } else {
  //             return ""
  //         }
  //     }
  // };

  return (
    <>
      <CustomDialog params={myDialog} />
      <MySnackbar params={snack} />
      <LoadingIndicator open={loading} />
      <main className={classes.content}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <PreferenceList />
          </Grid>
          <Grid item sm={12} md={6}>
            {/* MI 13.01.2022, #2127031892 legende plus utile*/}
            {/* <StatusLegend text={"Registration"}/>  */}

            <MUIDataTable
              title={texts.title_registration_assigned}
              data={registrationsList}
              columns={columns}
              options={options}
              // components={components}
            />
          </Grid>
        </Grid>
      </main>
    </>
  )
}

export default Registrations
