
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import InfoIcon from "@material-ui/icons/Info";
import GetRandomKey from '../general/randomKey';
import { Box } from '@material-ui/core';

export default function ListDates(props) {
  const [hover, setHover] = useState(false)
  const dates = props?.dates?.sort(function (a, b) {
    return new Date(a.startDate) - new Date(b.startDate);
  });
  
  const dateConflict = useMemo(()=>{
    if (props?.dateConflict)
      return props.dateConflict;
    return [];
  },[props]);
  
  const hundelEnterUl = () => {
    setHover(true)
  }

  const hundelLeaveUl = () => {
    setHover(false)
  }

  const hundelClickUl = () => {
    setHover((state) => !state)
  }


  const formatDate = props?.formatDate || 'ddd DD.MM.YYYY';
  const listDates = dates?.map((dates) =>
    <li
      key={GetRandomKey(9)}
      style={
        dateConflict.some(dateConflict =>  dateConflict?.dateAttribuee === dates )
          ? { color: '#d80669' }
          : {}
      }
    >
      {moment(new Date(dates.startDate ? dates.startDate : dates)).format(formatDate)}
    </li>
  );
  const classSpecial = props.special ? ' special': ''; 

  return (
    <Box
      key={GetRandomKey(9)}
      onMouseEnter={hundelEnterUl}      
      onClick={hundelEnterUl}
      onMouseLeave={hundelLeaveUl}
      className={props?.small 
        ? (hover ? 'listeDateUl hoverUl'+ classSpecial : 'listeDateUl' + classSpecial) 
        : (hover ? 'hoverUl' : '')}
    >
      {props?.small && <InfoIcon fontSize="small" htmlColor="#564146" onClick={hundelClickUl} />}
      {<ul>{listDates}</ul>}
    </Box>

  );
}