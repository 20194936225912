import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { useStyles } from "./LoadingIndicator.css";

export default function LoadingIndicator(props) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress size="10rem" color="inherit" />
    </Backdrop>
  );
}
