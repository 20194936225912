import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { AppBar, Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import IdentityTab from './IdentityTab';
import ContributionTab from './ContributionTab';
import AdditionalInfosTab from './AdditionalInfosTab';
import ResignationTab from './ResignationTab';
import HistoricalClassesTabV2 from './HistoricalClassesTabV2';
import HistoricalVisitsTab from './HistoricalVisitsTab';
import InvoiceTab from './InvoiceTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth : '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const  InfosMemberTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const texts = useSelector(state => state.texts);
  const selectedMemberID =props.vSelectedMemberID;

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
  }, [])

  return (
    <span className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
            <Tab label={texts.member_tab_identity} {...a11yProps(0)} />
            <Tab label={texts.member_tab_contribution} {...a11yProps(1)} />
            <Tab label={texts.member_tab_additionalInfo} {...a11yProps(2)} />
            <Tab label={texts.member_tab_resignation} {...a11yProps(3)} />
            <Tab label={texts.member_tab_historicalClasses} {...a11yProps(4)} />
            <Tab label={texts.member_tab_historicalVisits} {...a11yProps(5)} />
            <Tab label={texts.member_tab_historicalExcursion} {...a11yProps(6)} />
            <Tab label={texts.member_tab_historicalTicketing} {...a11yProps(7)} />
            <Tab label={texts.member_tab_invoices} {...a11yProps(8)} />
            
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} >{/*member_tab_identity*/}
        <IdentityTab 
          vSelectedMemberID={selectedMemberID}
        />
      </TabPanel>
      <TabPanel value={value} index={1} >{/*member_tab_identity*/}
        <ContributionTab 
          vSelectedMemberID={selectedMemberID}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>{/*member_tab_additionalInfo*/}
        <AdditionalInfosTab
          vSelectedMemberID={selectedMemberID}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>{/*member_tab_resignation*/}
        <ResignationTab
          vSelectedMemberID={selectedMemberID}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>{/*member_tab_historicalVisits*/}
        <HistoricalClassesTabV2
          vSelectedMemberID={selectedMemberID} vType="GRPE" vTitle={texts.member_tab_historicalClasses}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>{/*member_tab_historicalVisits*/}
        <HistoricalVisitsTab
          vSelectedMemberID={selectedMemberID} vType="VGE" vTitle={texts.member_tab_historicalVisits}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>{/*member_tab_historicalVisits*/}
        <HistoricalVisitsTab
          vSelectedMemberID={selectedMemberID} vType="VHC" vTitle={texts.member_tab_historicalExcursion}
        />
      </TabPanel>
      <TabPanel value={value} index={7}>{/*member_tab_historicalVisits*/}
        <HistoricalVisitsTab
          vSelectedMemberID={selectedMemberID} vType="Billetterie" vTitle={texts.member_tab_historicalTicketing}
        />
      </TabPanel>
      <TabPanel value={value} index={8}>{/*member_tab_invoices*/}
        <InvoiceTab
          vSelectedMemberID={selectedMemberID}
        />
      </TabPanel>
    </span>
  );
}

export default InfosMemberTabs;
  

