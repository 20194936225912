
import { RETRIEVE_VERSION } from "../actions/types";

const initialState = {};

const versionReducer = (version = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_VERSION:
      return payload;

    default:
      return version;
  }
};

export default versionReducer;