

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import http from "../../http-common";
import debounce from "lodash/debounce";


export default function CustomAutocomp(props) {
   
   
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [currentValue, setCurrentValue] = useState("");
    const texts = useSelector(state => state.texts);
    

    const handleSearch = (pCurrentValue) => {
        
        let params = {};
        params.value = pCurrentValue
        params.url = props.params.url;
        
        http.post(props.params.url, params).then((response) => {
            const rows = response.data;
            setOptions(rows);
        }, (error) => {
            console.log(error);
        });
    }
    const handleSearchDelayed = useCallback(
        debounce(handleSearch, 1000)
        , []);
       

    useEffect(() => {
        if (!props.params.delayAndRequest) {
            if (!loading) {
                return undefined;
            }
            handleSearch(currentValue)
        } else {
            if (currentValue.length >= 3) {
                handleSearchDelayed(currentValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, props.params.url, currentValue]);



    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleInputChange = (event,value) => {
        setCurrentValue(value)
    }

    return (
        <Autocomplete
            id={props.params.autoCompName}
            multiple={props.params.multiple}
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={props.params.handleAutocompChange(props.params.autoCompName)}
            getOptionDisabled={props.params.handleOptionDisabled}
            // getOptionDisabled={(option) => console.log(option)}
            getOptionLabel={(option) => option.text}
            options={options}
            loading={loading}
            onInputChange={handleInputChange}
            value={props.params.autoCompState}
            disabled={props.params.isDisabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.params.autoCompLabel}
                    name={props.params.autoCompName}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    error={props.params.error}
                    helperText={props.params.error !== undefined ? texts.required_field  : ""}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option.id}
                        color="primary"
                        label={option.text}
                        {...getTagProps({ index })}
                    />
                ))}
        />
    );
}
