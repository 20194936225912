import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export default function CustomSnackbar({ open, onClose, messageError, messageSuccess, severity }) {

  return (
    <React.Fragment>
      {(messageError || messageSuccess) && (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
          <div>
            {messageError && <Alert onClose={onClose} severity={severity} style={{ backgroundColor: '#f44336', color: '#fff', fontWeight: 'bold' }}>
              {messageError.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </Alert>}

            {messageSuccess && <Alert onClose={onClose} severity={severity} style={
              { backgroundColor: '#4caf50', color: '#fff', fontWeight: 'bold', position: 'absolute', top: '-250px', minWidth: '400px' }
            }>
              {messageSuccess.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </Alert>}
          </div>
        </Snackbar>
      )}
    </React.Fragment>
  );
}
