
import { TOGGLE_LOADING } from "../actions/types";

const initialState = true;

const loadingReducer = (loading = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TOGGLE_LOADING:
      return !loading;
    default:
      return loading;
  }
};

export default loadingReducer;