
import React from 'react';
import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
// import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import InfosActivityTabs from './Tabs/InfosActivityTabs'

const useStyles = makeStyles((theme) => ({
    cardBackground: {
        background: "#564146",
        color: "#FFF"
    },
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    label: {
        textAlign: "left",
    },
    containerAlignementPadding: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        marginBottom: '0%'
    },
    input: {
        "& .MuiInputBase-root": {
            color: '#808080',
            cursor: 'not-allowed'
        },
        cursor: 'not-allowed'
    },
}));

const InfosActivity = (props) => {
    const classes = useStyles();
    const selectedActivity = props.vSelectedActivity;

    return (
        selectedActivity ?
            <Card >
                <CardContent className={classes.cardBackground}>
                    <Grid container spacing={1}>
                        <Grid container className={classes.gridContainerstyle}>
                            <Grid item xs={12} >
                                <h2>{selectedActivity.ID>0?selectedActivity.Nom:"Nouvelle activité"}</h2>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <InfosActivityTabs
                        vSelectedActivityID={selectedActivity.ID}
                        selectedActivityName={selectedActivity.Nom}
                        selectedActivityNature={selectedActivity.Nature}
                        handleRowChange={props.handleRowChange}
                        setSelectedActivity={props.setSelectedActivity}
                        fieldError={props.fieldError}
                        setHideAction={props.setHideAction}
                        setReload={props.setReload}
                        reload={props.reload}
                    />
                </CardActions>
            </Card> : null
    )
}

export default InfosActivity;