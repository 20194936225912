import React, { useEffect, Text } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import MoreIcon from '@material-ui/icons/MoreVert';
import AppsIcon from '@material-ui/icons/Apps';
import People from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CategoryIcon from '@material-ui/icons/Category';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import Logo from '../../assets/uni3_logo_portail.png'
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import http from "../../http-common";
import HomeIcon from '@material-ui/icons/Home';
import { useDispatch } from "react-redux";
import { retrieveVersion } from '../../actions/version';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  linkMenuHome: {
    flex: '1 1 30%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: '5 1 90%',
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconWhite: {
    color: "#ffffff"
  },
  iconBlack: {
    color: "#000000",
    textDecoration: 'none'
  }
}));


export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const texts = useSelector(state => state.texts);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userSession = useSelector(state => state.session);
  const version = useSelector(state => state.version);
  // const  [userAdherent, setUserAdherent] = React.useState(false);

  // function isAdherent(arr, titre) {
  //   return arr.some(function(el) {
  //     return el.Titre === titre;
  //   }); 
  // }

  // useEffect(() => {
  //   if(userSession.connected){
  //     setUserAdherent(isAdherent(userSession.users.roles, "Adhérent"))
  //   }
  // }, [userSession])

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const mobileMenuId = 'primary-search-account-menu-mobile';


  const handleDeco = () => {
    var param = {};
    http.post(`/portail/user/sessionLogout`, param).then((response) => {
      handleMobileMenuClose();
      history.push('/login');
    }, (error) => {
      console.log(error);
    });
  };

  const redirectProfil = () => {
    history.push('/profil');
  };



  const RenderMobileMenu = (props) => {
    return (
      (userSession.connected && userSession.users.members.length === 1) ?
        <div>
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            {userSession.users.menuItem.map((item, index) => (
              <Link key={index} onClick={handleMobileMenuClose} to={item.link} className={classes.iconBlack}>
                <MenuItem >
                  <IconButton color="inherit">
                    {item.link === "/home" ? <HomeIcon /> : null}
                    {item.link === "/activities" ? <AppsIcon /> : null}
                    {item.link === "/registrations" ? <AssignmentIcon /> : null}
                    {item.link === "/cotisations" ? <LocalAtmIcon /> : null}
                    {item.link === "/invoices" ? <DescriptionIcon /> : null}
                    {item.link === "/users" ? <AccountBoxIcon /> : null}
                    {item.link === "/liste-activities" ? <ListIcon /> : null}
                    {item.link === "/adherents" ? <People /> : null}
                    {item.link === "/rubriques" ? <CategoryIcon /> : null}
                    {item.link === "/intervenants" ? <EmojiPeopleIcon /> : null}
                    {item.link === "/statistiques" ? <EqualizerIcon /> : null}
                    {item.link === "/presences" ? <EventAvailableIcon /> : null}
                  </IconButton>
                  <p>{texts[item.nom]}</p>
                </MenuItem>
              </Link>
            ))}
            <MenuItem onClick={handleDeco}>
              <IconButton color="inherit">
                <ExitToAppIcon />
              </IconButton>
              <p>{texts.menu_deco_btn}</p>
            </MenuItem>
          </Menu>
        </div>
        :
        null
    )
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveVersion());

  }, [dispatch]);
// rt 12 07 2022 supretion des tooltip car devenu redondant avec le texte  
// <Tooltip key={index} title={texts[item.nom] ? texts[item.nom] : ""} >
  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <span className='headerTitle'>
          {(() => {
            if (userSession.connected && userSession.users.members.length === 1) {
              return (
                <Link className='linkMenuHomeConected' to="/"><img src={Logo} alt="LogoUni3" /></Link>
              )
            }

            return <Link className={classes.linkMenuHome} to="/"><img src={Logo} alt="LogoUni3" style={{paddingTop: "7px", width: '20%' }} /></Link>;
          })()}
          </span>
          {(userSession.connected && userSession.users.members.length === 1) &&
            <>
              <div className={classes.sectionDesktop}>
                {userSession.users.menuItem.map((item, index) => (
                  
                    <Link to={item.link} className={classes.iconWhite}>
                      <IconButton aria-label={texts[item.nom]} color="inherit">

                        {item.link === "/home" ? <p className='menuIcone'><HomeIcon /><span className='menuIconeText'>Accueil</span></p> : null}
                        {item.link === "/activities" ? <p className='menuIcone'><AppsIcon /><span className='menuIconeText'>Demande d'inscription</span></p> : null}
                        {item.link === "/registrations" ? <p className='menuIcone'><AssignmentIcon /><span className='menuIconeText'>Mes inscriptions</span></p> : null}
                        {item.link === "/cotisations" ? <p className='menuIcone'><DescriptionIcon /><span className='menuIconeText'>Mes cotisations</span></p> : null}
                        {item.link === "/invoices" ? <p className='menuIcone'><LocalAtmIcon /><span className='menuIconeText'>Mes factures</span></p> : null}
                        {item.link === "/users" ? <p className='menuIcone'><AccountBoxIcon /><span className='menuIconeText'>Utilisateurs</span></p> : null}
                        {item.link === "/liste-activities" ? <p className='menuIcone'><ListIcon /><span className='menuIconeText'>Activities</span></p> : null}
                        {item.link === "/adherents" ? <p className='menuIcone'><People /><span className='menuIconeText'>Adherents</span></p> : null}
                        {item.link === "/rubriques" ? <p className='menuIcone'><CategoryIcon /><span className='menuIconeText'>Rubriques</span></p> : null}
                        {item.link === "/intervenants" ? <p className='menuIcone'><EmojiPeopleIcon /><span className='menuIconeText'>Intervenants</span></p> : null}
                        {item.link === "/statistiques" ? <p className='menuIcone'><EqualizerIcon /><span className='menuIconeText'>Statistiques</span></p> : null}
                        {item.link === "/presences" ? <p className='menuIcone'><EventAvailableIcon /><span className='menuIconeText'>Présences</span></p> : null}

                      </IconButton>
                    </Link>
                  
                ))}
                <IconButton aria-label={texts.menu_deco_btn ? texts.menu_deco_btn : null} color="inherit">
                  <p className='menuIcone' onClick={handleDeco} ><ExitToAppIcon /><span className='menuIconeText'>{texts.menu_deco_btn ? texts.menu_deco_btn : 'Déconnection'}</span></p>
                </IconButton>
                <Tooltip onClick={redirectProfil} title={[texts.menu_profil_btn, " ", userSession.users.members[0].firstname, " ", userSession.users.members[0].name]} >
                  <Avatar className='avatarUserOnHeader'>{userSession.users.members[0].firstname.substring(0, 1)}{userSession.users.members[0].name.substring(0, 1)}</Avatar>
                </Tooltip>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>

            </>
          }

        </Toolbar>
        <div style={{ textAlign: "right", fontSize: 11, margin: "0px 1.5% 2px  1.5%", display: 'flex' }}>
          <span style={{ textAlign: "left",  flex: '1 1 50%' }}>{version.numVersion}</span>
          {/*MI 05.01.23, #3734808567*/}
          {userSession.connected && userSession.users.members.length === 1 ?
            <span style={{ textAlign: "center",  flex: '1 1 50%' }}>{[userSession.users.members[0].firstname, " ", userSession.users.members[0].name.toUpperCase()]}</span>
            :null
          }
          <span style={{ textAlign: "right",  flex: '1 1 50%' }}><a className={classes.iconWhite} href="https://www.unige.ch/uni3/" target='_blank'>Aller sur le site Uni3</a></span>

        </div>
      </AppBar>
      <Toolbar />
      <Toolbar />
      <RenderMobileMenu userSession={userSession} />
      {/* {renderMenu} */}
    </div>
  );
}