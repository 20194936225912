import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from "./PaymentCallback.css";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const PaymentCallback = ({ match }) => {
    let { action, msg } = match.params;
    const classes = useStyles();
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(false);
    useEffect(() => {
        switch (action) {
            case "success":
                setError(false)
                break;
            case "failed":
                setError(true)
                break;
            default:
                break;
        }
        if (msg) {
            setMessage(msg)
        }
    }, [action, msg]);

    return (
        message !== null
            ?
            <main className={classes.content}>
                <Grid container spacing={2} direction="column" justify="center" alignItems="center">
                    <Grid item>
                        {error ? <ErrorOutlineIcon style={{ fontSize: 60, color: "#e8545d" }} /> : <CheckCircleOutlineIcon style={{ fontSize: 60, color: "#32a852" }} />}
                    </Grid>
                    <Grid item style={{ textAlign: "center"}}>
                        <span style={{ fontSize: "2em", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: message }}/>
                    </Grid>
                </Grid>
            </main>
            :
        null
    )
}


export default PaymentCallback
















