import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./ResourceAdd.css";
import {
  Dialog,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import http from "../../../http-common";
import { useDropzone } from "react-dropzone";
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";
import { MySnackbar } from "../../mySnackbar/MySnackbar";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function FileUpload({ acceptMatch, setIsDisabled, setFileResource }) {
  const texts = useSelector((state) => state.texts);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ maxFiles: 1, accept: acceptMatch });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setFileResource(acceptedFiles);
  }, [acceptedFiles, setIsDisabled, setFileResource]);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          {texts.dropzoneTextFile} ({acceptMatch})
        </p>
      </div>
      <aside>
        <ul>
          {texts.loaded_file} : {files}
        </ul>
      </aside>
    </section>
  );
}

export default function ResourceAdd({
  setAddResourceOpen,
  activityID,
  setReloadResourceList,
  reloadResourceList,
  open,
}) {
  const classes = useStyles();
  const texts = useSelector((state) => state.texts);
  const [typeResource, setTypeResource] = useState("");
  const [linkResource, setLinkResource] = useState("");
  const [fileResource, setFileResource] = useState([]);
  const [isPrivateResource, setIsPrivateResource] = useState(false);
  const [isImgIllResource, setIsImgIllResource] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loadingInfos, setLoadingInfos] = useState(false);
  const [snack, setSnack] = useState({});

  const acceptMatch = { image: "image/png, image/jpeg", document: ".doc,.docx,.pdf,.xlsx" }; //MI 30.04.24, #5153096401

  useEffect(() => {
    setLinkResource("");
    setTypeResource("");
    setIsPrivateResource(false);
    setIsImgIllResource(false);
  }, [open]);

  const handleClose = () => {
    setAddResourceOpen(false);
    setTypeResource("");
    setLinkResource("");
    setFileResource([]);
    setIsDisabled(true);
  };
  const handleValid = () => {
    var formData = new FormData();
    formData.append("typeVisitID", activityID);
    formData.append("linkResource", linkResource);
    formData.append("typeResource", typeResource);
    formData.append("fileResource", fileResource[0]);
    formData.append("isPrivateResource", isPrivateResource);
    formData.append("isImgIllResource", isImgIllResource);
    setLoadingInfos(true);
    http.post("/portail/activities/saveResource", formData).then(
      (response) => {
        if (response.data.error) {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: "error",
          });
        } else {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: "success",
          });
          setAddResourceOpen(false);
          setReloadResourceList(!reloadResourceList);
        }
        setLoadingInfos(false);
      },
      (error) => {
        setLoadingInfos(false);
        console.log(error);
      }
    );
  };

  const handleTypeChange = (event) => {
    if (event.target.value !== "URL") {
      setLinkResource("");
    }
    setTypeResource(event.target.value);
  };
  const handleLinkChange = (event) => {
    setIsDisabled(event.target.value === "");
    setLinkResource(event.target.value);
  };
  const handlePrivateChange = (event) => {
    setIsPrivateResource(event.target.checked);
  };
  const handleImgIllChange = (event) => {
    setIsImgIllResource(event.target.checked);
  };

  return (
    <>
      <LoadingIndicator open={loadingInfos} />
      <MySnackbar params={snack} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {texts.new_resource_title}
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="typeResourceLabel">
                  {texts.new_resource_type}*
                </InputLabel>
                <Select
                  labelId="typeResourceLabel"
                  id="typeResource"
                  name="typeResource"
                  value={typeResource}
                  onChange={handleTypeChange}
                  fullWidth={true}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="document">
                    {texts.new_resource_document}
                  </MenuItem>
                  <MenuItem value="image">
                    {texts.new_resource_picture}
                  </MenuItem>
                  <MenuItem value="URL">{texts.new_resource_url}</MenuItem>
                  <MenuItem value="text">{texts.new_resource_text}</MenuItem>
                </Select>
                <FormHelperText htmlFor="typeResource" error={false}>
                  {texts.select_requiered}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPrivateResource}
                      onChange={handlePrivateChange}
                    />
                  }
                  label="Privée"
                />
              </FormControl>
            </Grid>
            {/* JC 22.11.21, ajout champ Image */}
            {typeResource === "image" ? (
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isImgIllResource}
                        onChange={handleImgIllChange}
                      />
                    }
                    label="Image d'illustration (sur portail adhérent et site)"
                  />
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12} md={6}>
              {typeResource === "URL" || typeResource === "text" ? (
                <TextField
                  label={typeResource === "URL" ? "Lien" : "Texte"}
                  id="link"
                  name="link"
                  fullWidth
                  required={true}
                  helpertext={texts.required_field}
                  error={false}
                  value={linkResource}
                  onChange={handleLinkChange}
                />
              ) : null}
              {typeResource === "image" || typeResource === "document" ? (
                <FileUpload
                  acceptMatch={acceptMatch[typeResource]}
                  setIsDisabled={setIsDisabled}
                  setFileResource={setFileResource}
                />
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            {texts.label_registration_cancel}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={handleValid}
            color="primary"
            variant="contained"
          >
            {texts.resource_save_btn}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
