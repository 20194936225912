import {
    RETRIEVE_SESSION,
} from "./types";

import SessionDataService from "../services/SessionService";

export const retrieveSession = () => async (dispatch) => {
    try {
        const res = await SessionDataService.sessionUser();
        
        dispatch({
            type: RETRIEVE_SESSION,
            payload: res.data,
        });
        return res
    } catch (err) {
        console.log(err);
    }
};
