import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TableRow, TableCell, Tooltip, Dialog, DialogContent, DialogActions, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import http from "../../http-common";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import ListePresence from '../../components/presences/ListePresence';
import moment from 'moment'
import "moment/locale/fr"; //pour mettre en français le datepicker
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../components/customDialog/CustomDialog'
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import WarningIcon from '@material-ui/icons/Warning';
import ListDates from "../../components/listDates/ListDates";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    filterDates:{
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        marginLeft: 15,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}));


export const Activities = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger=texts.langueCharger;
    const [tableTexts, setTableTexts]=useState("")
    const [activitiesList, setActivitiesList] = useState([])//tableau données datatable
    const [displayActif, setDisplayActif] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState(null)//current row
    const [datesVisite, setDatesVisite] = useState([])//current row
    const [filterDu, setFilterDu] = useState(null)
    const [filterAu, setFilterAu] = useState(null)
    const [typesList, setTypesList] = useState([])
    const [loading, setLoading] = useState(true);
    const [snack, setSnack] = useState({});
    const [myDialog, setMyDialog] = useState({ show: false, title: "", body: "", extraButtons: null, subtitle: null, jsxComponent: null });
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'du', direction: 'desc' })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    

    useEffect(()=>{
        if(langueCharger==="FR"){
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    },[tableTexts,langueCharger]);

    useEffect(() => {
        http.get('/portail/Activities/getFilterPeriode').then((response) => {
            setFilterDu(moment(response.data.start, 'DD.MM.YYYY').format('YYYY-MM-DD'))
            setFilterAu(moment(response.data.end, 'DD.MM.YYYY').format('YYYY-MM-DD'))
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
        //MI 16.07.2021 libellé des natures
        http.get(`/portail/enum/getTypes`).then((response) => {
            setTypesList(response.data.liste)
        }, (error) => {
            console.log(error);
        });
    }, [dispatch]);


    useEffect(() => {
        if ((filterDu !== null) && (filterAu !== null)) {
            setLoading(true)
            http.get('/portail/Activities/getActiviteIntervenant?actif=' + displayActif + "&du=" + moment(filterDu, 'YYYY-MM-DD').format('DD.MM.YYYY') + "&au=" + moment(filterAu, 'YYYY-MM-DD').format('DD.MM.YYYY')).then((response) => {
                setActivitiesList(response.data)
                setLoading(false)
            }, (error) => {
                console.log(error);
                setLoading(false)
            });
        }
    }, [displayActif, filterDu, filterAu, dispatch]);

    const getTypeAttribute = (ref, type) => {
        let retour = ""
        for (let index = 0; index < typesList.length; index++) {
            if (typesList[index].ref === ref)
                retour = typesList[index][type]
        }
        return retour;
    }

    const handleActifFilter = () => {
        setDisplayActif(!displayActif)
    }

    const handleFilterPeriodeDu = (date) => {
        setFilterDu(date)
    }
    const handleFilterPeriodeAu = (date) => {  
        setFilterAu(date)
    }

    const handleLineSelection = (currentRowsSelected) => {
        let row = activitiesList[currentRowsSelected[0].dataIndex]

        let vDatesCours = [];
        if (row.Nature === "GRPE") {
            for (let index = 0; index < row.Visite_Type_Visite.length; index++) {
                const element = row.Visite_Type_Visite[index];
                let tmp = { "ID": element.ID, "groupageTitle": moment(element.Date_Debut, "YYYY-MM-DD").format("DD.MM.YYYY") + "(" + element.Heure_debut + "-" + element.Heure_fin + ")","dates":[]}
                for (let index2 = 0; index2 < element.DatesCours.vcDatesCours.length; index2++) {
                    const element2 = element.DatesCours.vcDatesCours[index2];
                    tmp.dates.push(moment(element2, "YYYY-MM-DD").format("DD.MM.YYYY"))
                }
                vDatesCours.push(tmp)
            }
        } else {
            for (let index = 0; index < row.Visite_Type_Visite.length; index++) {
                const element = row.Visite_Type_Visite[index];
                let tmp = { "ID": element.ID, "groupageTitle": moment(element.Date_Debut, "YYYY-MM-DD").format("DD.MM.YYYY") + "(" + element.Heure_debut + "-" + element.Heure_fin+")", "dates": [] }
                tmp.dates.push(moment(element.Date_Debut, "YYYY-MM-DD").format("DD.MM.YYYY"))
                vDatesCours.push(tmp)
            }
        }

        if (vDatesCours !== null) {
            if (selectedActivity) {
                if (selectedActivity.ID !== row.ID) {
                    setDatesVisite(vDatesCours)
                    setSelectedActivity(row)
                    window.scrollTo(0, 0)
                } else {
                    setSelectedActivity(null)
                }
            } else {
                setDatesVisite(vDatesCours)
                setSelectedActivity(row)
            } 
        } else {
            setSnack({ message: texts.presence_no_date, show: true, severity: "warning" })
        }
    };


    function SubRow(props) {
        let dates = [];
        if (props.rows.length>0) {
            if (props.rows[0].DatesCours!==null) {
                dates = props.rows[0].DatesCours.vcDatesCours
            } else {
                for (let index = 0; index < props.rows.length; index++) {
                    const element = props.rows[index];
                    dates.push(element.Date_Debut)
                }
            }
        }
        const colSpan = props.rows.length + 1;
        return (
            <TableRow>
                <TableCell colSpan={colSpan} className={classes.subRowCell} >
                    <Grid container direction="row" alignItems="flex-end" justify="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <ListDates dates={dates} />
                        </Grid>
                    </Grid>

                </TableCell>
            </TableRow>
        );
    }

    //MI 15.07.2021 renvoie le libellé de la nature
    function FullNature (props) {
       return( 
           <span >{getTypeAttribute(props.code, "name")}</span>
       );
             
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setSelectedActivity(null)
    }

    const PeriodComp = ({value}) => {
        let retour = ""
        let allreadyPresent = [];
        for (let index = 0; index < value.length; index++) {
            const element = value[index];
            if (allreadyPresent.indexOf(element.ID_Période_6_ID.Nom)<0) {
                retour += element.ID_Période_6_ID.Nom+", "
                allreadyPresent.push(element.ID_Période_6_ID.Nom)
            }
        }
        retour = retour.substring(0,retour.length-2)
        return (
            retour
        )
    }

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: "Nom",
            name: "Nom",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    tableMeta.rowData[6]?(tableMeta.rowData[6].length === 0) ? <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}><Tooltip title="Aucune date pour cette activité"><WarningIcon color="primary" /></Tooltip><span>{value}</span></div> : value : ""
                ),
            },
        },
        {
            label: "Type",
            name: "Nature",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true, //affiche la colonne dans le tableau des filtres
                filterOptions: { renderValue: v => <FullNature code={v} /> },
                //MI 15.07.2021 afficher le libellé de la nature
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? <FullNature code={value} /> : ""
                )
            }
        },
        {
            label: "Categorie",
            name: "categorie",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,   
                sortCompare: (order) => { //MI 24.01.2022, #2181852970              
                   return (obj1, obj2) => {
                      console.log(order);
                     
                      let val1 = obj1.rowData[3] !== null ? obj1.rowData[3].Nom : "";
                      let val2 = obj2.rowData[3] !== null ? obj2.rowData[3].Nom : "";
                      return (val1 > val2) * (order === 'asc' ? 1 : -1) || (val1 < val2) * (order === 'desc' ? 1 : -1);
                      
                    };                   

                  },            
                customBodyRender: (value, tableMeta, updateValue) => (                    
                   value ? <span key={value.id} > {value.text}</span> : ""
                   
                )
            }
        },
        {
            label: "Discipline",
            name: "Discipline",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? value.map((item, index) => (
                        <div key={index}>{item.Nom}</div>
                    )) : ""
                )
            }
        },
        {
            label: "Période",
            name: "Visite_Type_Visite",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <PeriodComp value={value}/>
                )
            }
        },
        {
            label: "Date début",
            name: "du",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?moment(new Date(value)).format("DD.MM.YYYY"):""
                )
            }
        },
        {
            label: "Date fin",
            name: "au",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ?moment(new Date(value)).format("DD.MM.YYYY"):""
                )
            }
        },
        {
            label: "Dates",
            name: "Visite_Type_Visite",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: true,
            }
        },
    ];
    
    const options = {
        filter: true,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: handleLineSelection,
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        downloadOptions: {
            filename: 'export_' + texts.title_presences + '.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {
               //catégories
                copyData[index].data[3] = copyData[index].data[3].Nom
                // Discipline
                let tmpDiscipline = ""
                for (let index2 = 0; index2 < copyData[index].data[4].length; index2++) {
                        tmpDiscipline += copyData[index].data[4][index2].Nom+","
                }
                copyData[index].data[4] = tmpDiscipline
               
                //périodes
                let periods = copyData[index].data[5]
                let retour = ""
                let allreadyPresent = [];
                for (let index = 0; index < periods.length; index++) {
                    const element = periods[index];
                    if (allreadyPresent.indexOf(element.ID_Période_6_ID.Nom) < 0) {
                        retour += element.ID_Période_6_ID.Nom + ", "
                        allreadyPresent.push(element.ID_Période_6_ID.Nom)
                    }
                }
                retour = retour.substring(0, retour.length - 2)
                copyData[index].data[5] = retour

                //dates
                copyData[index].data[5] = moment(new Date(copyData[index].data[5])).format("DD.MM.YYYY")
                copyData[index].data[6] = moment(new Date(copyData[index].data[6])).format("DD.MM.YYYY")
                //dates visites
                for (let index2 = 0; index2 < copyData[index].data[8].length; index2++) {
                    if (copyData[index].data[8][index2].Date_Debut) {
                        copyData[index].data[8][index2] = moment(new Date(copyData[index].data[8][index2].Date_Debut)).format("DD.MM.YYYY")
                    } else {
                        copyData[index].data[8][index2] = ""
                    }
                }
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        textLabels: tableTexts,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex, expandedRows) => {
            if (activitiesList[dataIndex].Visite_Type_Visite) {
                if (activitiesList[dataIndex].Visite_Type_Visite.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <SubRow rows={rowData[8]} /> 
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
        customToolbar: () => {
            return (
                <>
                    <MuiDatatableToolBar label={texts.label_actif_filter_tab} actifFilter={displayActif} handleActifFilter={handleActifFilter} /> 
                    <MuiPickersUtilsProvider key={1} utils={MomentUtils} >
                        <DatePicker cancelLabel={texts.datepicker_annuler} autoOk id="filterDu" label="Du" format="DD/MM/YYYY" value={filterDu} onChange={handleFilterPeriodeDu} />
                        <DatePicker cancelLabel={texts.datepicker_annuler} autoOk id="filterAu" label="Au" format="DD/MM/YYYY" value={filterAu} onChange={handleFilterPeriodeAu} />
                    </MuiPickersUtilsProvider>
                </>
                 
            );
        },
        onTableChange: (action,tableState) => {
             //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
    };

    return (
        <React.Fragment>
            <CustomDialog params={myDialog} />
            <MySnackbar params={snack} />
            <LoadingIndicator open={loading} />
            {/* <main className={classes.content}> */}
                <span className={classes.toolbar}> </span>
                <Grid container >
                    <Grid item sm={12} >
                        <MUIDataTable
                            title={texts.title_activities}
                            data={activitiesList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            {/* </main> */}
            <Dialog
                fullScreen
                fullWidth={true}
                open={selectedActivity !== null}
                disableEnforceFocus={true}
                onClose={handleClose}
            >
                <DialogContent style={{ padding: "0px" }}>
                    <ListePresence
                        vSelectedActivity={selectedActivity}
                        datesVisite={datesVisite}
                    />
            </DialogContent>
            <DialogActions>
                <Button className={classes.btnClass} variant="contained" onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>
    )

}

export default Activities;