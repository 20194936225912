import React, { useEffect, useState } from 'react';
import {IconButton, Card, CardActions, CardContent, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, NativeSelect } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../http-common";
import LoadingIndicator from "../loadingIndicator/LoadingIndicator";
import moment from 'moment'
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";


const useStyles = makeStyles((theme) => ({
    cardBackground: {
        background: "#564146",
        color: "#FFF"
    },
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    label: {
        textAlign: "left",
    },
    input: {
        "& .MuiInputBase-root": {
            color: '#808080',
            cursor: 'not-allowed'
        },
        cursor: 'not-allowed'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));

const ListePresence = (props) => {
    const classes = useStyles();
    const [tableTexts, setTableTexts]=useState("")
    const selectedActivity = props.vSelectedActivity;
    const selectedActivityID = selectedActivity?selectedActivity.ID:null
    const [participant, setParticipant] = useState([])
    const [loadingInfos, setLoadingInfos] = useState(true);
    const [snack, setSnack] = useState({});
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'nomPrenom', direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(50)
    // Date selection
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedVisit, setSelectedVisit] = useState("")
    const [listeDates] = useState(props.datesVisite)
    //checkbox
    const [checkboxSelection, setCheckboxSelection] = useState([])
    //dynamique construction muidatatable
    const [columns, setColumns] = useState([])
    const [options, setOptions] = useState({
        filter: false,
        download: false,
        //print: false, //MI 30.04.24, #5919606901
        viewColumns: false,
        search:false,
        selectableRows: "none",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false,
        selectToolbarPlacement: 'none',
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: '60vh',
        fixedHeader: true,
        textLabels: tableTexts,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
  

    })

    //set default selected date (present or futur)
    useEffect(() => {
        let found = false;
        let index = 0;
        while ((index < listeDates.length) && (!found)) {
            let index2 = 0;
            while ((index2 < listeDates[index].dates.length) && (!found)) {
                if (moment(listeDates[index].dates[index2], "DD.MM.YYYY").isSameOrAfter(moment())) {
                    setSelectedDate(listeDates[index].dates[index2])
                    found = true;
                }
                index2++;
            }
            index++;
        }
        console.log("here", listeDates[index - 1].ID)
        if (!found) {
            setSelectedDate(listeDates[index-1].dates.join(","))
        }
        setSelectedVisit(listeDates[index-1].ID)
    }, [listeDates]);

    useEffect(() => {
        if ((selectedActivityID !== null) && (selectedDate !== "")) {
            setLoadingInfos(true)
            var param = {
                activityNum: selectedActivityID,
                forPresence: true,
                selectedDate: selectedDate,
                selectedVisit: selectedVisit,
            }
            http.post('/portail/activities/getParticipant', param).then((response) => {
                let tmpArray = selectedDate.split(",")
                let tmpCheckbox = []
                for (let index = 0; index < tmpArray.length; index++) {
                    tmpCheckbox.push([])
                    for (let indexMembre = 0; indexMembre < response.data.length; indexMembre++) {
                        let element = response.data[indexMembre]
                        // console.log(element[tmpArray[index]])
                        tmpCheckbox[index].push(element[tmpArray[index]]===1?true:false)
                    }
                }
                setCheckboxSelection(tmpCheckbox)
                setParticipant(response.data)
                setLoadingInfos(false)
            }, (error) => {
                console.log(error);
            });
        }
    }, [selectedDate]);

    useEffect(() => {
        let dynamiqueColumns = [
            {
                label: "ID",
                name: "attributionID",
                options: {
                    viewColumns: false,
                    filter: false,
                    display: 'excluded',
                    download: false,
                }
            },
            {
                label: "Nom & Prénom",
                name: "nomPrenom",
                options: {
                    sortThirdClickReset: true,
                    sort: false,
                    filter: false,
                },
            },
        ]
        let tmpArray = selectedDate.split(",")
        for (let index = 0; index < tmpArray.length; index++) {
            dynamiqueColumns.push({
                label: tmpArray[index],
                name: tmpArray[index],
                options: {
                    sortThirdClickReset: false,
                    sort: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Checkbox
                                checked={checkboxSelection[index][tableMeta.rowIndex]}
                                onChange={(e) => { handleChangeCheck(e, index, tableMeta.rowIndex, tableMeta.columnData.name, tableMeta.rowData[0]) }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        )
                    },

                }
            })
        }
        setColumns(dynamiqueColumns);

    }, [checkboxSelection]);


    const handleChangeCheck = (event, indexDate, indexCheck, dateString, attributionID) => {
        setCheckboxSelection((preArray) => {
            let newArray = [];
            for (let index = 0; index < preArray.length; index++) {
                const element = preArray[index];
                newArray.push([])
                for (let index2 = 0; index2 < element.length; index2++) {
                    const element2 = element[index2];
                    if ((indexCheck === index2) && (index === indexDate)) {
                        newArray[index].push(!element2)
                        setLoadingInfos(true)
                        let param = {};
                        param.attributionID = attributionID;
                        param.date = dateString;
                        param.value = !element2;
                        param.allDates = listeDates.join(",");
                        http.post('/portail/activities/savePresence', param).then((response) => {
                            if (response.data.error) {
                                setSnack({ message: response.data.message, show: true, severity: "error" })
                            }
                            setLoadingInfos(false)
                        }, (error) => {
                            console.log(error);
                            setLoadingInfos(false)
                        });


                    } else {
                        newArray[index].push(element2)
                    }
                }
            }
            return newArray
        });
    };

    const handleChangeDate = (event) => {
        setSelectedDate(event.target.value);
        setSelectedVisit(event.target.selectedOptions[0].getAttribute('data-parentid'))
    };

    return ( 
        <div>
            <MySnackbar params={snack} />
            <LoadingIndicator open={loadingInfos} />
                {selectedActivity ?
                    <>
                        <Card>
                            <CardContent className={classes.cardBackground}>
                                <Grid container spacing={1}>
                                    <Grid container className={classes.gridContainerstyle}>
                                        <Grid item xs={12} >
                                            <h2>{selectedActivity.Nom}</h2>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.formControl}>
                                        <InputLabel id="dateSelection">Date présence</InputLabel>
                                        
                                        <NativeSelect
                                            value={selectedDate}
                                            onChange={handleChangeDate}
                                            inputProps={{
                                                name: 'name',
                                                id: 'name-native-disabled',
                                            }}
                                        >
                                            
                                            {listeDates.map((item, index) => (
                                                <optgroup key={index} label={item.groupageTitle}>
                                                    {item.dates.map((item2, index2) => (
                                                        <option key={index2} value={item2} data-parentid={item.ID} >{item2}</option>
                                                    )
                                                    )}

                                                    {item.dates.length > 1 &&
                                                        <option value={item.dates.join(",")} data-parentid={item.ID} >Toutes</option>
                                                    }

                                                </optgroup>
                                            )
                                            )}
                                        </NativeSelect>






                                            {/* <Select
                                                labelId="dateSelection"
                                                id="dateSelectionSelect"
                                                value={selectedDate}
                                                onChange={handleChangeDate}
                                            >
                                                {listeDates.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                )
                                                )}
                                                <MenuItem value={listeDates.join(",")}>Toutes</MenuItem>
                                            </Select> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                    <MUIDataTable
                                            title="Présences"
                                            data={participant}
                                            columns={columns}
                                            options={options}
                                        />
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </>
                    : null
                }
            </div>
    )
}

export default ListePresence;