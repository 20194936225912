import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import http from '../../../http-common'
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from '@material-ui/core'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import CustomAutocomp from '../../customAutocomp/CustomAutocomp'
import ResourceAdd from '../Resources/ResourceAdd'
import ResourceList from '../Resources/ResourceList'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
// CKEDITOR
import { CKEditor } from '@ckeditor/ckeditor5-react'
import FullEditor from 'ckeditor5-build-full'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  separator: {
    background: '#564146',
    height: 1,
  },
}))

const DetailTab = ({
  vSelectedActivityID,
  setSelectedActivity,
  handleRowChange,
  fieldError,
}) => {
  const texts = useSelector((state) => state.texts)
  const classes = useStyles()
  const selectedActivityID = vSelectedActivityID

  const [loadingInfos, setLoadingInfos] = useState(true)
  const [catDisabled, setCatDisabled] = useState(false)
  const [typeDisabled, setTypeDisabled] = useState(false)
  const [showCycleConference, setShowCycleConference] = useState(false)
  const [listeNature, setListeNature] = useState([])
  const [listeCategorie, setListeCategorie] = useState([])
  const [imageSrc, setImageSrc] = useState(""); //RT + TB 07.05.24, #5153096401
  const [detailActivity, setDetailActivity] = useState({
    ID: 0,
    Nom: '',
    Discipline: [],
    categorie: { id: 0, text: '', compte: '' }, //car Categorie est un texte dans 4D
    MotCle: [],
    Nature: '',
    C_confirmation: '',
    Compte_Produit: '',
    Description: '',
    Gratuite: false,
    Localisation: '',
    NePasDiffuser: false,
    Prix: 0,
    Repas: false,
    TexteCoupon: '',
    Destinataire_prerequis: '',
    CycleConference: '',
    Remarque: '',
    Annulee: false,
    Remarque_Interne: '',
    AttributionMultiple: false,
    EntreePayante: false,
  })
  const [addResourceOpen, setAddResourceOpen] = useState(false)
  const [reloadResourceList, setReloadResourceList] = useState(false)
  const [resourcesListResult, setResourcesListResult] = useState([]) //MI 03.05.24, #5153096401
  const[foundImage, setFoundImage] = useState(false) //MI 08.05.24, #5153096401

  
  useEffect(() => {
    http.get('/portail/enum/getTypes').then(
      (response) => {
        setListeNature(response.data.liste)
      },
      (error) => {
        console.log(error)
      },
    )
    http.get('/portail/enum/getCategories').then(
      (response) => {
        setListeCategorie(response.data)
      },
      (error) => {
        console.log(error)
      },
    )

  }, [])

  useEffect(() => {
    setLoadingInfos(true)
    var param = {
      activityNum: selectedActivityID,
    }
    http.post('/portail/activities/getDetail', param).then(
      (response) => {
        // response.data.Remarque=""
        setDetailActivity(response.data)
        setSelectedActivity(response.data)
        setLoadingInfos(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }, [selectedActivityID, setSelectedActivity])

  useEffect(() => {
    if (detailActivity.categorie !== null) {
      let ref = ''
      if ('Nom' in detailActivity.categorie) {
        ref = detailActivity.categorie.Nom
      } else {
        ref = detailActivity.categorie.text
      }
      switch (ref) {
        case 'Visite culturelle':
        case 'Excursion et activité associée':
        case 'Billetterie':
          setCatDisabled(true)
          break
        default:
          setCatDisabled(false)
          break
      }
      if (detailActivity.categorie.id === 47) {
        setShowCycleConference(true)
      }
    }
  }, [detailActivity.categorie])

  useEffect(() => {
    if (selectedActivityID > 0) {
      setTypeDisabled(true)
    } else {
      setTypeDisabled(false)
    }
  }, [selectedActivityID])

 // console.log(resourcesListResult);
 //RT + TB 07.05.24, #5153096401
  useEffect(() => {
    if (resourcesListResult?.length) {
      const found = resourcesListResult.find((element) => element.Type === 'image' && element.Interne === false );
      if (found) {
        setImageSrc('/portail/activities/viewResource?resourceID=' + found.ID);
        setFoundImage(true)
      }
    }
  }, [resourcesListResult, reloadResourceList]);

  const getSelectObject = (ref) => {
    let retour = ''
    for (let index = 0; index < listeCategorie.length; index++) {
      if (listeCategorie[index].text === ref) retour = listeCategorie[index]
    }
    return retour
  }

  const handleFormChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let ref = null
    if (name === 'Nature') {
      let refVal = ''
      switch (value) {
        case 'VGE':
          refVal = 'Visite culturelle'
          break
        case 'VHC':
          refVal = 'Excursion et activité associée'
          break
        case 'Billetterie':
          refVal = 'Billetterie'
          break
        default:
          detailActivity.categorie = { id: 0, text: '', compte: '' }
          // setCatDisabled(false)
          break
      }
      if (refVal !== '') {
        ref = getSelectObject(refVal)
        detailActivity.categorie = ref
        setDetailActivity({ ...detailActivity, categorie: ref })
        // handleRowChange("categorie", ref);

        // setCatDisabled(true)
      }
    }
    if (name === 'Compte_Produit') {
      detailActivity.categorie.Compte_Produit = value
      // console.log( detailActivity.categorie.Compte_Produit)// rt 01 09 2022
    }
    setDetailActivity({ ...detailActivity, [name]: value })
    handleRowChange(name, value, ref)
  }

  const handleAutocompChange = (autocompName) => (event, value) => {
    setDetailActivity({ ...detailActivity, [autocompName]: value })
    handleRowChange(autocompName, value)
    if (value) {
      if (value.id === 47) {
        setShowCycleConference(true)
      } else {
        setShowCycleConference(false)
      }
    }
  }

  const addResource = () => {
    setAddResourceOpen(true)
  }

  const handleOptionDisabled = (option) => {
    let retour = false
    if (detailActivity.Nature === 'GRPE') {
      switch (option.text) {
        case 'Billetterie':
        case 'Excursion et activité associée':
        case 'Visite culturelle':
          retour = true
          break
        default:
          retour = false
          break
      }
    }
    return retour
  }

  const autoCompCategory = {}
  autoCompCategory.url = '/portail/enum/getCategories'
  autoCompCategory.autoCompState = detailActivity.categorie
  autoCompCategory.autoCompLabel = texts.autoCompCategory_label
  autoCompCategory.autoCompName = 'categorie'
  autoCompCategory.multiple = false
  autoCompCategory.error = fieldError[1]
  autoCompCategory.handleAutocompChange = handleAutocompChange
  autoCompCategory.isDisabled = catDisabled
  autoCompCategory.handleOptionDisabled = handleOptionDisabled

  const autoCompDiscipline = {}
  autoCompDiscipline.url = '/portail/enum/getDisciplines'
  autoCompDiscipline.autoCompState = detailActivity.Discipline
  autoCompDiscipline.autoCompLabel = texts.autoCompDiscipline_label
  autoCompDiscipline.autoCompName = 'Discipline'
  autoCompDiscipline.multiple = true
  autoCompDiscipline.error = fieldError[2]
  autoCompDiscipline.handleAutocompChange = handleAutocompChange

  const autoCompKeyWord = {}
  autoCompKeyWord.url = '/portail/enum/getKeyWord'
  autoCompKeyWord.autoCompState = detailActivity.MotCle
  autoCompKeyWord.autoCompLabel = texts.autoCompKeyWord_label
  autoCompKeyWord.autoCompName = 'MotCle'
  autoCompKeyWord.multiple = true
  autoCompKeyWord.handleAutocompChange = handleAutocompChange

  return (
    <>
      <LoadingIndicator open={loadingInfos} />
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_nom}
            id="Nom"
            name="Nom"
            fullWidth
            required={true}
            value={detailActivity.Nom}
            onChange={handleFormChange}
            error={fieldError[0]}
            helperText={texts.required_field}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_description}
            id="Description"
            name="Description"
            fullWidth
            value={detailActivity.Description}
            onChange={handleFormChange}
            multiline
            rows={1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {listeNature.length > 0 ? (
            <FormControl className={classes.formControl}>
              <InputLabel id="natureLabel">
                {texts.form_typeVisit_nature}
              </InputLabel>
              <Select
                labelId="natureLabel"
                id="Nature"
                name="Nature"
                value={detailActivity.Nature}
                onChange={handleFormChange}
                error={fieldError[3]}
                helpertext={texts.required_field}
                disabled={typeDisabled}
              >
                {listeNature.map((item, index) => (
                  <MenuItem key={item.id} value={item.ref}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText htmlFor="Nature" error={fieldError[3]}>
                {texts.required_field}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomp params={autoCompCategory} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomp params={autoCompDiscipline} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomp params={autoCompKeyWord} />
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_cConfirm}
            id="C_confirmation"
            name="C_confirmation"
            fullWidth
            value={detailActivity.C_confirmation}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_prix}
            id="Prix"
            name="Prix"
            fullWidth
            value={detailActivity.Prix}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_lieu}
            id="Localisation"
            name="Localisation"
            fullWidth
            value={detailActivity.Localisation}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_productAccount}
            id="Compte_Produit"
            name="Compte_Produit"
            fullWidth
            value={
              detailActivity.ID === 0 && detailActivity.Compte_Produit === ''
                ? 'Compte_Produit' in detailActivity.categorie
                  ? detailActivity.categorie.Compte_Produit
                  : detailActivity.categorie.compte
                : detailActivity.Compte_Produit
            }
            // value={detailActivity.Compte_Produit}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_couponTxt}
            id="TexteCoupon"
            name="TexteCoupon"
            fullWidth
            value={detailActivity.TexteCoupon}
            onChange={handleFormChange}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={texts.form_typeVisit_destPrerequis}
            id="Destinataire_prerequis"
            name="Destinataire_prerequis"
            fullWidth
            value={detailActivity.Destinataire_prerequis}
            onChange={handleFormChange}
            multiline
            rows={3}
          />
        </Grid>
        {showCycleConference && (
          <Grid item xs={12} md={6}>
            <TextField
              label={texts.form_typeVisit_cycleConference}
              id="CycleConference"
              name="CycleConference"
              fullWidth
              value={detailActivity.CycleConference}
              onChange={handleFormChange}
              multiline
              rows={3}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <label htmlFor="Remarque">Remarque/Programme/Déroulement</label>
          <CKEditor
            editor={FullEditor}
            data={detailActivity.Remarque}
            config={{
              removePlugins: [
                'Image',
                'ImageCaption',
                'ImageStyle',
                'ImageToolbar',
                'ImageUpload',
                'MediaEmbed',
                'Highlight',
              ],
              toolbar: [
                'removeFormat',
                '|',
                'heading',
                '|',
                'fontSize',
                'fontColor',
                '|',
                'bold',
                'italic',
                'underline',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'alignment',
                'indent',
                'outdent',
                '|',
                'link',
                'insertTable',
                'specialCharacters',
                '|',
                'horizontalLine',
                'pageBreak',
              ],
              fontColor: {
                colors: [
                  {
                    color: 'hsl(0, 0%, 0%)',
                    label: 'Black',
                  },
                  {
                    color: 'hsl(0, 0%, 30%)',
                    label: 'Dim grey',
                  },
                  {
                    color: 'hsl(0, 0%, 60%)',
                    label: 'Grey',
                  },
                  {
                    color: 'hsl(0, 0%, 90%)',
                    label: 'Light grey',
                  },
                  {
                    color: 'hsl(0, 0%, 100%)',
                    label: 'White',
                    hasBorder: true,
                  },
                  {
                    color: 'hsl(0, 75%, 60%)',
                    label: 'Red',
                  },
                  {
                    color: 'hsl(30, 75%, 60%)',
                    label: 'Orange',
                  },
                  {
                    color: 'hsl(60, 75%, 60%)',
                    label: 'Yellow',
                  },
                  {
                    color: 'hsl(90, 75%, 60%)',
                    label: 'Light green',
                  },
                  {
                    color: 'hsl(120, 75%, 60%)',
                    label: 'Green',
                  },
                  {
                    color: 'hsl(150, 75%, 60%)',
                    label: 'Aquamarine',
                  },
                  {
                    color: 'hsl(180, 75%, 60%)',
                    label: 'Turquoise',
                  },
                  {
                    color: 'hsl(210, 75%, 60%)',
                    label: 'Light blue',
                  },
                  {
                    color: 'hsl(240, 75%, 60%)',
                    label: 'Blue',
                  },
                  {
                    color: 'hsl(270, 75%, 60%)',
                    label: 'Purple',
                  },
                  {
                    color: 'hsl(332, 95%, 44%)',
                    label: 'UNIGE',
                  },
                ],
              },
            }}
            id="Remarque"
            onChange={(event, editor) => {
              const data = editor.getData()
              // setDetailActivity({ ...detailActivity, "Remarque": data });
              let ref = null
              handleRowChange('Remarque', data, ref)
            }}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={detailActivity.Gratuite}
                onChange={handleFormChange}
                name="Gratuite"
                id="Gratuite"
                color="primary"
              />
            }
            label={texts.form_typeVisit_payerSurPlace}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={detailActivity.NePasDiffuser}
                onChange={handleFormChange}
                name="NePasDiffuser"
                id="NePasDiffuser"
                color="primary"
              />
            }
            label={texts.form_typeVisit_nePasDiffuser}
          />
        </Grid>
        {/*MI 23.11.22, #3408334255*/}
        <Grid item xs={4} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={detailActivity.AttributionMultiple}
                onChange={handleFormChange}
                name="AttributionMultiple"
                id="AttributionMultiple"
                color="primary"
              />
            }
            label={texts.form_typeVisit_attributionMultiple}
          />
        </Grid>
        {/*MI 21.11.23, #5516654591*/}
        {detailActivity.Nature === 'VHC' || detailActivity.Nature === 'VGE' ? (
          <Grid item xs={4} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={detailActivity.EntreePayante}
                  onChange={handleFormChange}
                  name="EntreePayante"
                  id="EntreePayante"
                  color="primary"
                />
              }
              label={texts.form_typeVisit_entreePayante}
            />
          </Grid>
        ) : null}
        {/* MI 24.02.2022, #2334108691*/}
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                label="Remarque interne" //{texts.form_typeVisit_couponTxt}
                id="Remarque_Interne"
                name="Remarque_Interne"
                fullWidth
                value={detailActivity.Remarque_Interne}
                onChange={handleFormChange}
                multiline
                rows={3}
              />
            </Grid>
            {/*MI 03.05.24, #5153096401*/}
            <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              {foundImage?
              <img alt="image illustration" src={imageSrc}/>
              : null}
            </Grid>
          </Grid>
          
        </Grid>

        
        
        {/* <Grid item xs={6} md={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={detailActivity.Annulee}
                                    onChange={handleFormChange}
                                    name="Annulee"
                                    id="Annulee"
                                    color="primary"
                                />
                            }
                        label={texts.form_typeVisit_annulee}
                        />
                </Grid> */}
      </Grid>
      {detailActivity.ID > 0 ? (
        <>
          <br />
          <Grid container alignItems="flex-start" spacing={2}>
            {/* Ressource */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<NoteAddIcon></NoteAddIcon>}
                onClick={addResource}
              >
                {texts.form_typeVisit_addResourceBtn}
              </Button>
            </Grid>
          </Grid>
          <ResourceAdd
            open={addResourceOpen}
            setAddResourceOpen={setAddResourceOpen}
            activityID={detailActivity.ID}
            reloadResourceList={reloadResourceList}
            setReloadResourceList={setReloadResourceList}
          />
          <ResourceList
            reloadResourceList={reloadResourceList}
            activityID={detailActivity.ID}
            resourcesListResult={resourcesListResult}
            setResourcesListResult={setResourcesListResult}
          />
        </>
      ) : null}
    </>
  )
}

export default DetailTab
