import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
       avatarPreference:{ //MI 29.04.24, #5153096401
        height:"30px",
        width:"30px",
    },
}));
