import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    customDialogHeader: {
        backgroundColor: "#564146",
        color: "#FFF",
    },
}));

export const CustomDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.params.show);
    }, [props.params]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
        >
            <DialogTitle className={classes.customDialogHeader} id="customDialog">{props.params.title}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText> <Typography component={'span'} variant="h5">{props.params.subtitle}</Typography></DialogContentText>
                <Typography component={'span'}>
                    {props.params.body}
                    {props.params.jsxComponent}
                </Typography>
            </DialogContent>
            <DialogActions>
                {props.params.extraButtons}
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={handleClose} variant="contained" type="button">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}