import React, { useEffect } from 'react'
import Header from '../../components/menu/Header';
import ProtectedRoute from '../../components/protectedRoute/ProtectedRoute';
import ConnexionForm from '../connexion/ConnexionForm';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import ResetPassword from '../resetPassword/ResetPassword';
import AdheringForm from '../../components/adheringForm/AdheringForm';
import PaymentCallback from '../paymentCallback/PaymentCallback';
import Home from '../home/Home';
import ActivitiesList from '../activity/ActivitiesList';
import Activities from '../activity/ActivitiesCards';
import Adherents from '../adherent/Members';
import Users from '../users/Users';
import Rubriques from '../rubriques/Rubriques';
import Registrations from '../registrations/Registrations';
import Cotisations from '../cotisations/Cotisations';
import Invoices from '../invoice/Invoice';
import Intervenants from '../intervenants/Intervenants';
import Profil from '../profil/Profil';
import Presences from '../presences/Presences';
import NotFound from '../notFound/NotFound';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, connect  } from "react-redux";
import { retrieveTexts } from '../../actions/text'
import { toogleLoader } from '../../actions/loading'
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { Statistiques } from '../statistiques/Statistiques';

const mapStateToProps = ({ loading }) => ({ loading: loading });

const LoadDatas = ({ loading })  =>{
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveTexts(_ => { dispatch(toogleLoader()) }));
    
  }, [dispatch]);


    return (
      <>
        <Router>
            <div>
              <Header />
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login"/>
                </Route>
                <Route exact path="/login" component={ConnexionForm} />
                <Route exact path="/forgotPsw" component={ForgotPassword}/>
                <Route exact path="/resetPsw" component={ResetPassword} />
                <Route exact path="/join" component={AdheringForm} />
              <Route exact path="/paymentCallback/:action?/:msg?" component={PaymentCallback} />
              
                <ProtectedRoute exact path='/home' component={Home} />
                {/* Admin */}
                <ProtectedRoute exact path='/liste-activities' component={ActivitiesList} />
                <ProtectedRoute exact path='/adherents/:memberID?' component={Adherents} />
                <ProtectedRoute exact path='/users' component={Users} />
                <ProtectedRoute exact path='/rubriques' component={Rubriques} />
                <ProtectedRoute exact path='/intervenants' component={Intervenants} />
                <ProtectedRoute exact path='/statistiques' component={Statistiques} />
                {/* Adherent */}
                <ProtectedRoute exact path='/activities' component={Activities} />
                <ProtectedRoute exact path='/activities/:id' component={Activities} />
                <ProtectedRoute exact path='/registrations' component={Registrations} />
                <ProtectedRoute exact path='/cotisations' component={Cotisations} />
                <ProtectedRoute exact path='/invoices/:action?' component={Invoices} />
                <ProtectedRoute exact path='/profil' component={Profil} />
                {/* Intervenant */}
                <ProtectedRoute exact path='/presences' component={Presences} />

                <Route exact path="*" component={NotFound} />
              </Switch>
            </div>
          </Router>

        <LoadingIndicator open={loading} />
        </>
    )
}

  
  // export default LoadDatas

export default connect(mapStateToProps)(LoadDatas);

