import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import http from '../../http-common';
import {
  Paper,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  Box,
  DialogContent, FormGroup, FormLabel, FormControlLabel, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import moment from 'moment';
import clsx from 'clsx';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar';
import { toogleLoader } from '../../actions/loading';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { DialogContentComponent } from './DialogBoxComponet';
import CustomSnackbar from '../../components/general/customSnackBar';
import GetRandomKey from '../../components/general/randomKey';
import verifierConflits, { getAttribution } from './getConfilct';
import SimpleAccordion from './Accordeon';
import ListDates from '../../components/listDates/ListDates';


const useStyles = makeStyles((theme) => ({
  paperClass: {
    padding: theme.spacing(3),
    position: '-webkit-sticky',
    [theme.breakpoints.up('md')]: {
      top: 65,
    },
    [theme.breakpoints.down('md')]: {
      top: '3%',
    },
    bottom: 20,
    paddingTop: '40px',
    paddingBottom: '40px',
    zIndex: 100,
    background: 'rgba(220, 220, 220)',
    border: '2px solid rgba(193, 193, 193)',
  },
  activityCardClass: {
    margin: theme.spacing(2),
    minHeight: '20vh',
    position: 'relative',
  },
  activityCardTop: {
    background: 'rgba(86, 65, 70,0.2)',
    minHeight: '10vh',
  },
  activityCardContent: {
    background: 'rgba(86, 65, 70,0.1)',
    minHeight: '10vh',
    paddingLeft: '9%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto !important',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandLabel: {
    backgroundColor: '#564146',
    minHeight: '3vh',
    transform: 'rotate(0deg)',
    marginLeft: 'auto !important',

    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  pastillecards:{ //MI 08.05.24, #5919606901
 
  borderRadius:'50%', 
  width: '35px', 
  height: '35px', 
  display: 'inline-flex',
  marginRight: '10px',

  },
  btnLeftMargin: {
    //marginLeft: '9%',
    width:  '185px',
    marginLeft: '10px',
    marginRight: '10px' 
  },
  line: {
    backgroundColor: '#564146',
    height: 1,
  },
  modalHeader: {
    backgroundColor: '#564146',
    color: '#FFF',
  },
  noResultClasses: {
    padding: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  flotingButton: {
    position: 'fixed',
    bottom: 0,
    right: '7px',
    zIndex: 100,
    backgroundColor: '#564146',
    color: '#FFF',
    width: 'calc(100vw - 30px)',
    height: '60px',
    padding: '10px',
    boxSizing: 'border-box'
  },
}))

export const Activities = (props) => {
  const matches = useMediaQuery('(max-width:500px)'); //MI 31.01.23,#3868608062
  const [state, setState] = useState({ conditionsgenerales: false });
  const dispatch = useDispatch();
  const classes = useStyles();
  const texts = useSelector((state) => state.texts);
  const userSession = useSelector((state) => state.session);
  const [typesList, setTypesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [catChoosen, setCatChoosen] = React.useState([]);
  const [disciplinesList, setDisciplinesList] = useState([]);
  const [disciChoosen, setDisciChoosen] = React.useState([]);
  const [keyWordChoosen] = React.useState([]);
  const history = useHistory();
  const [activitiesList, setActivitiesList] = useState([]);
  const [attributionList, setAttributionList] = useState([]);
  const [expandedId, setExpandedId] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [actChoosen, setActChoosen] = useState([]);
  const [snack, setSnack] = React.useState({});
  const [infosAdherent, setInfosAdherent] = useState({});
  const [conditionsgeneralesError, setConditionsgeneralesError] = useState(false);


  //################## selecte multi activity rt: 24.01.2024
  const [errorAlert, setErrorAlert] = React.useState({});
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showFlautButton, setShowFlautButton] = useState(false);
  const [flotingContentText, setFlotingContentText] = useState('');
  const [checkboxesMultiSelectAtivity, setCheckboxesMultiSelectAtivity] = useState(
    Object.fromEntries(activitiesList.map((item) => [item.ID, false]))
  );
  const handleCheckboxMultiSelectAtivityChange = (item) => {
    item.Categorie = getCategoryAttribute(item.categorie, 'text');
    item.FullNature = getTypeAttribute(item.Nature, 'name');
    setCheckboxesMultiSelectAtivity((prevCheckboxes) => {
      if (prevCheckboxes[item.ID]) { // attention cet etat vas changer desuite donc prendre l'inverse 
        setActChoosen(actChoosen.filter(obj => obj.ID !== item.ID));
      } else {
        setActChoosen(prevActChoosen => {
          let newArr = [...prevActChoosen];
          newArr = newArr.filter(obj => obj.ID !== item.ID)
          return [...newArr, { ...item }]
        });
      }
      return { ...prevCheckboxes, [item.ID]: !prevCheckboxes[item.ID], };
    });
    getInfosMembre(userSession.users.members[0].id)
  };

  const resetCheckedProperties = (collection) => {
    return collection.map((item) => {
      const sessionsProposees = item.SessionsProposees.map((session) => ({
        ...session,
        notChecked: false,
      }));

      return {
        ...item,
        SessionsProposees: sessionsProposees,
      };
    });
  }

  useEffect(() => {
    const atLeastOneChecked = Object.values(checkboxesMultiSelectAtivity).some((isChecked) => isChecked);
    setShowFlautButton(atLeastOneChecked);

    let texte = '';
    let selectedItems = [];
    Object.keys(checkboxesMultiSelectAtivity).forEach(id => {
      const selectedItem = activitiesList.find((item) => Number(item.ID) === Number(id) & checkboxesMultiSelectAtivity[id]);
      if (selectedItem) selectedItems.push(selectedItem);
    });
    for (let i = 0; i < Math.min(4, selectedItems.length); i++) {
      texte += texte === '' ? selectedItems[i].Nom : `, ${selectedItems[i].Nom}`;
    }

    if (selectedItems.length > 4) texte += ', ...';// Si le tableau a plus de quatre éléments, ajouter "..." à la fin du texte
    setFlotingContentText(texte);
  }, [checkboxesMultiSelectAtivity]);

  const handleClickOpenMulti = () => {
    setOpen(true);
  }

  const resetMultiSelect = () => {
    setActChoosen([])
    setCheckboxesMultiSelectAtivity([]);
    setFlotingContentText('');
    setActivitiesList((listAct) => {
      let newList = [...listAct];
      return resetCheckedProperties(newList);
    })
  }

  const hundelCloseMulti = () => {
    resetMultiSelect();
    setOpenAlert(false);
  }
  //################## end selecte multi activity 



  function isAccompagnateur(arr, titre) {
    return arr.some(function (el) {
      return el.Titre === titre
    })
  }

  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }

  const handleChangeCat = (event, value) => {
    setCatChoosen(value)
  }

  const handleChangeDisci = (event, value) => {
    setDisciChoosen(value)
  }

  const handleSearch = (id) => {
    setLoading(true)
    let params = {}
    params.searchId = id
    params.searchText = searchText
    params.searchCat = JSON.stringify(catChoosen)
    params.searchDiscipline = JSON.stringify(disciChoosen)
    params.searchKeyWord = JSON.stringify(keyWordChoosen)

    http.post('/portail/activities/search', params).then(async (response) => {
      const attribution = await getAttribution({}, attributionList);
      setAttributionList(attribution);
      const activitysAvecConflit = verifierConflits(response.data.activites, attribution);
      // console.log('response.data.activites handleSearch =================>>>>>>>>>>>>>>>>>>>>> ', response.data.activites);
      setActivitiesList(resetCheckedProperties(activitysAvecConflit));
      setDisciplinesList(response.data.discipline)
      setLoading(false)
      if (id !== undefined) {
        history.push('/activities')
      }
    },
      (error) => {
        console.log(error)
        setLoading(false)
      },
    )
  }

  const getActivites = () => {
    if (props.match.params.id !== undefined) {
      handleSearch(props.match.params.id);
    } else {
      handleSearch();
    }
  }

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i)
  }

  const getTypeAttribute = (ref, type) => {
    let retour = ''
    for (let index = 0; index < typesList.length; index++) {
      if (typesList[index].ref === ref) retour = typesList[index][type]
    }
    return retour
  }

  //     //MI 17.09.2021 fonction pour récupérer la catégorie de l'activité
  const getCategoryAttribute = (ref, type) => {
    //console.log(ref);
    let retour = ''
    for (let index = 0; index < categoriesList.length; index++) {
      if (ref !== null) {
        if (categoriesList[index].text === ref.text)
          retour = categoriesList[index][type]
        // console.log(categoriesList);
      }
    }

    return retour
  }

  //MI 27.09.2021 récupéré l'image de l'activité dans les ressources
  const getImage = (idImage) => {
    //console.log("id de l'image:"+idImage);

    if (idImage !== 0) {
      return '/portail/activities/viewResource?resourceID=' + idImage
    }
  }

  //JC 30.11.21, remplacé Visite_Type_Visite par SessionsProposees
  const handleClickOpen = (item) => {
    if (item.preInscrEnabled) {
      //alors il y a une facture de cotisation payée pour au moins une date
      //console.log("nbSessions=" + item.SessionsProposees.length);
      //si au moins 1 session
      item.Categorie = getCategoryAttribute(item.categorie, 'text');
      item.FullNature = getTypeAttribute(item.Nature, 'name');
      item.SessionsProposees.forEach(
        (currentItem) =>
          (currentItem.checked = item.SessionsProposees.length === 1),
      )
      resetMultiSelect();
      // setActChoosenDate([item.SessionsProposees])
      setActChoosen(() => [{ ...item }]);// clone item
      setOpen(true)
      setState({ ...state, conditionsgenerales: '' })
      //if (item.Nature === "VGE" || item.Nature === "VHC"){
      //MI 14.07.2021 récupérer certaines infos du membre
      getInfosMembre(userSession.users.members[0].id);
      //}
    } else {
      setSnack({
        message: texts.forcePayeCotisation_msg,
        show: true,
        severity: 'error',
      })
    }
  }

  const getInfosMembre = (idAdherent) => {
    var param = {
      vMember: idAdherent,
    }
    http.post(`/portail/members/getIdentity`, param).then(
      (response) => {
        setInfosAdherent(response.data);
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const handleDateSelectionCheck = (idAct, indexDate) => {
    setActChoosen(prevActChoosenDate => {
      const newArr = [...prevActChoosenDate];
      const act = newArr.find(activite => activite.ID === idAct);
      if (act) {
        act.SessionsProposees[indexDate].notChecked = act.SessionsProposees[indexDate].notChecked ? !act.SessionsProposees[indexDate].notChecked : true;
      }
      return newArr;
    });
  };

  //MI 14.07.2021
  const handleFormChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setState({ ...state, [name]: value })
  }

  const handleClose = () => {
    setState({ conditionsgenerales: false, conditionsgeneralesError: false })
    setOpen(false);
    resetMultiSelect();
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const regepMessage = (messageKey) => {
    const regex = /^(.*?)\s\((.*?)\)$/;
    const match = messageKey.match(regex);
    if (match) {
      return ` (${match[2]}) ${texts[match[1].trim()]}`;
    } else {
      return texts[messageKey.trim()]
    }
  }

  const handleSubscriptionValidation = () => {
    console.log("accompagnateur -  actChoosen === : ", state.accompagnateur, actChoosen)
    //07.07.2021
    var error
    let params = {}
    let checkedDates = actChoosen.map((act) => {
      return act.SessionsProposees.filter((session) => !session.notChecked)
    });
    //params.activity = JSON.stringify(actChoosen);
    let activityData = JSON.stringify(actChoosen); //MI 08.07.2024, #6980069910
    activityData = activityData.replace(/&/g, 'feff0026')
    activityData = activityData.replace(/=/g, 'feff003d')
    activityData = activityData.replace(/%/g, 'feff0025')
    params.activity = activityData
    params.idMemb = userSession.users.members[0].id
    params.conditionsgenerales = state.conditionsgenerales
    if (checkedDates.some(subArray => Array.isArray(subArray) && subArray.length > 0)) {

      if (!state.conditionsgenerales) {
        setConditionsgeneralesError(true)
        error = true
      } else {
        setConditionsgeneralesError(false)
      }

      if (error) {
        setSnack({
          message: texts.error_empty_required_adhering,
          show: true,
          severity: 'error',
        })
        return false
      }
      http.post('/portail/members/subscribe', params).then(
        (response) => {
          //  setCurrentActivity(response.data)

          if (response.data.error || response.data.multiActivity) {
            const messagesArray = response.data.message.split(',');
            const messages = (messagesArray.length > 1) ? messagesArray.map(messageKey => regepMessage(messageKey)).filter(Boolean).join('\n') : false;
            const messagesArraySuccess = response.data.success ? response.data.messageSuccess.split(',') : '';
            const messageSuccess = response.data.success ? messagesArraySuccess.map(messageKey => regepMessage(messageKey)).filter(Boolean) : '';
            setErrorAlert({
              messageSuccess: messageSuccess?.join('\n'),
              messageError: messages,
              show: true,
              severity: 'success',
            });
            setOpenAlert(true);
          } else {

            setSnack({
              message: texts.success_subscribe,
              show: true,
              severity: 'success',
            })
            handleSearch();
          }
          // vide la liste des selection 
          resetMultiSelect();
          getActivites();// actualiser les activités
        },
        (error) => {
          console.log(error)
        },
      )

      //console.log(checkedDates)
      setState({ conditionsgenerales: false, conditionsgeneralesError: false })
      setOpen(false)
    } else {
      setSnack({
        message: 'Sélectionner au moins une session',
        show: true,
        severity: 'error',
      })
    }
  }

  useEffect(() => {
    getActivites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catChoosen, disciChoosen, keyWordChoosen])

  useEffect(() => { //rt  20.02.2024
    getAttribution({});
  }, [])

  useEffect(() => {
    dispatch(toogleLoader())

    const getDisciplinePromise = http.get(`/portail/enum/getDisciplines`);
    const getCategoriesPromise = http.get(`/portail/enum/getCategories`);
    const getTypesPromise = http.get(`/portail/enum/getTypes`);
    Promise.all([
      getDisciplinePromise,
      getCategoriesPromise,
      getTypesPromise,
    ]).then(
      function (values) {
        setDisciplinesList(values[0].data)
        setCategoriesList(values[1].data)
        setTypesList(values[2].data.liste);
        dispatch(toogleLoader())
      },
      (error) => {
        console.log(error)
        dispatch(toogleLoader())
      },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div>
      <MySnackbar params={snack} />
      <div
        /* style={{ background: "rgba(220, 220, 220,0.5)" }} */ className={
          classes.paperClass
        }
        style={{ position: matches ? 'static' : 'sticky' }} //MI 31.01.23,#3868608062
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2"> {texts.info_demande_inscription1} </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}> {texts.info_demande_inscription2} </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={10} md={2}>
            <TextField
              id="vSearch"
              name="vSearch"
              label={texts.search}
              type="text"
              fullWidth
              value={searchText}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Fab
              aria-label="Rechercher"
              color="primary"
              onClick={() => handleSearch()}
            >
              <SearchIcon />
            </Fab>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl className={classes.formControl} width="70%" fullWidth>
              <Autocomplete
                multiple
                id="vSelectCategories"
                options={categoriesList}
                getOptionLabel={(option) => option.text}
                defaultValue={[]}
                onChange={handleChangeCat}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={texts.categories}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={GetRandomKey(8)}
                      color="primary"
                      label={option.text}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl className={classes.formControl} width="70%" fullWidth>
              <Autocomplete
                multiple
                id="vSelectDisciplines"
                options={disciplinesList}
                getOptionLabel={(option) => option.text}
                defaultValue={[]}
                onChange={handleChangeDisci}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={texts.disciplines}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={GetRandomKey(9)}
                      color="primary"
                      label={option.text}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <span style={{ fontSize: 18, fontWeight: 'bold' }}>
                "SE PRÉ-INSCRIRE" signifie que vous faites une demande
                d’inscription mais que Uni3 doit encore valider par la suite, en
                tenant compte des places disponibles.
              </span>
            </Typography>
          </Grid>
        </Grid>
      </div>
      {/* LISTE ACTIVITES */}
      <Grid
        container
        direction="row"
        display="flex"
        alignItems="flex-start"
      >
        {showFlautButton && // bare du bas flotante /  multiactivité 
          <Box className={classes.flotingButton} direction="row" justifyContent="space-between" alignItems="stretch">
            <Grid container spacing={2} >
              <Grid item xs={9}>
                <div className={classes.flotingContent}>{flotingContentText}</div>
              </Grid>
              <Grid item xs={3} container justifyContent="space-between" alignItems="center">
                <Button color="primary" variant="contained" onClick={handleClickOpenMulti}>{'Pre inscription'}{actChoosen.length > 1 ? ' multiple' : ''}</Button>
                <IconButton onClick={hundelCloseMulti} aria-label="Fermer">
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        }
        {activitiesList.length > 0 ? (
          activitiesList.map((item, index) => (
            <Grid key={GetRandomKey(10)} item xs={12} sm={12} md={6}>
              <Card className={classes.activityCardClass}>
                <CardHeader
                  className={classes.activityCardTop}
                  // avatar={
                  //   <Avatar
                  //     aria-label="recipe"
                  //     className={classes.avatar}
                  //     style={{
                  //       background: getCategoryAttribute(
                  //         item.categorie,
                  //         'color',
                  //       ), //MI 14.04.2022, #2126986518//getTypeAttribute(item.Nature, "color"),
                  //     }}
                  //   >
                  //     &nbsp;
                  //   </Avatar>
                  // }
                  titleTypographyProps={{ variant: 'h5' }}
                  title={[  
                  
                 <span className={classes.pastillecards} style={{ background: getCategoryAttribute(item.categorie, 'color'), }}> &nbsp;</span>,
                               
                    getCategoryAttribute(item.categorie, 'text') + ' | ' + item.Nom,
                    <hr key={item.ID + 'hr'} className={classes.line} />,
                    

                  ]}
                  subheaderTypographyProps={{ variant: 'h6' }}
                  //MI 17.09.2021 pas indiquer la nature mais la catégorie dans l'activité (cards)
                  subheader={[
                    '',
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <SimpleAccordion
                            key={item.ID + 'chip'}
                            color="primary"

                            style={{ width: '100%' }}
                            title={[
                             //MI 08.5.24, #5919606901
                               <Typography variant="subtitle1" component="p"><b> 
                              {(item.SessionsProposees.length > 1 ||
                              item.AutreDatesCours.length > 0
                              ? 'Dès le '
                              : ''  )} {moment(item.FirstDateVisite).format('DD.MM.YYYY')}
                                </b>  
                                </Typography>,
                              
                              // item.SessionsProposees.length > 1 ||
                              //   item.AutreDatesCours.length > 0
                              //   ? 'Dès le '
                              //   : '',
                              // moment(item.FirstDateVisite).format('DD.MM.YYYY'),
                            ]}
                            text={<>
                            
                                {item.Visite_Type_Visite.map((visite, indexVisite) => (
                                <Typography
                                  key={GetRandomKey(11)}
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {item.Nature === 'GRPE' &&
                                    item.AutreDatesCours.length > 0 ? (
                                    <>
                                      {'Dès le ' + moment(new Date(visite.Date_Debut)).format('DD.MM.YYYY') +
                                        ' / ' +
                                        visite.Heure_debut +
                                        (visite.Heure_fin !== ''
                                          ? ['-' + visite.Heure_fin]
                                          : '') +
                                        ' / ' +
                                        (visite.Salle !== ''
                                          ? [' ' + visite.Salle + ' / ']
                                          : '') +
                                        (visite.Places > 0
                                          ? [
                                            texts.label_participant_registration +
                                            ': ' +
                                            visite.Places +
                                            ' pers.',
                                          ]
                                          : '') +
                                        (visite.DateAnnulation === '0000-00-00'
                                          ? ''
                                          : ' Session Annulée')}
                                      <br></br>
                                      {/* MI 12.01.2022, #2127079115 */}
                                      {/* {texts.label_all_date_registration}
                                      <ListDates
                                        dates={visite.DatesCours.vcDatesCours}
                                      /> */}
                                      {/*MI 01.05.24, #5919606901*/}
                                  {item.SessionsProposees.map((vDate) => 
                                  <Box>
                                    {texts.label_all_date_registration}
                                    <ListDates dates={vDate.DatesCours?.vcDatesCours} 
                                    special={true} small={true} dateConflict={null} />
                                  </Box>)}
                                    </>
                                  ) : (
                                    moment(new Date(visite.Date_Debut)).format('DD.MM.YYYY') +
                                    ' / ' +
                                    visite.Heure_debut +
                                    (visite.Heure_fin !== ''
                                      ? ['-' + visite.Heure_fin]
                                      : '') +
                                    ' / ' +
                                    (visite.Places > 0
                                      ? [
                                        texts.label_participant_registration +
                                        ': ' +
                                        visite.Places +
                                        ' pers.',
                                      ]
                                      : '') +
                                    (visite.DateAnnulation === '0000-00-00'
                                      ? ''
                                      : ' Session Annulée')
                                  )}

                                  {/* JC 22.11.21 1929631653 */
                                  /* {moment(new Date(visite.Date_Debut)).format('DD.MM.YYYY')} / {visite.Heure_debut}{visite.Heure_fin !== "" ? ["-",visite.Heure_fin] : ""} / {visite.Places > 0 ? [texts.label_participant_registration+": ",visite.Places +" pers."] : ""} */}
                                </Typography>
                              ))}
                              
                            </>}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="subtitle1" component="p">
                        <b>{texts.label_intervenant_registration} :</b> {item.intervenant}
                      </Typography>
                      <Typography variant="subtitle1" component="p">
                        <b> {texts.label_price_registration} :</b> {item.Prix === 0
                          ? texts.label_free_registration
                          : (item.Gratuite ? texts.form_typeVisit_payerSurPlace :'CHF ' + item.Prix.toFixed(2))} {/*MI 08.05.24, #5919606901 Gratuite correspond à "A payer sur place"*/}
                      </Typography>
                      <Typography variant="subtitle1" component="p"><b>{texts.label_localisation_registration} :</b>
                        {item.Nature === 'GRPE' ? (item.Localisation) : null} </Typography>
                        </Grid>
                      </Grid>
                        ,
                    ,
                  ]}
                />
                <CardActions className={classes.activityCardTop} style={{ paddingTop: 0, marginTop: '-0px', }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={7}>                      
                      <SimpleAccordion
                        key={item.ID + 'description'}
                        color="primary"
                        style={{ width: '100%' }}
                        title={[
                          //MI 08.05.24, #5919606901
                           <Typography variant="subtitle1" component="p"><b>
                          {texts.label_description_registration}
                          </b>
                          </Typography>,
                        ]}
                        text={<div style={{ width: '100%' }}>{item.Image.idRessource > 0 ? (
                          <CardMedia
                            className={classes.activityCardClass}
                            component="img"
                            image={getImage(item.Image.idRessource)}
                            alt={item.Image.contenuRessource}
                            style={{
                              margin: 'auto',
                              paddingTop: '5px',
                              width: '100%'
                            }}
                          ></CardMedia>
                        ) : null}
                          {item.Description} <Typography variant="subtitle1" component="p">
                            <b>{texts.label_remarque_registration} :</b> {item.TexteCoupon.replace(/(<br \/>|<br>|\\t)/g, ' \n')}
                          </Typography>
                        </div>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={5}>
                      {item.SessionsProposees.length > 0 ? (
                      <div style={{  width: '100%' }}>
                        <Checkbox
                          style={{ width: '9px', margin: '0 10px', padding: 0 }}
                          checked={checkboxesMultiSelectAtivity[item.ID] || false}
                          onChange={() => handleCheckboxMultiSelectAtivityChange(item)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<DoubleArrowIcon />}
                          onClick={() => {
                            handleClickOpen(item)
                          }}
                          
                          //className={classes.btnLeftMargin} //MI 01.05.24, #5919606901
                          style={{ width:  '185px' }} 
                        >
                          {texts.label_registration_registration}
                        </Button>
                      </div>
                    ) : null}
                    {/*item.AdhDejaInscrit === 1 ? " (déjà pré-inscrit)" : ""*/}
                    {item.AdhDejaInscrit === 0 ? (
                      ''
                    ) : (
                      <Button variant="contained" 
                       style={{ width:  '200px' }}//MI 01.05.24, #5919606901
                      >déjà (pré-)inscrit</Button> 
                    )}
                    </Grid>
                    
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.noResultClasses}>
              {texts.noResult}
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle className={classes.modalHeader}>
          {' '}
          {texts.label_registration_registration} {actChoosen.length} activité(s)
        </DialogTitle>

        <DialogContent>
          {actChoosen.map((act) =>
            <DialogContentComponent
              key={GetRandomKey(12)}
              act={act}
              setActChoosen={setActChoosen}
              classes={classes}
              texts={texts}
              handleDateSelectionCheck={handleDateSelectionCheck}
              infosAdherent={infosAdherent}
              isAccompagnateur={isAccompagnateur}
              userSession={userSession}
            />
          )}

          {/* {actChoosen.Nature === "GRPE" ? ( */}
          <FormGroup className='conditionGeneralActivityCard'>
            <FormControl
              component="fieldset"
              required={true}
              error={conditionsgeneralesError}
            >
              <FormLabel component="legend">
                Acceptation des conditions générales
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.conditionsgenerales || false}
                    onChange={handleFormChange}
                    name="conditionsgenerales"
                    color="default"
                    required={true}
                  />
                }
                label={
                  <>
                    {' '}
                    Je confirme avoir lu et accepté les{' '}
                    <a
                      href="https://www.unige.ch/uni3/conditions-generales" //{ConditionsGenerales} //MI 15.11.22
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#d80669' }}
                    >
                      Conditions générales.
                    </a>
                  </>
                } //"que pour cours,seminaires et ateliers"
                labelPlacement="end"
              />
            </FormControl>
          </FormGroup>
          {/* ) : null} */}
        </DialogContent>
        <DialogActions className='actionActivityCard'>
          <Button variant="contained" onClick={handleClose} color="default">
            {texts.label_registration_cancel}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubscriptionValidation}
            color="primary"
          >
            {texts.label_registration_submit}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingIndicator open={loading} />
      <CustomSnackbar
        open={openAlert}
        messageError={errorAlert.messageError}
        messageSuccess={errorAlert.messageSuccess}
        severity={errorAlert.severity}
        onClose={handleCloseAlert}
      />
    </div>
  )
}

export default Activities

