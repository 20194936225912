import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import http from "../../http-common";
import { Grid } from '@material-ui/core';
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import { TableRow, TableCell } from '@material-ui/core';
import { useStyles } from "./Users.css";
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";

export const Users = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [tableTexts, setTableTexts] = useState("")
    const [displayActif, setDisplayActif] = useState(true);
    const [usersList, setUsersList] = useState([])
    const [loading, setLoading] = useState(false);

    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'Login', direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(20)

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        setLoading(true)
        http.get('/portail/user/liste?actif=' + displayActif).then((response) => {
            setUsersList(response.data)
            setLoading(false)
        }, (error) => {
            console.log(error);
            setLoading(false)
        });
    }, [displayActif, dispatch]);

    const handleActifFilter = () => {
        setDisplayActif(!displayActif)
    }

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download:false,
            }
        },
        {
            label: texts.user_column_login,
            name: "Login",
            options: {
                sortThirdClickReset: true,
            }
        },
        {
            label: texts.user_column_roles,
            name: "Web_User_Role_Web_User",
            options: {
                sortThirdClickReset: true,
                sort:false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value.map((item, index) => (
                        <span key={index}>{item.Web_User_Role_Web_Role.Titre}{(value.length - 1 > index) ? "," : ""} </span>
                    ))
                )
            }
        },
        {
            label: texts.user_column_membres,
            name: "Membre_Web_User",
            options: {
                sortThirdClickReset: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value.map((item, index) => (
                      item.Statut === 0 ? 
                        <span key={index}>{item.Nom.toUpperCase()} {item.Prenom} (<Link title={texts.link_view_member} to={'/adherents/'+item.ID} >{item.ID_Affiche}</Link>){(value.length - 1 > index) ? "," : ""} </span>
                     :null
                        ))
                )
            }
        },
        {
            label: texts.user_column_lastConnexion,
            name: "LastConnexion",
            options: {
                sortThirdClickReset: true,
                sort: true,
            }
        },
    ];

    const handleLineSelection = (rowData, rowMeta) => {
        console.log(rowData)
    };

    function SubRow(props) {
        const colSpan = props.rows.length + 1;
        return (
                <TableRow>
                    <TableCell colSpan={colSpan} className={classes.subRowCell} >
                    
                    <Grid container direction="row" alignItems="flex-end" justify="center" spacing={2}>
                            {props.rows.map((item, index) => (
                                <Grid key={item.ID} item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                {item.Nom.toUpperCase()} {item.Prenom}
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                {item.ID_Affiche}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                ))}
                        </Grid>

                    </TableCell>
                </TableRow>
        );
    }

    const options = {
        filter: false,
        selectableRows: "none",
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        downloadOptions: {
            filename: 'export_' + texts.title_users+'.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {//format column object to string
                for (let index2 = 0; index2 < copyData[index].data[2].length; index2++) {
                    copyData[index].data[2][index2] = copyData[index].data[2][index2].Web_User_Role_Web_Role.Titre
                }
                for (let index2 = 0; index2 < copyData[index].data[3].length; index2++) {
                    copyData[index].data[3][index2] = copyData[index].data[3][index2].Nom.toUpperCase() + " " + copyData[index].data[3][index2].Prenom
                }
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        onRowClick: handleLineSelection,
        textLabels: tableTexts,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex, expandedRows) => {
            if (usersList[dataIndex].Membre_Web_User.length > 1) {
                return true;
            } else {
                return false;
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <SubRow rows={rowData[3]}/>
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
        customToolbar: () => {
            return (
                <MuiDatatableToolBar label={texts.label_actif_filter_tab} actifFilter={displayActif} handleActifFilter={handleActifFilter} />
            );
        },
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
    };

    const components = {
        ExpandButton: function (props) {
           if(usersList.length>0){
               if (props.dataIndex) {
                   if (usersList[props.dataIndex].Membre_Web_User.length > 1) {
                       return <ExpandButton {...props} />;
                   } else {
                       return <div style={{ width: '24px' }} />;
                   }
               } else {
                   return <div style={{ width: '24px' }} />;
               }
           } else {
               return null;
           }
        }
    };

    return (
        <React.Fragment>
            <LoadingIndicator open={loading} />
                <main className={classes.content}>
                    <span className={classes.toolbar}> </span>
                    <Grid container >
                        <Grid item sm={12}>
                            <MUIDataTable
                                title={texts.title_users}
                                data={usersList}
                                columns={columns}
                                options={options}
                                components={components}
                            />
                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
    )
}

export default Users;