import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../../http-common";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment'

const useStyles = makeStyles(() => ({
    container: {
        fontSize: '13px'
    },
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingBottom: '3%',
    },
    label: {
        textAlign: "right",
        marginRight: '3%',
        color: "blue"
    },
}));

const InvoiceTab = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID = props.vSelectedMemberID;
    const [invoiceAdherent, setInvoiceAdherent] = useState({ invoices:[]})

    useEffect(() => {
        var param = {
            vMember: selectedMemberID,
        }
        http.post('/portail/members/getInvoice', param).then((response) => {
            setInvoiceAdherent(response.data)
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID])

    return (
        <div>
            <h3>{texts.member_tab_invoices}</h3>
            <Grid container className={classes.containerAlignement}>
                <Grid item xs={12}><br />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{texts.tab_invoices_label_numFact}</TableCell>
                                    <TableCell align="center">{texts.tab_invoices_label_periode}</TableCell>
                                    <TableCell>{texts.tab_invoices_label_libelle}</TableCell>
                                    <TableCell align="center">{texts.tab_invoices_label_date}</TableCell>
                                    <TableCell align="center">{texts.tab_invoices_label_total}</TableCell>
                                    <TableCell align="center">{texts.tab_invoices_label_solde}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invoiceAdherent.invoices.map((row) => (
                                        <TableRow key={row.ID_Facture}>
                                            <TableCell>{row.No_Facture}</TableCell>
                                            <TableCell align="center">{row.Periode}</TableCell>
                                            <TableCell>{row.Libelle}</TableCell>
                                            <TableCell align="center">{moment(new Date(row.Date_Facture)).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell align="right">{row.Total}</TableCell>
                                            <TableCell align="right">{row.Solde}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

export default InvoiceTab;
