import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '30%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(14), //MI 01.05.24, #5919606901
        //fontWeight: theme.typography.fontWeightRegular,
        fontWeight: theme.typography.fontWeightBold, 
        
    },
}));

export default function SimpleAccordion({ text, title, style }) {
    const classes = useStyles();

    return (
        <div className={classes.root} style={style}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{background: 'rgba(86, 65, 70,0.2)'}}
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={{background: 'rgba(86, 65, 70,0.1)'}}>
                    <Typography>
                        {text}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
