
import { RETRIEVE_SESSION } from "../actions/types";

const initialState = {};

const sessionReducer = (session = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_SESSION:
      return payload;

    default:
      return session;
  }
};

export default sessionReducer;