import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch  } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import http from "../../http-common";
import { Grid, Typography } from '@material-ui/core';
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import InfosMember from '../../components/adherent/InfosMember';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
}));


export const Members = ({match}) => {
    let { memberID } = match.params;
    if (!memberID) {
        memberID=0
    }
    const dispatch = useDispatch();
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const userSession = useSelector(state => state.session);
    const [loading, setLoading] = useState(false);
    const langueCharger=texts.langueCharger;
    const [tableTexts, setTableTexts]=useState("")

    const [membersList, setMembersList] = useState([])
    const [selectedMember, setSelectedMember] = useState(null)
    const [displayActif, setDisplayActif] = useState(true);
    const [displayOnlyMine, setDisplayOnlyMine] = useState(false);
    
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'Nom', direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(()=>{
        if(langueCharger==="FR"){
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    },[tableTexts,langueCharger]);

    useEffect(() => {
        setLoading(true)
        http.get(`/portail/members/getAll?actif=` + displayActif + "&onlyMine="+ displayOnlyMine +"&memberID=" + memberID).then((response) => {
                setMembersList(response.data.Members)
                setLoading(false)
            }, (error) => {
                console.log(error);
                setLoading(false)
            });
    }, [displayActif, displayOnlyMine, memberID, dispatch]);


    const handleActifFilter = () => {
        setDisplayActif(!displayActif)
    }

    const handleOnlyMineFilter = () => {
        setDisplayOnlyMine(!displayOnlyMine)
    }
    
    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download:false,
            }
        },
        {
            label: texts.adherent_column_numMem,
            name: "ID_Affiche",
            options: {
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Typography>
                      {value.toLocaleString("de-CH")}
                    </Typography>
                  )
            }
        },
        {
            label: texts.adherent_column_name,
            name: "Nom",
            options: {
                sortThirdClickReset: true,
            }
        },
        {
            label: texts.adherent_column_firstname,
            name: "Prenom",
            options: {
                sortThirdClickReset: true,
            }
        },
        {
            label: texts.adherent_column_numSpouse,
            name: "ID_1_ID_Membre",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Typography>
                        {/* {value.map(spouse => spouse.Spouse_To_Membre.ID_Affiche).toLocaleString("de-CH")} */}
                       { value.map((item, index) => (
                           item.Spouse_To_Membre ? item.Spouse_To_Membre.ID_Affiche : ""
                        ))}
                    </Typography>
                  )
            }
        },
        {
            label: texts.adherent_column_address,
            name: "Adresse1",
            options: {
                sortThirdClickReset: true,
            }
        },
        {
            label: texts.adherent_column_zip,
            name: "No_Postal",
            options: {
                sortThirdClickReset: true,
            }
        },
        {
            label: texts.adherent_column_city,
            name: "Localite",
            options: {
                sortThirdClickReset: true,
            }
        }, 
        {
            label: "Inactive",
            name: "Inactif",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
    ];

    const handleLineSelection = (currentRowsSelected) => {
        //si intervenant ET pas admin pas de click
        let row = membersList[currentRowsSelected[0].dataIndex]
        if(userSession.users.isAdmin){
            if (selectedMember) {
                if (selectedMember.ID !== row.ID) {
                    setSelectedMember(row)
                } else {
                    setSelectedMember(null)
                }
            } else {
                setSelectedMember(row)
            }
        } 
    };

    const options = {
        filter: false,
        sortOrder: sortOrder,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: handleLineSelection,
        responsive: 'simple',
        // onRowClick: handleLineSelection,
        textLabels: tableTexts,
        tableBodyHeight: 'auto',
        fixedHeader: true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        customToolbar: () => {
            return (
                <>
                    { (userSession.users.isAdmin) ?
                        <MuiDatatableToolBar label={texts.label_actif_filter_tab} actifFilter={displayActif} handleActifFilter={handleActifFilter} />
                        : ""
                    }
                    { ((userSession.users.isAdmin) & (userSession.users.isIntervenant)) ?
                        <MuiDatatableToolBar label={texts.label_only_mine_tab} onlyMineFilter={displayOnlyMine} handleActifFilter={handleOnlyMineFilter} />
                        : ""
                    }
                </>
            );
        },
        downloadOptions: {
            filename: 'export_' + texts.title_members + '.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {//format column object to string
                for (let index2 = 0; index2 < copyData[index].data[4].length; index2++) {
                    copyData[index].data[4][index2] = copyData[index].data[4][index2].Spouse_To_Membre.ID_Affiche
                }
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        onTableInit: (action, tableState) => {
            // if (memberID > 0) {
            //     let row = [{ index: 0, dataIndex: 0 }];
            //     handleLineSelection(row);
            // }
        },
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
    };

    return (
        <main className={classes.content}>
            <LoadingIndicator open={loading} />
                <span className={classes.toolbar}> </span>
                <Grid container >
                    <Grid item sm={12} md={selectedMember!==null?6:12}>
                        <MUIDataTable
                            title={texts.title_members}
                            data={membersList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>

                    {selectedMember !== null ?
                        <Grid item sm={12} md={6} style={{ paddingLeft: "1%" }}>
                            <InfosMember
                                vSelectedMember={selectedMember}
                            />
                        </Grid>
                    :
                    null
                    }
                </Grid>
            </main> 
    )
}

export default Members;