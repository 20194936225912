import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../../http-common";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";
import moment from 'moment'

const useStyles = makeStyles(() => ({
    container: {
        fontSize:'13px'
    }, 
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        paddingBottom: '3%', 
    }, 
    label:{
        textAlign:"right", 
        marginRight:'3%',
        color:"blue"
    },
}));

const  ContributionTab = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID = props.vSelectedMemberID;
    const [contributionAdherent, setContributionAdherent] = useState({})
    const [loadingInfos, setLoadingInfos] = useState(true);

    useEffect(() => {
        setLoadingInfos(true)
        var param = {
            vMember: selectedMemberID,
        }
        http.post(`/portail/members/getContribution`, param).then((response) => {
            
            setContributionAdherent(response.data)
            setLoadingInfos(false)
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID])
  
    return (
        loadingInfos ? <LoadingIndicator open={loadingInfos} />
            :
        <div>
            <h3>{texts.label_contributions}</h3>
            <Grid container className={classes.containerAlignement}>   
                <Grid item xs={12}><br/>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{texts.tab_contribution_label_motif}</TableCell>
                                    <TableCell align="center">{texts.tab_contribution_label_date}</TableCell>
                                    <TableCell align="center">{texts.tab_contribution_label_amount}</TableCell>
                                    <TableCell align="center">{texts.tab_contribution_label_SendCM}</TableCell>
                                    <TableCell align="center">{texts.tab_contribution_label_modality}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contributionAdherent.Cotisation &&
                                    contributionAdherent.Cotisation.map((row) => (
                                        <TableRow key={row.ID}>
                                             <TableCell>{row.Motif}</TableCell>
                                             <TableCell align="center">{moment(new Date(row.Date_paiement)).format('DD.MM.YYYY')}</TableCell>
                                             <TableCell align="center">{row.Montant}</TableCell>
                                             <TableCell align="center">{moment(new Date(row.Envoi_Cm)).format('DD.MM.YYYY')}</TableCell>
                                             <TableCell align="center">{row.Modalite_EnvoiCM}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}
  
export default ContributionTab;
