import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    alignTextLeft:{
        textAlign:"left",
    },
    alignTextCenter:{
        textAlign:"center",
    },
    alignTextRight:{
        textAlign:"right",
    },
  });
  