import http from "../http-common";

const getAll = () => {
  return http.get("/portail/texts");
};


const TextsService = {
  getAll
};

export default TextsService;
