import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import http from "../../../http-common";
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
    container: {
        fontSize:'13px'
    }, 
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        paddingBottom: '3%', 
    }, 
    label:{
        textAlign:"right", 
        marginRight:'3%',
        color:"blue"
    }, 
    input: {
        "& .MuiInputBase-root": {
            color: '#808080',
            cursor: 'not-allowed'
        },
        cursor: 'not-allowed'
    },
    propsInput: {
        cursor: 'not-allowed'
    }
}));

const  ResignationTab = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID = props.vSelectedMemberID;
    const [resignationInfo, setResignationInfo] = useState({
        NumAd: "",
        Gender: "",
        Name: "",
        Firstname: "",
        ResignationDate: "",
        RegistrationDate: "",
        Nationality: "",
        ResignationMotif: "",
        ResignationNote: "",
    })
    const [loadingInfos, setLoadingInfos] = useState(true);

    useEffect(() => {
        setLoadingInfos(true)
        var param = {
            vMember: selectedMemberID,
        }
        http.post(`/portail/members/getResignationInfos`, param).then((response) => {
            setResignationInfo(response.data)
            setLoadingInfos(false)
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID])
  
    return (
        loadingInfos ? <LoadingIndicator open={loadingInfos} />
            :
        <div>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_title} id="vTitle" fullWidth disabled inputProps={{className: classes.propsInput}}  value={resignationInfo.Gender} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_name} id="vName" fullWidth disabled inputProps={{className: classes.propsInput}}  value={resignationInfo.Name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_firstname} id="vFirstname" fullWidth disabled inputProps={{className: classes.propsInput}}  value={resignationInfo.Firstname} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_dateResignation} id="vResigntaionDate" fullWidth disabled inputProps={{ className: classes.propsInput }} value={resignationInfo.ResignationDate}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_dateRegistration} id="vRegistrationDate" fullWidth disabled inputProps={{ className: classes.propsInput }} value={resignationInfo.RegistrationDate} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_nationality} id="vNationality" fullWidth disabled inputProps={{className: classes.propsInput}}  value={resignationInfo.Nationality} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_resignationMotif} id="vResignationMotif" fullWidth disabled inputProps={{className: classes.propsInput}}  value={resignationInfo.ResignationMotif} />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={texts.label_note} id="vJob" variant="outlined" multiline rows={6} fullWidth disabled inputProps={{ className: classes.propsInput }} value={resignationInfo.ResignationNote} />
                </Grid>
            </Grid> 
        </div>
    )
}
  
export default ResignationTab;
