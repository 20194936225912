import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useStyles } from "./Invoice.css";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import MUIDataTable from "mui-datatables";
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import http from "../../http-common";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { Grid, Tooltip, IconButton, Button, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment'
import PaymentIcon from '@material-ui/icons/Payment';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcone from '@material-ui/icons/Print';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../components/customDialog/CustomDialog'


export const Invoice = ({ match }) => {
    let { action } = match.params;
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [tableTexts, setTableTexts] = useState("")
    const [snack, setSnack] = useState({});
    const [myDialog, setMyDialog] = useState({ show: false, title: "", body: "", extraButtons: null, subtitle: null, jsxComponent: null });
    const userSession = useSelector(state => state.session.users);
    const [invoiceList, setInvoiceList] = useState([])//tableau données datatable
    const [loading, setLoading] = useState(true);
    const [waitingPaye, setWainingPaye] = useState(true);

    useEffect(() => {
        switch (action) {
            case "success":
                setSnack({ message: "Paiement effectué avec succès.", show: true, severity: "success" })
                break;
            case "failed":
                setSnack({ message: "Paiement refusé/annulé.", show: true, severity: "error" })
                break;
            default:
                break;
        }
    }, [action]);

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        setLoading(true)
        var param = {
            vMember: userSession.members[0].id,
            waitingPaye: waitingPaye,
            invoicePayeUI:true,
        }
        http.post(`/portail/members/getInvoice`, param).then((response) => {
            setInvoiceList(response.data.invoices)
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
    }, [userSession.members, waitingPaye])

    const handleWaitingPayeFilter = () => {
        setWainingPaye(!waitingPaye)
    }

    function openFile(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

    function base64toBlob(base64Data) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }

    function SubRow(props) {
        const colSpan = props.invoice.length + 1;
        return (
            <TableRow>
                <TableCell colSpan={colSpan} className={classes.subRowCell} >

                    <Grid container direction="row" alignItems="flex-end" justify="center" spacing={2}>
                        {props.rows.map((item, index) => (
                            <Grid key={item.ID} container direction="row" alignItems="flex-start" justify="center" spacing={0} style={{padding:"10px"}}>
                                <Grid item xs={10}>
                                    - {item.Titre} 
                                </Grid>
                                <Grid item xs={2} style={{textAlign:"right"}}>
                                        {item.Tarif.toFixed(2)} CHF
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                </TableCell>
            </TableRow>
        );
    }

    const payeInvoice = (ID) => {

        let pTitle = texts.invoice_ask_paye;
        let pBody = texts.Invoice_redirect_msg;
        function payeInvoiceConfirm() {
            setLoading(true)
            var param = {
                factureID: ID,
            }
            http.post(`/portail/members/payeInvoice`, param).then((response) => {
                if (response.data.error) {
                    setSnack({ message: response.data.message, show: true, severity: "error" })
                } else {
                    setMyDialog({ show: false })
                    window.location.href = response.data.response;
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });
        }
        let pExtraButtons = <Button onClick={payeInvoiceConfirm} color="primary" variant="contained" type="button">{texts.invoice_payeBtn_label}</Button>
        setMyDialog({ show: true, title: pTitle, body: pBody, extraButtons: pExtraButtons, subtitle: null, jsxComponent: null })    

    };
    const printInvoce = (ID) => {

        setLoading(true)

        try {
            let oldPrintPage = document.getElementById("iframetoDeleteAfterUsed");// if exite allready a print page 
            if (oldPrintPage) oldPrintPage.remove();

            let iframe = document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = '/portail/members/getInvoicePDF?vMember=' + userSession.members[0].id + '&factureID=' + ID//blobURL;
            iframe.id = 'iframetoDeleteAfterUsed';
            iframe.onload = function () {
                setTimeout(function () {
                    setLoading(false)
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        } catch (error) {
            setLoading(false)
            setSnack({ message: 'Error on pdf export', show: true, severity: "error" })
        }

    };

    const viewReceipt = (ID) => {
        setLoading(true)
        var param = {
            factureID: ID,
        }
        http.post('/portail/members/viewReceipt', param).then((response) => {
            if (response.data.error) {
                setSnack({ message: response.data.message, show: true, severity: "error" })
            } else {
                var data = response.data.response;
                var blob = base64toBlob(data.data);
                openFile(blob, data.title)
            }
            setLoading(false)
        }, (error) => {
            setLoading(false)
            console.log(error);
        });
    };
    

    const columns = [
        {
            label: "ID",
            name: "ID_Facture",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: texts.tab_invoices_label_numFact,
            name: "No_Facture",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false, //affiche la colonne dans le tableau des filtres
            }
        },
        {
            label: texts.tab_invoices_label_periode,
            name: "Periode",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true, //affiche la colonne dans le tableau des filtres
            }
        },
        {
            label: texts.tab_invoices_label_libelle,
            name: "Type",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true, //affiche la colonne dans le tableau des filtres
            }
        },
        {
            label: texts.tab_invoices_label_total,
            name: "Total",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false, //affiche la colonne dans le tableau des filtres
                setCellProps: () => ({ style: { minWidth: "100px" } }),
                customBodyRender: (value, tableMeta, updateValue) => (
                    value.toFixed(2) + " CHF"
                ),
            }
        },
        {
            label: texts.tab_invoices_label_solde,
            name: "Solde",
            options: {
                display: waitingPaye,
                sortThirdClickReset: true,
                sort: true,
                filter: false, //affiche la colonne dans le tableau des filtres
                setCellProps: () => ({ style: { minWidth: "100px" } }),
                customBodyRender: (value, tableMeta, updateValue) => (
                    value.toFixed(2) + " CHF"
                ),
            }
        },
        {
            label: texts.tab_invoices_label_date,
            name: "Date_Facture",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    moment(new Date(value)).format('DD.MM.YYYY')
                ),
            }
        },
        {
            label: texts.tab_invoices_label_dateEcheance,
            name: "Date_echeance",
            options: {
                display: 'excluded',
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value!=="0000-00-00"?moment(new Date(value)).format('DD.MM.YYYY'):"-"
                ),
            }
        },
        {
            label: texts.tab_invoices_label_payeState,
            name: "payeStateTxt",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true,
            }
        },
        {
            label: "Date paiement",
            name: "payeDate",
            options: {
                display: !waitingPaye,
                viewColumns: false,
                filter: false,
                download: false,
                print: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ?
                        <div>{value}<Tooltip title={texts.invoice_view_receipt}>
                            <IconButton onClick={() => { viewReceipt(tableMeta.rowData[0]) }} color="primary" component="span">
                                    <DescriptionIcon />
                                </IconButton>
                        </Tooltip></div>
                        :
                        "-"
                ),
            }
        },
        {
            label: texts.tab_invoices_label_action,
            name: "ID_Facture",
            options: {
                display: waitingPaye,
                viewColumns: false,
                filter: false,
                download: false,
                print: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    console.log('payement ===>>>>>>', tableMeta.rowData);
                    // rt 06.09.2022
                    // return (
                    //     tableMeta.rowData[12] ?
                    //         <Tooltip title={texts.invoice_proceedConfirm_label} >
                    //             <IconButton onClick={() => { payeInvoice(value) }} color="primary" component="span">
                    //                 <PaymentIcon />
                    //             </IconButton>
                    //         </Tooltip>
                    //     :
                    //         <Tooltip title={texts.invoice_waitingConfirm_label} >
                    //             <IconButton color="primary" component="span">
                    //                 <HourglassEmptyIcon />
                    //             </IconButton>
                    //         </Tooltip>
                    //  )
                    return (
                            <Tooltip title={texts.invoice_proceedConfirm_label} >
                                <IconButton onClick={() => { printInvoce(value) }} component="span">
                                    <PrintIcone />
                                </IconButton>
                            </Tooltip>
                     )
                },
            }
        },
        {
            label: "Lignes",
            name: "lignefacture_facture",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: "actionEnabled",
            name: "actionEnabled",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
    ];

    const options = {
        filter: false,
        selectableRows: "none",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        responsive: 'simple',
        sortOrder: { name: 'Date_Facture', direction: 'desc' },
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        download:false,
        viewColumns: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        textLabels: tableTexts,
        customToolbar: () => {
            return (
                <>
                    <MuiDatatableToolBar label={texts.label_waiting_paye} actifFilter={waitingPaye} handleActifFilter={handleWaitingPayeFilter} />
                </>
            );
        },
        isRowExpandable: (dataIndex, expandedRows) => {
            if (invoiceList[dataIndex].lignefacture_facture) {
                if (invoiceList[dataIndex].lignefacture_facture.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <SubRow invoice={rowData} rows={rowData[11]} />
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
    };

    return (
        <>
            <LoadingIndicator open={loading} />
            <CustomDialog params={myDialog} />
            <MySnackbar params={snack} />
            <main className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <MUIDataTable
                            title={texts.title_invoice}
                            data={invoiceList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </main>
        </>
    )

}

export default Invoice;