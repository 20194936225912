const titleFromRecherche = (recherche, periodList, categoriesList, disciplinesList, texts) => {
    let title = '', key = '', val;
    const columnHeader = Object.keys(recherche);
    const correspondanceValueText = [
        { value: "ListeFiches", text: texts.label_ListeFiches },
        { value: "NombreFichesCategoriePeriode", text: texts.label_nbFileByCat },
        { value: "NombreFichesDiciplinePeriode", text: texts.label_nbFileByDisc },
        { value: "NombreFichesTranchesAgePeriode", text: texts.label_nbFileByTranche },
        { value: "NombreFichesLocalitePeriode", text: texts.label_nbFileByLocation },
        { value: "NombreFichesGenrePeriode", text: texts.label_nbFileByGenre },
        { value: "NombreFichesPersonnesInscritesActivite", text: texts.label_nbMeberINActivity },
        { value: "NombreFichesInscriptionsParTemps", text: texts.label_nbInscriptioParTemps },
        { value: "NombreFichesListeDattente", text: texts.label_nbListeDattente },
        { value: "NombreFichesAdherentsPapierOuPortail", text: texts.label_nbAdherantPapierPortails },
        { value: "NombreFichesAdherantsInscrits", text: texts.label_AdherantInscrit },
    ];

    function trouverTextePourValeur(valeur) {
        return correspondanceValueText.find(objet => objet.value === valeur)?.text;
    }

    let dateTitre = '';
    let demissionTitre = '';

    for (let i = 0; i < columnHeader.length; i++) {
        if (recherche.hasOwnProperty(columnHeader[i]) && recherche[columnHeader[i]]) {

            key = columnHeader[i];
            val = recherche[columnHeader[i]];

            switch (columnHeader[i]) {
                case 'typeData':
                    key = 'Liste';
                    break;

                case 'periode':
                    if (recherche.periode.length > 0) {
                        val = "";
                        key = texts.visitForm_autoCompPeriodLabel;
                        for (let j = 0; j < recherche.periode.length; j++) {
                            const element = recherche.periode[j];
                            let preriodIndex = periodList.findIndex((item) => Number(item.ID) === Number(element));
                            val += periodList[preriodIndex]?.Nom + ' - ' || '';
                        }
                    }
                    break;

                case 'categorie':
                    val = "";
                    key = texts.categories;
                    for (let j = 0; j < recherche.categorie.length; j++) {
                        const element = recherche.categorie[j];
                        let categorieIndex = categoriesList.findIndex((item) => Number(item.id) === Number(element));
                        val += categoriesList[categorieIndex]?.text + ' - ' || '';
                    }
                    break;

                case 'discipline':
                    val = "";
                    key = texts.disciplines;
                    for (let j = 0; j < recherche.discipline.length; j++) {
                        const element = recherche.discipline[j];
                        let disciplineIndex = disciplinesList.findIndex((item) => Number(item.id) === Number(element));
                        val += disciplinesList[disciplineIndex]?.text + ' - ' || '';
                    }
                    break;

                case 'sortie':
                    key = 'Export';
                    val = trouverTextePourValeur(val);
                    break;

                case 'genreM':
                case 'genreF':
                    key = texts.title_genre;
                    val = columnHeader[i] === 'genreM' ? texts.label_mal_long : texts.label_femal_long;
                    break;

                case 'actif':
                    val = texts.label_true;
                    break;

                case 'DateDemission':
                case 'DateDemissionFin':
                case 'DateNaissanceDebut':
                case 'DateNaissanceFin':
                    if (['DateNaissanceDebut', 'DateNaissanceFin'].includes(columnHeader[i])) 
                        dateTitre = dateTitre === '' ? texts.label_birth + ' ' : ' ';
                    else
                        demissionTitre = demissionTitre === '' ? texts.label_dateResignation + ' ' : ' ';   
                    key = ['DateNaissanceDebut', 'DateNaissanceFin'].includes(columnHeader[i]) ? dateTitre : demissionTitre;
                    key += ['DateNaissanceDebut', 'DateDemission'].includes(columnHeader[i]) ? texts.label_befor_date : texts.label_after_date;
                    break;

                default:

            }
            title += key + ': ' + val + ' - ';
        }
    }
    if (!recherche.periode || recherche.periode?.length === 0) {
        title += texts.visitForm_autoCompPeriodLabel + ` de l'année académique courante`;
    }
    return title;
}

export default titleFromRecherche;