import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from "react-redux";
import { Fab, Tooltip, Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import { useStyles } from "./ListRubrique.css";
import MUIDataTable from "mui-datatables";
import { MySnackbar } from '../../../components/mySnackbar/MySnackbar'
import LoadingIndicator from "../../../components/loadingIndicator/LoadingIndicator";
import { CustomDialog } from '../../../components/customDialog/CustomDialog'
import http from "../../../http-common";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import MuiDatatableToolBar from "../../../components/muiDatatableToolBar/MuiDatatableToolBar";
// CKEDITOR
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full'
import { FormHelperText } from '@material-ui/core';
// File upload
import { useDropzone } from "react-dropzone";

//traduction datatable
const labelsTrad = {
    body: {
        noMatch: "Aucun enregistrement trouvé",
        toolTip: "Sort",
        columnHeaderTooltip: column => `Trier sur ${column.label}`
    },
    pagination: {
        next: "Page suivant",
        previous: "Page précédente",
        rowsPerPage: "Elément par page:",
        displayRows: "sur",
    },
    toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger CSV",
        print: "Imprimer",
        viewColumns: "Voir Colonnes",
        filterTable: "Filtrer Table",
    },
    filter: {
        all: "Tous",
        title: "Filtres",
        reset: "Réinitialiser",
    },
    viewColumns: {
        title: "Montrer Colonnes",
        titleAria: "Montrer/Cacher Colonnes",
    },
    selectedRows: {
        text: "Enregistrement(s) sélectionné(s)",
        delete: "Supprimer",
        deleteAria: "Supprimer l'enregistrement sélectionné",
    },
}

//Fileupload
const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  
  const activeStyle = {
    borderColor: "#2196f3",
  };
  
  const acceptStyle = {
    borderColor: "#00e676",
  };
  
  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const thumb = {
    display: 'flex',
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    height: 'auto',
    padding: 4,
  };
  
  const imgThumb = {
    display: 'block',
    width: '80%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  };
  

function FileUpload({ acceptMatch, setIsDisabled, setFileResource, setShowExistedImage, setIsImgAdded}) {
    const texts = useSelector((state) => state.texts);
    const [filesImg, setFilesImg] = useState([]);
    const {
      acceptedFiles,
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({ 
        maxFiles: 1, 
        accept: acceptMatch,
        onDrop: acceptedFiles => {
            setFilesImg(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setShowExistedImage(false)
            setIsImgAdded(true)
        }
    });
    
    const thumbs = filesImg.map(file => (
        <div style={thumb} key={file.name}>
            <img src={file.preview} style={imgThumb} alt='' />
        </div>
    ));

  
    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept]
    );
  
    const files = acceptedFiles.map((file) => (
      <li key={file.path}>{file.path}</li>

    ));
  
    useEffect(() => {
      if (acceptedFiles.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      setFileResource(acceptedFiles);
    }, [acceptedFiles, setIsDisabled, setFileResource]);
  
    return (
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>
            {texts.dropzoneTextFile} ({acceptMatch})
          </p>
        </div>
        <aside>
            <br/>
            <Grid container>
                <Grid item xs={6}>
                    {thumbs}
                </Grid>
                <Grid item xs={6}>
                    {texts.loaded_file} :
                    <ul>
                        {files}
                    </ul>
                </Grid>
            </Grid>
        </aside>
      </section>
    );
}

export default function ListRubrique(props) {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const userSession = useSelector(state => state.session);
    const [disciplineList, setDisciplineList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [snack, setSnack] = useState({});
    const [myDialog, setMyDialog] = useState({ show: false, title: "", body: "", extraButtons: null, subtitle: null, jsxComponent: null });
    const [currentRubrique, setCurrentRubrique] = useState(null)
    const [labelError, setLabelError] = useState(false)
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'text', direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const acceptMatch = { image: "image/png, image/jpeg" };
    const [typeResource] = useState("image"); // setTypeResource
    const [linkResource] = useState("");// setLinkResource
    const [fileResource, setFileResource] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isImgAdded, setIsImgAdded] = useState(false);
    const [showExistedImage, setShowExistedImage] = useState(false)

    useEffect(() => {
        if ((userSession.users.isAdmin) && (props.url!==null)) {
            http.get(props.url).then((response) => {
                setDisciplineList(response.data);
            }, (error) => {
                console.log(error);
            });
        }
    }, [userSession.users.isAdmin, reload, props.url]);

    const handleAction = (action,row) => {
        setLabelError(false)

        switch (action) {
            case "add":
                setCurrentRubrique({ id: 0, text: "", Descriptif: "" })
                
                break;
            case "update":
                setCurrentRubrique(row)
                if(props.type === "rubrique"){
                    if(row.Image !== ""){
                        setShowExistedImage(true)
                    }
                }
                
                break;
            case "delete":
                let pTitle = texts.delete_confirm_btn //+" ( "+ row.text+" )";
                let pBody = texts.delete_confirm_btn_text ;               

                function deleteConfirm() {
                    setLoading(true)
                    let params = { id: row.id, type: props.type };
                    if(row.precision!==undefined){
                        http.post('/portail/enum/deleteRubriqueImage', params).then((response) => {
                            if (response.data.error) {
                                setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                            } else {
                                setMyDialog({ show: false })
                                setIsImgAdded(false)
                                setShowExistedImage(false)
                                setLabelError(false)
                            }
                            setLoading(false)
                        }, (error) => {
                            setLoading(false)
                            console.log(error);
                        });
                    } else {
                        http.post('/portail/enum/deleteRubrique', params).then((response) => {
                            if (response.data.error) {
                                setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                            } else {
                                setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                                setMyDialog({ show: false })
                                setReload(!reload)
                            }
                            setLoading(false)
                            
                        }, (error) => {
                            setLoading(false)
                        
                            console.log(error);
                        }); 
                    }

                }

                let pExtraButtons = <Button onClick={deleteConfirm} style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }} variant="contained" type="button">{texts.delete_btn}</Button>
                setMyDialog({ show: true, title: pTitle, body: pBody, extraButtons: pExtraButtons, subtitle: null, jsxComponent: null })
                
                break;
            default:
                break;
        }
    }

    function CustomAddButtons(props) {
        return (
            (props.type !== "categorie" && props.type !== "rubrique")?<Tooltip title={texts.new_btn}>
                <Fab onClick={_ => handleAction("add", { id: 0, text: "" })} size="small" color="primary" aria-label="Nouvelle rubrique" className={classes.actionBtn}>
                    <AddIcon />
                </Fab>
            </Tooltip>:null
        );
    }

    // config users table
    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: texts.table_rubrique_labelField,
            name: "text",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                )
            },
        },
        {
            label: texts.table_rubrique_compteField,
            name: "Compte_Produit",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                display: props.type !== "rubrique" && props.type !== "discipline" && props.type !== "keyWord"?  true : 'excluded', //MI 20.01.2022, #2170188704
            },
        },
        {
            label: texts.table_rubrique_codeField,
            name: "Code",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                display:'excluded',
                download: false,
            },
        },
        {
            label: "Descriptif",
            name: "Descriptif",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                display: props.type === "rubrique" ? true : 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                )
            },
        },
        {
            label: "Image",
            name: "Contenu",
            options: {
                sortThirdClickReset: false,
                sort: true,
                filter: false,
                display: props.type === "rubrique" ? true : 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        {tableMeta.rowData[6] !== "" &&
                            <>
                                <img src={'data:image/jpeg;base64, '+ tableMeta.rowData[6]} style={{maxWidth: '130px'}} alt='' />
                                <br/>
                            </>
                        }
                        {value}
                    </>
                    
                        
                )
            },
        },
        {
            label: "Image64",
            name: "ContenuBlob64",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: texts.table_rubrique_actionField,
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        {(props.type !== "categorie" && props.type !== "rubrique")?<Tooltip title={texts.delete_btn}>
                            <Fab onClick={_ => handleAction("delete", { id: tableMeta.rowData[0], text: tableMeta.rowData[1]})} size="small" color="secondary" className={classes.actionBtn}>
                                <DeleteIcon />
                            </Fab>
                        </Tooltip>:null}
                        <Tooltip title={texts.update_btn}>
                            {props.type === "rubrique" ?
                                <Fab onClick={_ => handleAction("update", { id: tableMeta.rowData[0], text: tableMeta.rowData[1], Code: tableMeta.rowData[3], Compte_Produit: tableMeta.rowData[2], Descriptif: tableMeta.rowData[4], Image: tableMeta.rowData[5], Image64: tableMeta.rowData[6],})} size="small" color="primary"className={classes.actionBtn}>
                                    <EditIcon />
                                </Fab>
                            :
                                <Fab onClick={_ => handleAction("update", { id: tableMeta.rowData[0], text: tableMeta.rowData[1], Code: tableMeta.rowData[3], Compte_Produit: tableMeta.rowData[2], Descriptif: tableMeta.rowData[4]})} size="small" color="primary"className={classes.actionBtn}>
                                    <EditIcon />
                                </Fab>
                            }
                        </Tooltip>
                    </>
                ),
            }
        },
    ];
    
    const options = {
        selectableRows: "none",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        // onCellClick: (colData, cellMeta) => handleLineSelection(cellMeta),
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        textLabels: labelsTrad,
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
        downloadOptions: {
            filename: 'export_discipline.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        customToolbar: () => {
            return (
                <MuiDatatableToolBar extraBtn={<CustomAddButtons type={props.type}/>} hideFilter={true} />
            );
        },
    };

    const handleCloseForm = () => {
        setCurrentRubrique(null)
    }
    const handleSaveForm = () => {
        if(props.type ==="discipline" || props.type ==="keyWord" ) { //MI 20.01.2022, #2170188704
            currentRubrique.Code = currentRubrique.text;
            currentRubrique.Compte_Produit = "";
        }
        if (currentRubrique.text==="") {
            setLabelError(true)
        } else {
            setLoading(true)
            console.log(props.type)
          
            var formData = new FormData();
            formData.append("id", currentRubrique.id)
            formData.append("type", props.type);
            formData.append("text",encodeURIComponent(currentRubrique.text))
            formData.append("Code", encodeURIComponent(currentRubrique.Code)) //MI 20.01.2022, #2170188704
            formData.append("Compte_Produit",encodeURIComponent(currentRubrique.Compte_Produit))//MI 20.01.2022, #2170188704
            formData.append("Descriptif", encodeURIComponent(currentRubrique.Descriptif))

            formData.append("isImgAdded", isImgAdded);
            formData.append("linkResource", linkResource);
            formData.append("typeResource", typeResource);
            formData.append("fileResource", fileResource[0]);

            /* let params = { type: props.type };
            if (props.type==="rubrique") {
                //params.text = encodeURIComponent(encodeURIComponent(currentRubrique.text))
                formData.append("Descriptif",currentRubrique.Descriptif)
            } else {
                formData.append("Descriptif", currentRubrique.Descriptif)
               // params.text = currentRubrique.text
            } 
            params.id = currentRubrique.id
            params.text = currentRubrique.text
            params.Code = currentRubrique.Code 
            params.Compte_Produit = currentRubrique.Compte_Produit */
            
            console.log("code:" + formData.get('Code'))
            console.log("text:" + formData.get('text'))
            console.log("compte produit:" + formData.get('Compte_Produit'))

            http.post('/portail/enum/saveRubrique', formData).then((response) => {
               
                if (response.data.error) {
                    setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                } else {
                    setCurrentRubrique(null)
                    setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                    setReload(!reload)
                    setLabelError(false)
                    setIsImgAdded(false)
                    setShowExistedImage(false)
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });
        }
    }
    const handleChangeForm = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCurrentRubrique({ ...currentRubrique, [name]: value });
    };

    return (
        <>
            <LoadingIndicator open={loading} />
            <CustomDialog params={myDialog} />
            <MySnackbar params={snack} />
            <MUIDataTable
                title=""
                data={disciplineList}
                columns={columns}
                options={options}
                className={classes.accDetail}
                />
         
            <Dialog disableEnforceFocus={true} maxWidth="md" open={currentRubrique!==null} onClose={handleCloseForm} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{currentRubrique ? currentRubrique.id > 0 ? "Edition":"Nouveau":null}</DialogTitle>
                
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="text"
                        label={texts.table_rubrique_labelField}
                        required={true}
                        fullWidth
                        value={currentRubrique ? currentRubrique.text : ""}
                        onChange={handleChangeForm}
                        error={labelError}
                        helperText={labelError ? texts.required_field : null}
                    />
                    <br/>
                    <br/>
                    {props.type === "rubrique" ? 
                        <div><label htmlFor="Descriptif">Descriptif</label>
                     <CKEditor
                        editor={FullEditor}
                        onReady={editor => {
                            editor.setData(currentRubrique ? currentRubrique.Descriptif : "")
                         }}
                         config={{
                             removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Highlight'],
                             toolbar: ["removeFormat",
                                 "|",
                                 "heading",
                                 "|",
                                 "fontSize",
                                "fontColor",
                                 "|",
                                 "bold",
                                 "italic",
                                 "underline",
                                 "|",
                                 "bulletedList",
                                 "numberedList",
                                 "|",
                                 "alignment",
                                 "indent",
                                 "outdent",
                                 "|",
                                 "link",
                                 "insertTable",
                                 "specialCharacters",
                                 "|",
                                 "horizontalLine",
                                 "pageBreak"],
                             fontColor: {
                                 colors: [
                                     {
                                         color: 'hsl(0, 0%, 0%)',
                                         label: 'Black'
                                     },
                                     {
                                         color: 'hsl(0, 0%, 30%)',
                                         label: 'Dim grey'
                                     },
                                     {
                                         color: 'hsl(0, 0%, 60%)',
                                         label: 'Grey'
                                     },
                                     {
                                         color: 'hsl(0, 0%, 90%)',
                                         label: 'Light grey'
                                     },
                                     {
                                         color: 'hsl(0, 0%, 100%)',
                                         label: 'White',
                                         hasBorder: true
                                     },
                                     {
                                         color: 'hsl(0, 75%, 60%)',
                                         label: 'Red'
                                     },
                                     {
                                         color: 'hsl(30, 75%, 60%)',
                                         label: 'Orange'
                                     },
                                     {
                                         color: 'hsl(60, 75%, 60%)',
                                         label: 'Yellow'
                                     },
                                     {
                                         color: 'hsl(90, 75%, 60%)',
                                         label: 'Light green'
                                     },
                                     {
                                         color: 'hsl(120, 75%, 60%)',
                                         label: 'Green'
                                     },
                                     {
                                         color: 'hsl(150, 75%, 60%)',
                                         label: 'Aquamarine'
                                     },
                                     {
                                         color: 'hsl(180, 75%, 60%)',
                                         label: 'Turquoise'
                                     },
                                     {
                                         color: 'hsl(210, 75%, 60%)',
                                         label: 'Light blue'
                                     },
                                     {
                                         color: 'hsl(240, 75%, 60%)',
                                         label: 'Blue'
                                     },
                                     {
                                         color: 'hsl(270, 75%, 60%)',
                                         label: 'Purple'
                                     },
                                     {
                                         color: 'hsl(332, 95%, 44%)',
                                         label: 'UNIGE'
                                     },
                                 ]},
                         }}
                         id="Descriptif"
                         onChange={(event, editor) => {
                             const data = editor.getData();
                             setCurrentRubrique({ ...currentRubrique, "Descriptif": data });
                         }}
                        />
                        <FormHelperText error={labelError} >{labelError ? texts.required_field : null}</FormHelperText>
                        <br/>
                        <FileUpload
                            acceptMatch={acceptMatch[typeResource]}
                            setIsDisabled={setIsDisabled}
                            isDisabled={isDisabled}
                            setFileResource={setFileResource}
                            setShowExistedImage={setShowExistedImage}
                            setIsImgAdded={setIsImgAdded}
                        />
                        <br/>

                        { showExistedImage &&
                            <Grid container>
                                <Grid item xs={6}>
                                    <div style={thumb}>
                                        {currentRubrique &&
                                            <>
                                                {currentRubrique.Image64 &&
                                                    <img src={'data:image/jpeg;base64, '+ currentRubrique.Image64}  style={imgThumb} alt='' />
                                                }
                                            </>
                                        }
                                        
                                    </div>
                                </Grid>
                                <Grid item xs={5}>
                                    <br/><br/><br/>
                                    {currentRubrique && 
                                        <>
                                            {currentRubrique.Image}
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <br/><br/><br/>
                                    <Fab onClick={_ => handleAction("delete", { id: currentRubrique.id, precision: "deleteImgRubrique", Image: currentRubrique.Image, Image64: currentRubrique.Image64})} size="small" color="secondary"  className={classes.actionBtn}>
                                        <DeleteIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        }
                     </div>
                        // <div><label for="text">Nom*</label>
                        // <CKEditor
                        //     editor={FullEditor}
                        //     onReady={editor => {
                        //         editor.setData(currentRubrique ? currentRubrique.text : "")
                        //     }}
                        //     config={{
                        //         removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Highlight'],
                        //         toolbar: ["removeFormat",
                        //             "|",
                        //             "heading",
                        //             "|",
                        //             "fontSize",
                        //             "fontColor",
                        //             "|",
                        //             "bold",
                        //             "italic",
                        //             "underline",
                        //             "|",
                        //             "bulletedList",
                        //             "numberedList",
                        //             "|",
                        //             "alignment",
                        //             "indent",
                        //             "outdent",
                        //             "|",
                        //             "link",
                        //             "insertTable",
                        //             "specialCharacters",
                        //             "|",
                        //             "horizontalLine",
                        //             "pageBreak"]
                        //     }}
                        //     id="text"
                        //     onChange={(event, editor) => {
                        //         const data = editor.getData();
                        //         setCurrentRubrique({ ...currentRubrique, "text": data });
                        //     }}
                        //     />
                        //     <FormHelperText error={labelError} >{labelError ? texts.required_field : null}</FormHelperText>
                        // </div>
                    : null
                    }

                    {props.type !== "rubrique" && props.type !== "discipline" && props.type !== "keyWord" ? <TextField
                        autoFocus
                        margin="dense"
                        name="Compte_Produit"
                        label={texts.table_rubrique_compteField}
                        fullWidth
                        value={currentRubrique ? currentRubrique.Compte_Produit : ""}
                        onChange={handleChangeForm}
                    /> : null}
                    <TextField
                        autoFocus
                        margin="dense"
                        name="Code"
                        label={texts.table_rubrique_codeField}
                        fullWidth
                        style={{display:"none"}}
                        value={currentRubrique ? currentRubrique.Code : ""}
                        onChange={handleChangeForm}
                    />
                </DialogContent>
              
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseForm}>
                        Annuler
                    </Button>
                    <Button variant="contained" onClick={handleSaveForm} color="primary">
                        Enregistrer
                    </Button>
                </DialogActions>
              
            </Dialog>
           
        </>
    );
}
