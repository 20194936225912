import axios from "axios";

 let api = axios.create({
  //baseURL: "http://127.0.0.1",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

api.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  if (error?.response?.status === 403) {
    window.location.href="/login"
  }
  return Promise.reject(error);
});

export default api