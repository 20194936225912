import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import http from "../../../http-common";
import TextTable_EN from '../../customComponent/textTable_EN.json';
import TextTable_FR from '../../customComponent/textTable_FR.json';
import { Grid } from '@material-ui/core';
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";
import moment from 'moment'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    container: {
        fontSize: '13px'
    },
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingBottom: '3%',
    },
    containerAlignementWithoutPadding: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        marginBottom: '-2%'

    },
    label: {
        textAlign: "right",
        marginRight: '3%',
        color: "blue"
    },
    nonFullTextfield: {
        width: "50%"
    },
    propsInput: {
        cursor: 'not-allowed'
    }
}));

const WaitingListTab = (props) => {
    const selectedActivityID = props.vSelectedActivityID;
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [watingList, setWatingList] = useState([])
    const [tableTexts, setTableTexts] = useState("")
    const [loadingInfos, setLoadingInfos] = useState(true);

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        var param = {
            activityNum: selectedActivityID,
        }
        http.post('/portail/activities/getWaitingList', param).then((response) => {
            setWatingList(response.data)
            setLoadingInfos(false)
        }, (error) => {
            console.log(error);
        });
    }, [selectedActivityID]);

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: "Nom & Prénom",
            name: "nomPrenom",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    (tableMeta.rowData[tableMeta.rowData.length - 1]) ? <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}><Tooltip title="Accompagnant"><AccessibilityNewIcon color="primary" /></Tooltip><span>{value}</span></div> : value
                ),
            }
        },
        {
            label: "Téléphone",
            name: "tel",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Coef.",
            name: "coef",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Groupe",
            name: "group",
            options: {
                sortThirdClickReset: true,
                sort: true,
            }
        },
        {
            label: texts.tab_activityParticipant_label_period,
            name: "periode",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true,
            }
        },
        {
            label: "Date",
            name: "visiteDate",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    moment(new Date(value)).format("DD.MM.YYYY")
                )
            }
        },
        {
            label: "Heure début",
            name: "visiteStart",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Heure fin",
            name: "visiteEnd",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Accompagnateur",
            name: "accompagnateur",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
    ];

    const options = {
        filter: true,
        selectableRows: "none",
        responsive: 'simple',
        sortOrder: { name: 'visiteDate', direction: 'asc' },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        downloadOptions: {
            filename: 'export_' + texts.label_waitingList + '_' + props.selectedActivityName + '.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {
                //dates
                copyData[index].data[6] = moment(new Date(copyData[index].data[6])).format("DD.MM.YYYY")
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        textLabels: tableTexts,
    };

    return (
        loadingInfos ? <LoadingIndicator open={loadingInfos} />
            :
            <div>
                <Grid container className={classes.containerAlignement}>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={texts.label_waitingList}
                            data={watingList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </div>
    )
}

export default WaitingListTab;
