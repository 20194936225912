import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useStyles } from "./ResourceAdd.css";
import { CustomDialog } from "../../customDialog/CustomDialog";
import http from "../../../http-common";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import LinkIcon from "@material-ui/icons/Link";
import DeleteIcon from "@material-ui/icons/Delete";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ShortTextIcon from "@material-ui/icons/ShortText";
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";
import { MySnackbar } from "../../mySnackbar/MySnackbar";
import {CardMedia} from '@material-ui/core';

export default function ResourceAdd(props) {
  const texts = useSelector((state) => state.texts);
  const [resourcesList, setResourcesList] = useState([]);
  //const [resourcesListResult, setResourcesListResult] = useState([]); //MI 03..05.24, #5153096401
  const [loadingInfos, setLoadingInfos] = useState(false);
  const [snack, setSnack] = useState({});
  const [reload, setReload] = useState(false);
  const [myDialog, setMyDialog] = React.useState({
    show: false,
    title: "",
    body: "",
    extraButtons: null,
    subtitle: null,
    jsxComponent: null,
  });

  useEffect(() => {
    http
      .get("/portail/activities/getResources?typeVisitID=" + props.activityID)
      .then(
        (response) => {
          setResourcesList(response.data);
          props.setResourcesListResult(response.data);
           
        },
        (error) => {
          console.log(error);
        }
      );
  }, [reload, props.activityID, props.reloadResourceList]);

  const handleDeleteResource = (resourceId) => {
    let pTitle = texts.delete_confirm_btn;
    let pBody = texts.delete_confirm_btn_text;

    function deleteConfirm() {
      setLoadingInfos(true);
      let params = { resourceID: resourceId };
      http.post("/portail/activities/deleteResource", params).then(
        (response) => {
          if (response.data.error) {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: "error",
            });
          } else {
            setSnack({
              message: texts[response.data.message],
              show: true,
              severity: "success",
            });
            setReload(!reload);
            setMyDialog({ show: false });
          }
          setLoadingInfos(false);
        },
        (error) => {
          console.log(error);
          setLoadingInfos(false);
        }
      );
    }

    let pExtraButtons = (
      <Button
        onClick={deleteConfirm}
        style={{ backgroundColor: "#9A0036", color: "#FFFFFF" }}
        variant="contained"
        type="button"
      >
        {texts.delete_btn}
      </Button>
    );
    setMyDialog({
      show: true,
      title: pTitle,
      body: pBody,
      extraButtons: pExtraButtons,
      subtitle: null,
      jsxComponent: null,
    });
  };

  //    //MI 19.02.24, #
  //   const getImage = (idImage) => {
  //   //console.log("id de l'image:"+idImage);
  //   if (idImage !== 0) {
  //     return '/portail/activities/viewResource?resourceID=' + idImage
  //   }
  // };

  const handleViewResource = (resourceId, type, contenu) => {
 
    if (type === "URL") {
 
      window.open(contenu);
      
    } else {
    
      window.open('/portail/activities/viewResource?resourceID=' + resourceId)
    return
      //  //MI 19.02.24, #5153096401   
      //  http.get('/portail/activities/viewResource?resourceID=' + resourceId).then(async (response) => {
      //     console.log(response.data); //window.URL.createObjectURL(response.data)
      //     const res = await blobToBase64(response.data)
      //     console.log(res)
      //     }, (error) => {
      //         console.log(error);

      //     })
    }
  };



  return (
    <>
      <LoadingIndicator open={loadingInfos} />
      <MySnackbar params={snack} />
      <CustomDialog params={myDialog} />
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
          <List>
            {resourcesList.map((item, index) => (
              <ListItem key={item.ID}>
                <ListItemAvatar>
                  <Avatar>
                    {item.Type === "document" ? <FolderIcon /> : null}
                    {item.Type === "image" ? (
                      item.ImageIllustration === true ? ( 
                        <LocalActivityIcon
   
                        />
                      
                        
                      ) : (
                        <ImageIcon />
                      )
                    ) : null}
                    {item.Type === "URL" ? <LinkIcon /> : null}
                    {item.Type === "text" ? <ShortTextIcon /> : null}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.Contenu} />
                <ListItemSecondaryAction>
                  {item.Interne ? (
                    <IconButton disabled edge="end">
                      <VisibilityOffIcon />
                    </IconButton>
                  ) : (
                    <IconButton edge="end">
                      <VisibilityIcon 
                          onClick={(_) => handleViewResource(item.ID, item.Type, item.Contenu) //MI 19.02.24, #5153096401 
                                }/>
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    onClick={(_) => handleDeleteResource(item.ID)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                  {item.Type !== "text" ? (
                    <IconButton
                      disabled
                      edge="end"
                      onClick={(_) =>
                        handleViewResource(item.ID, item.Type, item.Contenu)
                      }
                      aria-label="delete"
                    >
                      <FindInPageIcon

                      />
                    </IconButton>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
