import {
    RETRIEVE_TEXTS,
  } from "./types";
  
  import TextDataService from "../services/TextsService";
  
  export const retrieveTexts = (callback) => async (dispatch) => {
    try {
      const res = await TextDataService.getAll();

      dispatch({
        type: RETRIEVE_TEXTS,
        payload: res.data,
      });
      callback();
    } catch (err) {
      console.log(err);
    }
  };
  