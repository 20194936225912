import { useSelector } from "react-redux";
import { Paper, Grid, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import People from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CategoryIcon from '@material-ui/icons/Category';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Link } from "react-router-dom";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
    paperClass: {
        padding: theme.spacing(4),
    },
    cardMenu: {
        background: "#564146",
        color: "#FFFFFF",
    },
    iconBlack: {
        textDecoration: 'none'
    }
}));


export const Home = () => {
    const classes = useStyles();
    const userSession = useSelector(state => state.session);
    const texts = useSelector(state => state.texts);
   

    return (
        <div>
            <Paper elevation={3} className={classes.paperClass}>
                <Typography variant="h4">{texts.welcome_message} {[userSession.users.members[0].firstname, " ", userSession.users.members[0].name.toUpperCase()]}</Typography>
                <br />
                <Grid
                    container
                     spacing={2}
                    direction="row"
                    display='flex'
                    // justify="center"
                    alignItems="flex-start"
                >
                    {userSession.users.menuItem.map((item, index) => (
                        item.link !== "/home" ?
                        <Grid key={index} item xs={12} sm={12} md={6}>
                            <Link to={item.link} className={classes.iconBlack}>
                                <Card className={classes.cardMenu}>
                                    <CardActionArea>
                                    
                                        <CardContent className="CartContentLigne">
                                            
                                            {item.link === "/activities" ? <AppsIcon />:null}
                                            {item.link === "/registrations" ? <AssignmentIcon /> : null}
                                            {item.link === "/cotisations" ? <LocalAtmIcon /> : null}
                                            {item.link === "/invoices" ? <LocalAtmIcon /> : null}
                                            {item.link === "/users" ? <AccountBoxIcon /> : null}
                                            {item.link === "/liste-activities" ? <ListIcon /> : null}
                                            {item.link === "/adherents" ? <People /> : null}
                                            {item.link === "/rubriques" ? <CategoryIcon /> : null}
                                            {item.link === "/intervenants" ? <EmojiPeopleIcon /> : null}
                                            {item.link === "/statistiques" ? <EqualizerIcon /> : null}
                                            {item.link === "/presences" ? <EventAvailableIcon />:null}
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {texts[item.nom]}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>:null
                    ))}
                </Grid>
            </Paper> 
        </div>
    )
}

export default Home;