import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TableRow, TableCell, Tooltip, Fab, Dialog, DialogContent, DialogActions, Button, Avatar, CardMedia, Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import http from "../../http-common";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import InfosActivity from '../../components/activity/InfosActivity';
import moment from 'moment'
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr"; //pour mettre en français le datepicker
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../components/customDialog/CustomDialog'
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
    pastille:{ //MI 23.02.24, #
        //background:"#8D6E63",
        //marginLeft: 30,
        //marginRight: -10,
       
      
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    filterDates:{
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        marginLeft: 15,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}));


export const Activities = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger=texts.langueCharger;
    const [tableTexts, setTableTexts]=useState("")
    const [activitiesList, setActivitiesList] = useState([])//tableau données datatable
    const [displayActif, setDisplayActif] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState(null)//current row
    const [filterDu, setFilterDu] = useState(null)
    const [filterAu, setFilterAu] = useState(null)
    const [typesList, setTypesList] = useState([])
     const [categoriesList, setCategoriesList] = useState([]); //MI 16.02.24,#5153096401
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [hideAction, setHideAction] = useState(false);
    const [snack, setSnack] = React.useState({});
    const [myDialog, setMyDialog] = React.useState({ show: false, title: "", body: "", extraButtons: null, subtitle: null, jsxComponent: null });
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'du', direction: 'desc' })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    //gestion erreur champ obligatoire
    const [fieldError, setFieldError] = useState([false, false, false, false])//Nom, Categorie, Discipline
    

    useEffect(()=>{
        if(langueCharger==="FR"){
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    },[tableTexts,langueCharger]);

    useEffect(() => {
        http.get('/portail/Activities/getFilterPeriode').then((response) => {
            setFilterDu(moment(response.data.start, 'DD.MM.YYYY').format('YYYY-MM-DD'))
            setFilterAu(moment(response.data.end, 'DD.MM.YYYY').format('YYYY-MM-DD'))
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
        //MI 16.07.2021 libellé des natures
        http.get(`/portail/enum/getTypes`).then((response) => {
            setTypesList(response.data.liste)
        }, (error) => {
            console.log(error);
        });
      //MI 16.02.24, #5153096401
       http.get(`/portail/enum/getCategories`).then((response) => {
            setCategoriesList(response.data)
        }, (error) => {
            console.log(error);
        });

    }, [dispatch]);

    useEffect(() => {
        if ((filterDu !== null) && (filterAu !== null)) {
            setLoading(true)
            http.get('/portail/Activities/getAll?actif=' + displayActif + "&du=" + moment(filterDu, 'YYYY-MM-DD').format('DD.MM.YYYY') + "&au=" + moment(filterAu, 'YYYY-MM-DD').format('DD.MM.YYYY')).then((response) => {
               console.log(response.data)
              setActivitiesList(response.data)
                setLoading(false)
            }, (error) => {
                console.log(error);
                setLoading(false)
            });
        }
    }, [displayActif, filterDu, filterAu, dispatch, reload]);

    const getTypeAttribute = (ref,type) => {
        let retour = ""
        for (let index = 0; index < typesList.length; index++) {
            if (typesList[index].ref === ref)
                retour = typesList[index][type]
        }
        return retour;
    }

    const handleActifFilter = () => {
        setDisplayActif(!displayActif)
    }

    const handleFilterPeriodeDu = (date) => {
        setFilterDu(date)
    }
    const handleFilterPeriodeAu = (date) => {  
        setFilterAu(date)
    }

    const handleLineSelection = (currentRowsSelected) => {
        setFieldError([false, false, false, false])
        let row = activitiesList[currentRowsSelected[0].dataIndex]

        if (selectedActivity) {
            if (selectedActivity.ID !== row.ID) {
                setSelectedActivity(row)
                window.scrollTo(0, 0)
            } else {
                setSelectedActivity(null)
            }
        } else {
            setSelectedActivity(row)
        }
    };

    const handleRowChange = (name,value,ref) => {
        
        if (name === "Nature") {
            let refVal = ""
            switch (value) {
                case "VGE":
                    refVal = "Visite culturelle"
                    break;
                case "VHC":
                    refVal = "Excursion et activité associée"
                    break;
                case "Billetterie":
                    refVal = "Billetterie"
                    break;
                default:
                    selectedActivity.categorie = { id: 0, text: "" }
                    break;
            }
            if (refVal !== "") {
                selectedActivity.categorie = ref
            }
        }
      
        setSelectedActivity({ ...selectedActivity, [name]: value });
    };

    function SubRow(props) {
        const colSpan = props.rows.length + 1;
        return (
            <TableRow>
                <TableCell colSpan={colSpan} className={classes.subRowCell} >

                    <Grid container direction="row" alignItems="flex-end" justify="center" spacing={2}>
                        {props.rows.map((item, index) => (
                            item.Date_Debut !== "0000-00-00T00:00:00.000Z" ?
                            <Grid key={item.ID} item xs={12} sm={12}>
                                {moment(new Date(item.Date_Debut)).format('DD.MM.YYYY')}
                            </Grid>
                            :
                            null
                        ))}
                    </Grid>

                </TableCell>
            </TableRow>
        );
    }

    //MI 15.07.2021 renvoie le libellé de la nature
    function FullNature (props) {
       return( 
           <span >{getTypeAttribute(props.code, "name")}</span>
       );
             
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setSelectedActivity(null)
    }

    const saveRecord = (action) => {
       
        let fError = [false, false, false, false]
        if (selectedActivity.Nom==="") {
            fError[0]=true
        }
        if (selectedActivity.categorie.id === 0) {
            fError[1] = true
        }
        if (selectedActivity.Discipline.length === 0) {
            fError[2] = true
        }
        if (selectedActivity.Nature.length === 0) {
            fError[3] = true
        }
        let checkError = fError.every(v => v === false);

        if (!checkError) {
            setFieldError(fError)
        } else {
            //SAVE
            setLoading(true)
            let backupRemarque = selectedActivity.Remarque;
            selectedActivity.Remarque = encodeURIComponent(selectedActivity.Remarque) //encodeURIComponent(encodeURIComponent(selectedActivity.Remarque))
            let rowData = JSON.stringify(selectedActivity)
            rowData = rowData.replace(/&/g, 'feff0026')
            rowData = rowData.replace(/=/g, 'feff003d')
            rowData = rowData.replace(/%/g, 'feff0025')

            let params = { row: rowData };
            selectedActivity.Remarque = backupRemarque;
            
            http.post('/portail/activities/save',params).then((response) => {
                if (response.data.error) {
                    setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                } else {
                    setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                    setReload(!reload)
                    if (action === "save") {
                        setSelectedActivity(null)
                    } else {
                        setSelectedActivity({ ...selectedActivity, "ID": response.data.row.ID });
                    }
                    setFieldError([false, false, false, false])
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });
        }
    }
    
    const handleDelete = () => {
        let pTitle = texts.delete_confirm_btn;
        let pBody = texts.delete_confirm_btn_text;
        function deleteConfirm() {
            setLoading(true)
            let params = { row: JSON.stringify(selectedActivity) };

            http.post('/portail/activities/delete', params).then((response) => {
                if (response.data.error) {
                    setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                } else {
                    setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                    setReload(!reload)
                    setSelectedActivity(null)
                    setMyDialog({ show: false })
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });  
        }
        let pExtraButtons = <Button onClick={deleteConfirm} style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }} variant="contained" type="button">{texts.delete_btn}</Button>
        setMyDialog({ show: true, title: pTitle, body: pBody, extraButtons: pExtraButtons, subtitle: null, jsxComponent: null })    
    }

    const PeriodComp = ({value}) => {
        let retour = ""
        let allreadyPresent = [];
        for (let index = 0; index < value.length; index++) {
            const element = value[index];
            if (allreadyPresent.indexOf(element.ID_Période_6_ID.Nom)<0) {
                retour += element.ID_Période_6_ID.Nom+", "
                allreadyPresent.push(element.ID_Période_6_ID.Nom)
            }
        }
        retour = retour.substring(0,retour.length-2)
        return (
            retour
        )
    }

     //MI 16.02.24, #5153096401 fonction pour récupérer la catégorie de l'activité
  const getCategoryAttribute = (ref, type) => {
  //console.log(categoriesList);
    let retour = ''
    for (let index = 0; index < categoriesList.length; index++) {
      if (ref !== null) {
        if (categoriesList[index].text === ref.text)
          retour = categoriesList[index][type]
        //console.log(categoriesList[index][type]);
      }
    }

    return retour
  }

    //MI 19.02.24 récupéré l'image de l'activité dans les ressources
  const getImage = (idImage) => {
    //console.log("id de l'image:"+idImage);
    if (idImage !== 0) {
      return '/portail/activities/viewResource?resourceID=' + idImage
    }
  }
    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: "Nom",
            name: "Nom",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    tableMeta.rowData[8]?(tableMeta.rowData[8].length === 0) ? <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}><Tooltip title="Aucune date pour cette activité"><WarningIcon color="primary" /></Tooltip><span>{value}</span></div> : value : ""
                ),
            },
        },
        {
            label: "Type",
            name: "Nature",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true, //affiche la colonne dans le tableau des filtres
                filterOptions: { renderValue: v => <FullNature code={v} /> },
                //MI 15.07.2021 afficher le libellé de la nature
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? <FullNature code={value} /> : ""
                )
            }
        },
        {
            label: "Categorie",
            name: "categorie",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: true,   //MI 07.02.2022, #2217664714
                customHeadRender: (columnMeta, updateDirection) => ( //MI 28.02.24, #5153184889
                <th
                    className="MuiTableCell-root MuiTableCell-head"
                    key={columnMeta.index}
                    style={{textAlign: "center",}}
                >
                    Catégorie
                </th>
            ),
                sortCompare: (order) => { //MI 24.01.2022, #2181852970              
                   return (obj1, obj2) => {
                    //   console.log(obj1);// rt 01 09 2022
                     
                      let val1 = obj1.rowData[3] !== null ? obj1.rowData[3].Nom : "";
                      let val2 = obj2.rowData[3] !== null ? obj2.rowData[3].Nom : "";
                      return (val1 > val2) * (order === 'asc' ? 1 : -1) || (val1 < val2) * (order === 'desc' ? 1 : -1);
                      
                    };                   

                  },            
                customBodyRender: (value, tableMeta, updateValue) => (  //MI 23.02.24, #5153096401                  
                   value ? <> 
                   <Container className={classes.pastille} maxWidth ="sm" style={{
                    display: "flex",
                    justifyContent: "center",
                    margin:"0"}}> 
                   <Avatar
                    aria-label="recipe"
                     className={classes.avatar}
                      style={{                       
                        background: getCategoryAttribute(
                          value,
                          'color',
                        ),
                      }}
                    >                   
                      &nbsp;                     
                    </Avatar> 
                    </Container >              
                    <div style={{textAlign: "center",}}>
                    {value.text}
                    </div>
                    
                    </>: "" //value.text : ""
                   
                )
            }
        },
        {
            label: "Discipline",
            name: "Discipline",
            options: {
                sortThirdClickReset: false,
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? value.map((item, index) => (
                        <div key={index}>{item.Nom}</div>
                    )) : ""
                )
            }
        },

        {
            label: "Intervenant(s)",
            name: "intervenant",
            options: {
                sortThirdClickReset: true,
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? value : ""
                   
                )
            }
        },
        {
            label: "Prix",
            name: "Prix",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Compte produit",
            name: "Compte_Produit",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            }
        },
        {
            label: "Période",
            name: "Visite_Type_Visite",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <PeriodComp value={value}/>
                )
            }
        },
        {
            label: "Date début",
            name: "du",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value?moment(new Date(value)).format("DD.MM.YYYY"):""
                )
            }
        },
        {
            label: "Date fin",
            name: "au",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ?moment(new Date(value)).format("DD.MM.YYYY"):""
                )
            }
        },
        {
            label: "Dates",
            name: "Visite_Type_Visite",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: true,
            }
        },
        //MI 01.03.2022, #2334108691
        {
            label: "Remarque interne",
            name: "Remarque_Interne",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: true,
            }
        },

        { //MI 16.02.24, #5153096401
            label: "Image",
            name: "Image",
            options: {
                sortThirdClickReset: false,
                sort: true,
                filter: false,
                //display: props.type === "rubrique" ? true : 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => (
                      //console.log(value.idRessource)
                    value.idRessource > 0 ? (
                    <CardMedia
                    className={classes.activityCardClass}
                    component="img"
                    image={getImage(value.idRessource)}
                    alt={value.contenuRessource}
                    style={{
                      margin: 'auto',
                      paddingTop: '5px',
                      width: '60%',
                      height: '60%',
                    }}
                  ></CardMedia>
                ) : null
                    
                  
                )
            },
        },
    ];
    
    function replaceSpecialChars(str) {
        str = str.toLowerCase();
        str = str.replace(/[àáâãäå]/, "a");
        str = str.replace(/[èéêë]/, "e");
        str = str.replace(/[ç]/, "c");

        return str.replace(/[^a-z0-9]/gi, '');
    }

    const options = {
        filter: true,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: handleLineSelection,
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        downloadOptions: {
            filename: 'export_' + texts.title_activities + '.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            
            for (let index = 0; index < copyData.length; index++) {
                //type
               copyData[index].data[2] =  getTypeAttribute(copyData[index].data[2], "name")
               //console.log( getTypeAttribute(copyData[index].data[2], "name"));
               //catégories
                copyData[index].data[3] = copyData[index].data[3] !== null ? copyData[index].data[3].Nom : ""
               
                // Discipline
                let tmpDiscipline = ""
                for (let index2 = 0; index2 < copyData[index].data[4].length; index2++) {
                        tmpDiscipline += copyData[index].data[4][index2].Nom+","
                }
                copyData[index].data[4] = tmpDiscipline
                //intervenants
                // for (let index2 = 0; index2 < copyData[index].data[5].length; index2++) {
                //     if (copyData[index].data[5][index2].TypeVintervtoIntervenant) {
                //         copyData[index].data[5][index2] = copyData[index].data[5][index2].TypeVintervtoIntervenant.Nom + " " + copyData[index].data[5][index2].TypeVintervtoIntervenant.Prenom + ","
                //     } else {
                //         copyData[index].data[5][index2] =""
                //     }
                // }
                // copyData[index].data[5] = copyData[index].data[5].join(",")

                //périodes
                let periods = copyData[index].data[8]
                let retour = ""
                let allreadyPresent = [];
                for (let index = 0; index < periods.length; index++) {
                    const element = periods[index];
                    if (allreadyPresent.indexOf(element.ID_Période_6_ID.Nom) < 0) {
                        retour += element.ID_Période_6_ID.Nom + ", "
                        allreadyPresent.push(element.ID_Période_6_ID.Nom)
                    }
                }
                retour = retour.substring(0, retour.length - 2)
                copyData[index].data[8] = retour

                //dates
                copyData[index].data[9] = moment(new Date(copyData[index].data[9])).format("DD.MM.YYYY")
                copyData[index].data[10] = moment(new Date(copyData[index].data[10])).format("DD.MM.YYYY")
                //dates visites
                for (let index2 = 0; index2 < copyData[index].data[11].length; index2++) {
                    if (copyData[index].data[11][index2].Date_Debut) {
                        copyData[index].data[11][index2] = moment(new Date(copyData[index].data[11][index2].Date_Debut)).format("DD.MM.YYYY")
                    } else {
                        copyData[index].data[11][index2] = ""
                    }
                }
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        //  onRowClick: handleLineSelection,
        textLabels: tableTexts,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        isRowExpandable: (dataIndex, expandedRows) => {
            if (activitiesList[dataIndex].Visite_Type_Visite) {
                if (activitiesList[dataIndex].Visite_Type_Visite.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <SubRow rows={rowData[11]} />
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => null,
        customToolbar: () => {
            const newRecord = () => {
                setFieldError([false, false, false, false])
                setSelectedActivity({ ID : 0})
            }
            // MI 21.07.2021
            const element = [
                <MuiPickersUtilsProvider key={1} utils={MomentUtils} >
                    <DatePicker cancelLabel={texts.datepicker_annuler} autoOk id="filterDu" label="Du" format="DD/MM/YYYY" value={filterDu} onChange={handleFilterPeriodeDu} />
                    <DatePicker cancelLabel={texts.datepicker_annuler} autoOk id="filterAu" label="Au" format="DD/MM/YYYY" value={filterAu} onChange={handleFilterPeriodeAu} />
                </MuiPickersUtilsProvider>,
                <Tooltip key={2} title={"Nouvelle activité"}>
                    <Fab className={classes.iconButton} size="small" color="primary" aria-label="add" onClick={newRecord}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            ]

            return (
                <MuiDatatableToolBar extraBtn={element} label={texts.label_periode_actif_filter} actifFilter={displayActif} handleActifFilter={handleActifFilter} />  
            );
        },
        onTableChange: (action,tableState) => {
             //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
        customSearch: (searchQuery, currentRow, columns) => {
            let isFound = false;
            currentRow.forEach(col => {
                let typeOfVar = typeof col;
                if ((typeOfVar === "string") || (typeOfVar === "number")) {
                    if (replaceSpecialChars(col.toString()).includes(replaceSpecialChars(searchQuery))) {
                        isFound = true;
                    }
                } 

                if (Array.isArray(col)) {
                    col.map((item, index) => {
                        if ("Nom" in item) {
                            if (replaceSpecialChars(item.Nom.toString()).includes(replaceSpecialChars(searchQuery))) {
                                isFound = true;
                            }
                        }
                    })
                }
            });
            return isFound;

        },
    };

    return (
        <React.Fragment>
            <CustomDialog params={myDialog} />
            <MySnackbar params={snack} />
            <LoadingIndicator open={loading} />
                <main className={classes.content}>
                    <span className={classes.toolbar}> </span>
                    <Grid container >
                        <Grid item sm={12} >
                         <MUIDataTable
                                title={texts.title_activities}
                                data={activitiesList}
                                columns={columns}
                                options={options}
                            />
                        </Grid>
                    </Grid>
            </main>
            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="lg"
                open={selectedActivity !== null}
                disableEnforceFocus={true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                // classes={{ paper: classes.dialogPaper }}
            >
                {/* <DialogTitle id="max-width-dialog-title">Optional sizes</DialogTitle> */}
                <DialogContent style={{ padding: "0px" }}>
                    <InfosActivity
                        vSelectedActivity={selectedActivity}
                        handleRowChange={handleRowChange}
                        setSelectedActivity={setSelectedActivity}
                        fieldError={fieldError}
                        setHideAction={setHideAction}
                        setReload={setReload}
                        reload={reload}
                    />
                </DialogContent>
                {!hideAction?<DialogActions>
                    {selectedActivity !== null ? selectedActivity.ID > 0 ? <Button className={classes.btnClass} variant="contained" onClick={handleDelete} style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}>{texts.delete_btn}</Button> : null : null}
                    <Button className={classes.btnClass} variant="contained" onClick={handleClose}>Annuler</Button>
                    <Button className={classes.btnClass} variant="contained" onClick={_=>saveRecord("apply")} color="primary">Appliquer</Button>
                    <Button className={classes.btnClass} variant="contained" onClick={_=>saveRecord("save")} color="primary">Enregistrer</Button>
                </DialogActions>:null}
            </Dialog>
            </React.Fragment>
    )

}

export default Activities;