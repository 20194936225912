import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../../http-common";
import { Checkbox, Grid, TextField, FormControlLabel } from '@material-ui/core';
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";

const useStyles = makeStyles(() => ({
    container: {
        fontSize:'13px'
    }, 
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        paddingBottom: '3%', 
    }, 
    label:{
        textAlign:"right", 
        marginRight:'3%',
        color:"blue"
    },
    nonFullTextfield:{
        width:"20%"
    },
    containerAlignementPadding:{
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        marginBottom: '0%'
    },
    propsInput: {
        cursor: 'not-allowed'
    }, 
    line:{
        backgroundColor: '#564146',
        height:1
    }
}));

const  AdditionalInfosTab = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID = props.vSelectedMemberID;
    const [additionalInfosAdherent, setAdditionalInfos] = useState({
        NumAd:"",
        Gender: "",
        Name: "",
        Firstname: "",
        Birth: "",
        Sex: "",
        Job: "",
        Studies:"",
        Region: "",
        Country: "",
        KnowUni3: "",
        IdNumber: "",
        Note: "",
    })
    const [ckFreeEntry, setCkFreeEntry] = useState(false)
    const [ckeBulletin, setEBulletin] = useState(false)
    const [loadingInfos, setLoadingInfos] = useState(true);

    useEffect(() => {
        setLoadingInfos(true)
        var param = {
            vMember: selectedMemberID,
        }
        http.post(`/portail/members/getAdditionalInfos`, param).then((response) => {
            if ("NumAd" in response.data) {
                setAdditionalInfos(response.data)
                setCkFreeEntry(response.data.FreeEntry)
                setEBulletin(response.data.ElectronicBulletin)
            }
            setLoadingInfos(false)
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID])
  
    return (
        loadingInfos ? <LoadingIndicator open={loadingInfos} />
            :
        <div>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_title} id="vTitle" fullWidth disabled inputProps={{ className: classes.propsInput }} value={additionalInfosAdherent.Gender} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_name} id="vName" fullWidth disabled inputProps={{ className: classes.propsInput }} value={additionalInfosAdherent.Name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_firstname} id="vFirstname" fullWidth disabled inputProps={{ className: classes.propsInput }} value={additionalInfosAdherent.Firstname} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_birth} id="vBirth" fullWidth disabled inputProps={{ className: classes.propsInput }} value={additionalInfosAdherent.Birth} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_sex} id="vSex" fullWidth disabled inputProps={{ className: classes.propsInput }} value={additionalInfosAdherent.Sex} />
                </Grid>
            </Grid>
            <br/>
            <hr className={classes.line}/>
            <br/>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_job} id="vJob" fullWidth disabled inputProps={{className: classes.propsInput}}  value={additionalInfosAdherent.Job} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_region} id="vRegion" fullWidth disabled inputProps={{className: classes.propsInput}}  value={additionalInfosAdherent.Region} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_country} id="vCountry" fullWidth disabled inputProps={{className: classes.propsInput}}  value={additionalInfosAdherent.Country} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_idNumber} id="vIdNumber" fullWidth disabled inputProps={{className: classes.propsInput}}  value={additionalInfosAdherent.IdNumber} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_studies} id="vStudies" multiline rowsMax={4} fullWidth disabled inputProps={{className: classes.propsInput}} value={additionalInfosAdherent.Studies} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_knowUni3} id="vKnowUni3" fullWidth disabled inputProps={{className: classes.propsInput}} value={additionalInfosAdherent.KnowUni3} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ckFreeEntry}
                                name="vFreeEntry"
                                id="vFreeEntry"
                                color="primary"
                                disabled={true}
                            />
                        }
                        label={texts.label_freeEntry}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ckeBulletin}
                                name="vEBulletin"
                                id="vEBulletin"
                                color="primary"
                                disabled={true}
                            />
                        }
                        label={texts.label_eBulletin}
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
            <br/><hr className={classes.line}/><br/>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <TextField label={texts.label_note} id="vJob" variant="outlined" multiline rows={6} fullWidth disabled inputProps={{className: classes.propsInput}} value={additionalInfosAdherent.Note}/>
                </Grid>
            </Grid>
        </div>
    )
}
  
export default AdditionalInfosTab;
