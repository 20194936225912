import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../../http-common";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        fontSize: '13px'
    },
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingBottom: '3%',
    },
    containerAlignement2: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingBottom: '1%',
    },
    label: {
        textAlign: "right",
        marginRight: '3%',
        color: "blue"
    },
    gridChips: {
        textAlign: "center",
    },
}));

const HistoricalClassesTabV2 = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID = props.vSelectedMemberID;
    const [historicalClassesAdherent, setHistoricalClassesAdherent] = useState({})
    useEffect(() => {
        var param = {
            vMember: selectedMemberID,
            vType: props.vType
        }
        http.post(`/portail/members/getHistoric`, param).then((response) => {
            setHistoricalClassesAdherent(response.data)
            // console.log(response.data);// rt 01 09 2022
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID, props.vType])

    return (
        <div>
            <h3>{props.vTitle}</h3>
            <Grid container className={classes.containerAlignement2}>
                <Grid className={classes.gridChips} item xs={4}><Chip style={{ width: "80%" }} color="primary" label={["Obtenues : ", historicalClassesAdherent.totalObtenu]} /></Grid><Grid className={classes.gridChips} item xs={4}><Chip style={{ width: "80%" }} color="primary" label={["Demandées : ", historicalClassesAdherent.totalDemande]} /></Grid><Grid className={classes.gridChips} item xs={4}><Chip style={{ width: "80%" }} color="primary" label={["Coeff. : ", historicalClassesAdherent.coef, "%"]} /></Grid>
            </Grid>
            <Grid container className={classes.containerAlignement}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead >
                                <TableRow >
                                    <TableCell style={{ width: '30%' }}>{texts.tab_histoClasses_label_period}</TableCell>
                                    <TableCell align="center" style={{ width: '2%' }}></TableCell>
                                    <TableCell align="center" style={{ width: '30%' }}>{texts.tab_histoClasses_label_visits}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historicalClassesAdherent.Historique &&
                                    historicalClassesAdherent.Historique.map(function (row, i) {
                                        return (
                                            <TableRow key={row.ID}>
                                                <TableCell style={{ width: '20%' }}><span>{
                                                    row.Nom
                                                }</span></TableCell>
                                                <TableCell style={{ width: '2%' }}>{row.TotAttrib}/{row.TotHist}</TableCell>
                                                <TableCell style={{ width: '50%' }}>
                                                    {row.List.map((lst) => (
                                                        <Table key={lst.ID}>
                                                            <TableBody>
                                                                <TableRow >
                                                                    <TableCell align="left" style={{ width: '80%' }}>{lst.Name}</TableCell>
                                                                    <TableCell align="right" style={{ width: '20%' }}>{lst.Statut}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    ))
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

export default HistoricalClassesTabV2;
