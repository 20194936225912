
import http from '../../http-common';

/**
 * Vérifie si une date potentielle avec une plage horaire se chevauche avec au moins une plage horaire
 * dans un tableau de dates spécifié.
 *
 * @param {Array} tableauDeDates - Tableau d'objets représentant les dates de début et de fin.
 * @param {string} date - Date à vérifier au format "YYYY-MM-DD".
 * @param {string} heureDebut - Heure de début à vérifier au format "HH:mm".
 * @param {string} heureFin - Heure de fin à vérifier au format "HH:mm".
 * @returns {boolean} - True si un chevauchement est trouvé, sinon False.
 */
function verifierChevauchementsDate(tableauDeDates, date, heureDebut, heureFin) {
    const startDatePotentielle = new Date(date + ' ' + heureDebut);
    const endDatePotentielle = new Date(date + ' ' + heureFin);
    // Utilisez la méthode some pour vérifier si l'une des dates dans le tableau se chevauche
    return tableauDeDates.some(({ dateDebut, dateFin }) => {
        const startDateArray = new Date(dateDebut);
        const endDateArray = new Date(dateFin);
        return startDatePotentielle < endDateArray && endDatePotentielle > startDateArray;
    });
}

/**
 * Convertit une liste d'objets de visites en un tableau de dates avec des objets dateDebut et dateFin.
 * Si une visite a plusieurs dates de cours, chaque date sera représentée individuellement.
 *
 * @param {Array} visiteObjects - Liste d'objets de visites à traiter.
 * @returns {Array} - Tableau d'objets représentant les dates de début et de fin de chaque visite.
 */
const flateArrayDateAttribuee = (visiteObjects) => {
    const tableauDeDates = visiteObjects.map(obj => {
        // console.log('obj.attr_visit.DatesCours?.vcDatesCours', obj.attr_visit.DatesCours?.vcDatesCours, obj.attr_visit.DatesCours, obj.attr_visit, obj);
        const datesCours = obj && obj.attr_visit
            ? (obj.attr_visit.DatesCours?.vcDatesCours || [obj.attr_visit.Date_Debut])
            : [];

        return datesCours.map(dateCours => {
            return {
                dateDebut: dateCours + ' ' + obj.attr_visit.Heure_debut,
                dateFin: dateCours + ' ' + obj.attr_visit.Heure_fin,
            };
        });
    }).flat();

    return tableauDeDates;
}

/**
* Fonction pour vérifier les conflits entre les visites potentielles et les visites attribuées.
* @param {Array} visitesPotentielles - Le tableau des visites potentielles.
* @param {Array} visitesAttribuees - Le tableau des visites attribuées.
* @returns {Array} - Le tableau des visites potentielles avec les informations de conflit.
*
* Utilisation de la fonction 
* tableau de visites potentielles
* const visitesPotentielles = [{"Visite_Type_Visite": [{"Date_Debut": "2023-09-21","Heure_debut": "09:15","Heure_fin": "12:00","DatesCours": {"vcDatesCours": []; ....}]
* tableau de visites attribuées
* const visitesAttribuees = [{"attr_visit": {"Date_Debut": "2024-02-09","Heure_debut": "12:00","Heure_fin": "02:00", ...}, ...]; 
* const visitesAvecConflits = verifierConflits(visitesPotentielles, visitesAttribuees);
**/
function verifierConflits(visitesPotentielles, visitesAttribuees) {
    if (!visitesAttribuees || visitesAttribuees.length === 0)
        return visitesPotentielles
    const flatAttribue = flateArrayDateAttribuee(visitesAttribuees);
    return visitesPotentielles.map(visitePotentielle => {
        visitePotentielle.SessionsProposees.forEach(SessionsProposee => {
            const heureDebut = SessionsProposee.Heure_debut;
            const heureFin = SessionsProposee.Heure_fin;
            const conflits = [];
            const DateCours = SessionsProposee.DatesCours || { vcDatesCours: [SessionsProposee.Date_Debut] }; // si dataCours est null ajouter dans la liste la date de début
            DateCours.vcDatesCours.forEach(datePotentielle => {
                if (flatAttribue && verifierChevauchementsDate(flatAttribue, datePotentielle, heureDebut, heureFin)) {
                    conflits.push({ dateAttribuee: datePotentielle });
                }
            });

            if (conflits.length > 0) {
                SessionsProposee.conflictuel = true;
                SessionsProposee.datesConflit = conflits;
            }
        });

        return structuredClone(visitePotentielle);
    });
}

/**
 * Obtient les attributions à partir du serveur.
 * @param {Object} params - Paramètres de la requête.
 * @param {array} attribution - Objet d'attribution déjà existant (peut être null).
 * @returns {Promise} - Une promesse qui résoudra avec l'objet d'attribution.
 */
export const getAttribution = async (params, attribution) => {
    if (attribution && attribution.length > 0)
        return attribution;
    const response = await http.post('/portail/members/getAttribution', params);
    // console.log('response.data.attribution =====================>>>>>>>>>>>>>>>>> ', response.data.attribution);
    return response.data.attribution;
}

export default verifierConflits