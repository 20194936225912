import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { AppBar, Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import DetailTab from './DetailTab';
import VisiteTab from './VisiteTab';
import ParticipantTab from './ParticipantTab';
import WaitingListTab from './WaitingListTab';
import clsx from 'clsx';
// import ManagePaymentTab from './ManagePaymentTab';
// import ConfirmationSettingsTab from './ConfirmationSettingsTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth : '100%',
    backgroundColor: theme.palette.background.paper,
    flexGrow: "1"
  },
  root2: {
    justifyContent: "center"
  },
  scroller: {
    flexGrow: "0"
  },
  tabDisabled: {
    cursor: 'not-allowed!important',
    "pointer-events": "auto!important",
  }
}));

const InfosActivityTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const texts = useSelector(state => state.texts);
  const selectedActivityID = props.vSelectedActivityID;
  const [tabDisabled, setTabDisabled] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedActivityID===0) {
      setValue(0);
    }
  }, [selectedActivityID])

  useEffect(() => {
    let checkError = props.fieldError.every(v => v === false);
    if (!checkError) {
      setValue(0);
    }
  }, [props.fieldError])

  
  return (
    <span className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          classes={{ root: classes.root2, scroller: classes.scroller }}
        >
          <Tab
            className={clsx({[classes.tabDisabled]: tabDisabled,})}
            disabled={tabDisabled}
            label={texts.activity_tab_detail}
            {...a11yProps(0)}
          />
          {selectedActivityID > 0 ? <Tab className={clsx({ [classes.tabDisabled]: tabDisabled, })} disabled={tabDisabled} label={texts.activity_tab_visite} {...a11yProps(1)} /> : null}
          {selectedActivityID > 0 ? <Tab className={clsx({ [classes.tabDisabled]: tabDisabled, })} disabled={tabDisabled} label={texts.activity_tab_listeParticipant} {...a11yProps(2)} />:null}
          {selectedActivityID > 0 ? <Tab className={clsx({ [classes.tabDisabled]: tabDisabled, })} disabled={tabDisabled} label={texts.activity_tab_manageWaitingList} {...a11yProps(3)} />:null}
          {/* {selectedActivityID > 0 ? <Tab label={texts.activity_tab_managePayment} {...a11yProps(4)} />:null}
          {selectedActivityID > 0 ? <Tab label={texts.activity_tab_confirmationSettings} {...a11yProps(5)} />:null} */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} >{/*activity_tab_détail*/}
        <DetailTab
          vSelectedActivityID={selectedActivityID}
          selectedActivityName={props.selectedActivityName}
          handleRowChange={props.handleRowChange}
          setSelectedActivity={props.setSelectedActivity}
          fieldError={props.fieldError}
        />
      </TabPanel>
      <TabPanel value={value} index={1} >{/*activity_tab_visite*/}
        <VisiteTab
          vSelectedActivityID={selectedActivityID}
          selectedActivityName={props.selectedActivityName}
          selectedActivityNature={props.selectedActivityNature}
          setHideAction={props.setHideAction}
          setTabDisabled={setTabDisabled}
          setReloadTypeVisit={props.setReload}
          reloadTypeVisit={props.reload}
        />
      </TabPanel>
      <TabPanel value={value} index={2} >{/*activity_tab_listeParticipant*/}
        <ParticipantTab
          vSelectedActivityID={selectedActivityID}
          selectedActivityName={props.selectedActivityName}
        />
      </TabPanel>
      <TabPanel value={value} index={3} >{/*activity_tab_manageWaitingList*/}
        <WaitingListTab
          vSelectedActivityID={selectedActivityID}
          selectedActivityName={props.selectedActivityName}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={4} >
        <ManagePaymentTab
          vSelectedActivityID={selectedActivityID}
        />
      </TabPanel>
      <TabPanel value={value} index={5} >
        <ConfirmationSettingsTab
          vSelectedActivityID={selectedActivityID}
        />
      </TabPanel> */}
    </span>
  );
}

export default InfosActivityTabs;
  

