import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import http from '../../../http-common'
import TextTable_EN from '../../customComponent/textTable_EN.json'
import TextTable_FR from '../../customComponent/textTable_FR.json'
import { Grid, Tooltip, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MuiDatatableToolBar from '../../muiDatatableToolBar/MuiDatatableToolBar'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import moment from 'moment'
import VisiteForm from './VisiteForm'
import EventBusyIcon from '@material-ui/icons/EventBusy'

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '13px',
  },
  containerAlignement: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingBottom: '3%',
  },
  containerAlignementWithoutPadding: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginBottom: '-2%',
  },
  label: {
    textAlign: 'right',
    marginRight: '3%',
    color: 'blue',
  },
  nonFullTextfield: {
    width: '50%',
  },
  propsInput: {
    cursor: 'not-allowed',
  },
}))

const VisiteTab = (props) => {
  const selectedActivityID = props.vSelectedActivityID
  const classes = useStyles()
  const texts = useSelector((state) => state.texts)
  const langueCharger = texts.langueCharger
  const [visites, setVisites] = useState([])
  const [selectedVisite, setSelectedVisite] = useState(null)
  const [tableTexts, setTableTexts] = useState('')
  const [loadingInfos, setLoadingInfos] = useState(true)
  const [reload, setReload] = useState(false)
  //persist table state
  const [sortOrder, setSortOrder] = useState({
    name: 'Date_Debut',
    direction: 'desc',
  })
  const [rowsPerPage, setRowsPerPage] = useState(20)

  useEffect(() => {
    if (langueCharger === 'FR') {
      setTableTexts(TextTable_FR)
    } else {
      setTableTexts(TextTable_EN)
    }
  }, [tableTexts, langueCharger])

  useEffect(() => {
    var param = {
      activityNum: selectedActivityID,
    }
    http.post('/portail/activities/getVisit', param).then(
      (response) => {
        // console.log(response.data);// rt 01 09 2022
        setVisites(response.data)
        setLoadingInfos(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }, [selectedActivityID, reload])

  useEffect(() => {
    props.setTabDisabled(selectedVisite !== null)
  }, [selectedVisite, props])

  const handleVisitSelection = (currentRowsSelected) => {
    let row = visites[currentRowsSelected[0].dataIndex]
    props.setHideAction(true)
    if (selectedVisite) {
      if (selectedVisite.ID !== row.ID) {
        setSelectedVisite(row)
        window.scrollTo(0, 0)
      } else {
        setSelectedVisite(null)
      }
    } else {
      setSelectedVisite(row)
    }
  }

  const closeVisitForm = () => {
    setSelectedVisite(null)
    props.setHideAction(false)
  }

  const columns = [
    {
      label: 'ID',
      name: 'ID',
      options: {
        viewColumns: false,
        filter: false,
        display: 'excluded',
        download: false,
      },
    },
    //MI 15.02.24, #5153124597
    {
      label: texts.tab_activityVisit_label_titreSession,
      name: 'TitreSession',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.tab_activityVisit_label_periode,
      name: 'ID_Période_6_ID',
      options: {
        sortThirdClickReset: true,
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          //value.Nom
          tableMeta.rowData[13] !== '0000-00-00' ? (
            <div>
              <span>{value.Nom}</span>
              <Tooltip title="Session annulée">
                <EventBusyIcon color="primary" />
              </Tooltip>
            </div>
          ) : (
            value.Nom
          ),
      },
    },
    {
      label: texts.tab_activityVisit_label_date,
      name: 'Date_Debut',
      options: {
        sortThirdClickReset: true,
        sort: true,
        display: props.selectedActivityNature !== 'GRPE' ? true : 'excluded',
        customBodyRender: (value, tableMeta, updateValue) =>
          moment(new Date(value)).format('DD.MM.YYYY'),
      },
    },
    {
      label: texts.tab_activityVisit_label_datecours,
      name: 'DatesCoursTransform',
      options: {
        sortThirdClickReset: true,
        sort: true,
        display: props.selectedActivityNature === 'GRPE' ? true : 'excluded',
        customBodyRender: (value, tableMeta, updateValue) =>
          value
            ? value.map(
                (item, index) => item + (index < value.length - 1 ? ', ' : ''),
              )
            : '',
      },
    },
    {
      label: texts.tab_activityVisit_label_de,
      name: 'Heure_debut',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.tab_activityVisit_label_a,
      name: 'Heure_fin',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.tab_activityVisit_label_capacity,
      name: 'Places',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.tab_activityVisit_label_transport,
      name: 'Transport',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.tab_activityVisit_label_nbAccompagnant,
      name: 'Nb_accompagnateurs',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.visitForm_bulletinLabel,
      name: 'NoBulletin',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.visitForm_salleLabel,
      name: 'Salle',
      options: {
        sortThirdClickReset: true,
        sort: true,
      },
    },
    {
      label: texts.visitForm_IntervenanrLabel,
      name: 'intervenant',
      options: {
        sortThirdClickReset: true,
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value
            ? value.map(
                (item, index) => item + (index < value.length - 1 ? ', ' : ''),
              )
            : '',
      },
    },
    {
      label: texts.visitForm_date_annulation,
      name: 'DateAnnulation',
      options: {
        sortThirdClickReset: true,
        sort: true,
        display: 'excluded',
      },
    },
  ]

  const options = {
    filter: true,
    selectableRows: 'single',
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    responsive: 'simple',
    sortOrder: sortOrder,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [20, 50, 100],
    tableBodyHeight: 'auto',
    fixedHeader: true,
    downloadOptions: {
      filename:
        'export_' +
        texts.label_visites +
        '_' +
        props.selectedActivityName +
        '.csv',
      separator: ';',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let copyData = JSON.parse(JSON.stringify(data)) //copy object
      for (let index = 0; index < copyData.length; index++) {
        //copyData[index].data[1] = moment(new Date(copyData[index].data[1])).format("DD.MM.YYYY")
        //Période est un texte pas une date
        copyData[index].data[1] = copyData[index].data[1].Nom
      }

      return '\uFEFF' + buildHead(columns) + buildBody(copyData)
    },
    customToolbar: () => {
      const newRecord = () => {
        props.setHideAction(true)
        setSelectedVisite({ ID: 0 })
      }
      const element = (
        <Tooltip key={2} title={'Nouvelle session'}>
          <Fab
            className={classes.iconButton}
            size="small"
            color="primary"
            aria-label="add"
            onClick={newRecord}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )

      return <MuiDatatableToolBar hideFilter={true} extraBtn={element} />
    },
    onRowSelectionChange: handleVisitSelection,
    onTableChange: (action, tableState) => {
      //persist table state
      switch (action) {
        case 'sort':
          setSortOrder(tableState.sortOrder)
          break
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage)
          break
        case 'propsUpdate':
          tableState.sortOrder = sortOrder
          tableState.rowsPerPage = rowsPerPage
          break
        default:
          break
      }
    },
    textLabels: tableTexts,
  }

  return loadingInfos ? (
    <LoadingIndicator open={loadingInfos} />
  ) : (
    <div>
      <Grid container className={classes.containerAlignement}>
        <Grid item xs={12}>
          {selectedVisite !== null ? (
            <VisiteForm
              setReloadTypeVisit={props.setReloadTypeVisit}
              reloadTypeVisit={props.reloadTypeVisit}
              setReload={setReload}
              reload={reload}
              closeVisitForm={closeVisitForm}
              selectedActivityID={selectedActivityID}
              vSelectedVisite={selectedVisite}
              selectedActivityNature={props.selectedActivityNature}
            />
          ) : (
            <MUIDataTable
              title={texts.label_visites}
              data={visites}
              columns={columns}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default VisiteTab
