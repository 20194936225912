import React, { useState }from 'react';
import { Button, Card, CardActions, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EmailIcon from '@material-ui/icons/Email';
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStyles } from "./ForgotPassword.css";
import http from "../../http-common";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'

const ForgotPassword = ()  =>{
    const classes = useStyles();
    const history = useHistory();
    const texts = useSelector(state => state.texts);

    const [emailConnexion, setEmailConnexion] = useState("");
    const [emailError, setEmailError]  = useState(false);
    const [emailTextError, setEmailTextError]  = useState("");

    const [snack, setSnack] = React.useState({});

    const validateEmail = (value) => {
        let error;
        if (value==="") {
          setEmailTextError(texts.login_required_field);
          setEmailError(true);
          error = true;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          setEmailTextError(texts.login_email_invalide);
          setEmailError(true);
          error = true;
        } else {
          setEmailTextError('');
          setEmailError(false);
          error = false;
        }
        return error;
    }

    const handleFormChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const idInput = target.id;
        if(idInput === "vConnexionEmail"){
          validateEmail(value);
          setEmailConnexion(value)
        }
    };

    const submitForgotPassword = () => {
        if(emailConnexion===""){
            setEmailTextError(texts.filed_required);
            setEmailError(true);
            return false;
        }
        if (validateEmail(emailConnexion)) {
            return false;
        }
        var param = {
            vForgotPswEmail: emailConnexion,
        }
        http.post(`/portail/user/forgotPsw`, param).then((response) => {
            if (response.data.error) {
                setSnack({ message: texts[response.data.message], show: true, severity: "error" })
            } else {
                setSnack({ message: texts.success_forgot_psw, show: true, severity: "success" })
                history.push('/resetPsw');
            }
        }, (error) => {
            console.log(error);
        });
    }

    const submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            submitForgotPassword();
        }
    }

    return (
        <Grid container style={{marginTop:'5%'}}>
            <Grid item xs={1} sm={4}></Grid>
            <Grid item xs={10} sm={4}>
                <Card>
                    <CardContent >
                        <MySnackbar params={snack} />
                        <Typography className={classes.alignTextCenter} variant="h3" component="h3">{texts.login_forgot_pwd}</Typography>
                        <br/><br/>
                        <TextField 
                            id="vConnexionEmail"
                            name="vConnexionEmail"
                            value={emailConnexion}
                            label={texts.login_username}
                            type="email"
                            variant="outlined"
                            fullWidth
                            required={true}
                            InputProps={{startAdornment: (
                                <InputAdornment position="start"><EmailIcon /></InputAdornment>
                            ),}} 
                            onChange={handleFormChange}
                            error={emailError}
                            helperText={emailTextError}
                            onKeyDown={(e) => submitOnEnter(e)}
                        />
                        <br/><br/>
                        <Link to="/login"><Typography className={classes.alignTextRight} variant="body2" >{texts.forgot_pwd_login}</Typography> </Link>
                    </CardContent>
                    <CardActions >
                        <Button variant="contained" color="primary" fullWidth onClick={submitForgotPassword} >{texts.forgot_pwd_validate}</Button>
                    </CardActions>
                    <br/>
                </Card> 
                </Grid>
            <Grid item xs={1} sm={4}></Grid>
        </Grid>
      
    )
}

  
  export default ForgotPassword
















