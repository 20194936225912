import React, { useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useStyles } from './ResetPassword.css'
import http from '../../http-common'
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import Visibility from '@material-ui/icons/Visibility' //MI 12.12.22, #3416502523
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useEffect } from 'react'

const ResetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const texts = useSelector((state) => state.texts)
  const query = new URLSearchParams(window.location.search)
  let code = ''
  if (query.get('code')) {
    code = query.get('code')
  }

  const [state, setState] = useState({
    resetPsw: '',
    resetPswRepeat: '',
    resetPswCode: code,
  })

  const [snack, setSnack] = React.useState({})

  const [pwdError, setPwdError] = useState(false)
  const [pwdError2, setPwdError2] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [pwdTextError, setPwdTextError] = useState('')
  const [pwdTextError2, setPwdTextError2] = useState('')
  const [codeTextError, setCodeTextError] = useState('')
  const [codeDemandeChangement, setDemandeChangement] = useState(false) //MI 30.11.22, #3416502523
  const [visiblePassword, setVisiblePassword] = React.useState(false) //MI 12.12.22, #3416502523
  const [visiblePassword2, setVisiblePassword2] = React.useState(false)

  //MI 06.02.23, #3923335880 vérifier que le code de mot de passe oublié existe toujours
  const [veriforgotPsw, setVeriforgotPsw] = useState(false)
  const [mesVeriforgotPsw, setMesVeriforgotPsw] = useState('')
  useEffect(() => {
    var param = {
      userCode: state.resetPswCode,
    }
    http.post('/portail/user/forgotPswVerifCode', param).then(
      (response) => {
        setVeriforgotPsw(response.data.error)
        setMesVeriforgotPsw(response.data.message)

        console.log(
          'message',
          texts.invalid_link_reset_forgot_psw,
          'erreur',
          response.data.error,
        )
      },
      (error) => {
        console.log(error)
      },
    )
  }, [])

  const handleFormChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setState({ ...state, [name]: value })
  }

  const validateResetPwdForm = () => {
    let errorOccured = false
    setCodeTextError('')
    setCodeError(false)
    if (state.resetPswCode === '') {
      setCodeTextError(texts.filed_required)
      setCodeError(true)
      errorOccured = true
    }
    setPwdError(false)
    setPwdTextError('')
    if (state.resetPsw === '') {
      setPwdTextError(texts.filed_required)
      setPwdError(true)
      errorOccured = true
    } else if (
      !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/i.test(state.resetPsw)
    ) {
      setPwdTextError(texts.error_pwd_constraints)
      setPwdError(true)
      errorOccured = true
    }
    setPwdError2(false)
    setPwdTextError2('')
    if (state.resetPswRepeat === '') {
      setPwdTextError2(texts.filed_required)
      setPwdError2(true)
      errorOccured = true
    } else if (
      !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/i.test(state.resetPsw)
    ) {
      setPwdTextError2(texts.error_pwd_constraints)
      setPwdError2(true)
      errorOccured = true
    }

    if (state.resetPsw !== state.resetPswRepeat) {
      errorOccured = true
      setPwdError(true)
      setPwdError2(true)
      setPwdTextError(texts.error_pwd_concordance)
      setPwdTextError2(texts.error_pwd_concordance)
    }

    return errorOccured
  }

  const submitResetPassword = () => {
    if (validateResetPwdForm()) {
      return false
    }

    var param = {
      resetPswCode: state.resetPswCode,
      resetPsw: state.resetPsw,
      resetPswRepeat: state.resetPswRepeat,
    }
    http.post(`/portail/user/forgotPswReset`, param).then(
      (response) => {
        if (response.data.error) {
          setSnack({
            message: texts[response.data.message],
            show: true,
            severity: 'error',
          })
        } else {
          setSnack({
            message: texts.success_reset_pwd,
            show: true,
            severity: 'success',
          })
          setTimeout(function () {
            history.push('/login')
          }, 5000) //MI 30.11.22, #3416502523
          // history.push('/login');
        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) {
      submitResetPassword()
      //setDemandeChangement(false); //MI 30.11.22, #3416502523
    }
  }

  //MI 30.11.22, #3416502523
  // const submitOnClic = () => {
  //   setDemandeChangement(true)
  // }
  //MI 12.12.22, #3416502523
  const handleClickShowPassword = () => {
    setVisiblePassword((vp) => !vp)
  }
  const handleClickShowPassword2 = () => {
    setVisiblePassword2((vp) => !vp)
  }

  return (
    <Grid container style={{ marginTop: '5%' }}>
      <Grid item xs={1} sm={4}></Grid>
      <Grid item xs={10} sm={4}>
        <Card>
          <CardContent>
            <MySnackbar params={snack} />
            {/* MI 03.02.23, #3923335880 */}
            {state.resetPswCode === '' ? (
              <>
                <Typography
                  className={classes.alignTextCenter}
                  variant="h3"
                  component="h3"
                >
                  {texts.login_forgot_pwd_new_step1}
                </Typography>
                <br />
                <br />
                <Typography
                  className={classes.alignTextCenter}
                  variant="h5"
                  component="h5"
                >
                  {texts.login_forgot_pwd_new_comp_step1}
                </Typography>
                <br />
                <br />
                {/* <TextField
                  id="resetPswCode"
                  name="resetPswCode"
                  value={state.resetPswCode}
                  label={texts.login_forgot_pwd_code}
                  type="text"
                  variant="outlined"
                  fullWidth
                  required={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleFormChange}
                  error={codeError}
                  helperText={codeTextError}
                  onKeyDown={(e) => submitOnEnter(e)}
                /> */}
              </>
            ) : null}

            {/* MI 28.11.22, #3416502523  
            {state.resetPswCode === '' ? ( //|| codeDemandeChangement === false
              <>
                <Typography
                  className={classes.alignTextCenter}
                  variant="h3"
                  component="h3"
                >
                  {texts.login_forgot_pwd_new_step1}
                </Typography>
                <br />
                <br />
                <Typography
                  className={classes.alignTextCenter}
                  variant="h5"
                  component="h5"
                >
                  {texts.login_forgot_pwd_new_comp_step1}
                </Typography>
                <br />
                <br />
                <TextField
                  id="resetPswCode"
                  name="resetPswCode"
                  value={state.resetPswCode}
                  label={texts.login_forgot_pwd_code}
                  type="text"
                  variant="outlined"
                  fullWidth
                  required={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleFormChange}
                  error={codeError}
                  helperText={codeTextError}
                  onKeyDown={(e) => submitOnEnter(e)}
                />
              </>
            ) : (
              <>
                <Typography
                  className={classes.alignTextCenter}
                  variant="h3"
                  component="h3"
                >
                  {texts.login_forgot_pwd_new_step2}
                </Typography>
                <br />
                <br />
                <Typography
                  className={classes.alignTextCenter}
                  variant="h5"
                  component="h5"
                >
                  {texts.login_forgot_pwd_new_comp_step2}
                </Typography>
              </>
            )}*/}

            {/* <br /><br />
                        <TextField
                            id="resetPswCode"
                            name="resetPswCode"
                            value={state.resetPswCode}
                            label={texts.login_forgot_pwd_code}
                            type="text"
                            variant="outlined"
                            fullWidth
                            required={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><VpnKeyIcon /></InputAdornment>
                                ),
                            }}
                            onChange={handleFormChange}
                            error={codeError}
                            helperText={codeTextError}
                            onKeyDown={(e) => submitOnEnter(e)} 
                        />  */}
            {/* MI 28.11.22, #3416502523  */}
            {/* MI 03.02.23, #3923335880 */}
            {state.resetPswCode !== '' && veriforgotPsw === false ? (
              <>
                <Typography
                  className={classes.alignTextCenter}
                  variant="h3"
                  component="h3"
                >
                  {texts.login_forgot_pwd_new_step2}
                </Typography>
                <br />
                <br />
                <Typography
                  className={classes.alignTextCenter}
                  variant="h5"
                  component="h5"
                >
                  {texts.login_forgot_pwd_new_comp_step2}
                </Typography>
                <br />
                <br />
                <TextField
                  id="resetPsw"
                  name="resetPsw"
                  value={state.resetPsw}
                  label={texts.login_forgot_pwd_new}
                  type={!visiblePassword ? 'password' : 'text'} //MI 12.12.22, #3416502523 //type="password"
                  variant="outlined"
                  fullWidth
                  required={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    //MI 12.12.22, #3416502523
                    endAdornment: (
                      <InputAdornment position="end">
                        {(() => {
                          if (visiblePassword) {
                            return (
                              <VisibilityOff
                                onClick={handleClickShowPassword}
                                className="iconePasswordVisible"
                              />
                            )
                          }
                          return (
                            <Visibility
                              onClick={handleClickShowPassword}
                              className="iconePasswordVisible"
                            />
                          )
                        })()}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleFormChange}
                  error={pwdError}
                  helperText={pwdTextError}
                  onKeyDown={(e) => submitOnEnter(e)}
                />
                <br />
                <br />
                <TextField
                  id="resetPswRepeat"
                  name="resetPswRepeat"
                  value={state.resetPswRepeat}
                  label={texts.login_forgot_pwd_new_repeat}
                  type={!visiblePassword2 ? 'password' : 'text'} //MI 12.12.22, #3416502523// type="password"
                  variant="outlined"
                  fullWidth
                  required={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    //MI 12.12.22, #3416502523
                    endAdornment: (
                      <InputAdornment position="end">
                        {(() => {
                          if (visiblePassword2) {
                            return (
                              <VisibilityOff
                                onClick={handleClickShowPassword2}
                                className="iconePasswordVisible"
                              />
                            )
                          }
                          return (
                            <Visibility
                              onClick={handleClickShowPassword2}
                              className="iconePasswordVisible"
                            />
                          )
                        })()}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleFormChange}
                  error={pwdError2}
                  helperText={pwdTextError2}
                  onKeyDown={(e) => submitOnEnter(e)}
                />
              </>
            ) : (
              //MI 06.02.23, #3923335880
              <>
                {veriforgotPsw ? (
                  <Typography
                    className={classes.alignTextCenter}
                    variant="h5"
                    component="h5"
                  >
                    {texts.invalid_link_reset_forgot_psw}
                  </Typography>
                ) : null}
              </>
            )}
            <br />
            <br />
          </CardContent>
          <CardActions>
            {/* MI 03.02.23, #3923335880 */}
            {state.resetPswCode !== '' && veriforgotPsw === false ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitResetPassword}
              >
                {texts.forgot_pwd_validate}
              </Button>
            ) : null}

            {/* MI 28.11.22, #3416502523 
            {state.resetPswCode === '' || codeDemandeChangement === false ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitOnClic}
              >
                {texts.forgot_pwd_change_request}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitResetPassword}
              >
                {texts.forgot_pwd_validate}
              </Button>
            )}*/}
          </CardActions>
          <br />
        </Card>
      </Grid>
      <Grid item xs={1} sm={4}></Grid>
    </Grid>
  )
}

export default ResetPassword
