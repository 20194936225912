import React, { useState, useEffect } from 'react';
import { Button, Card, CardActions, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./Connexion.css";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar';
import { MemberChoice } from '../../components/memberChoice/MemberChoice';
import http from "../../http-common";
import { retrieveSession } from '../../actions/session';
import { toogleLoader } from '../../actions/loading';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



const ConnexionForm = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const texts = useSelector(state => state.texts);
    const loading = useSelector(state => state.loading);
    const [emailConnexion, setEmailConnexion] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailTextError, setEmailTextError] = useState("");
    const [pwdConnexion, setPwdConnexion] = useState("");
    const [pwdError, setPwdError] = useState(false);
    const [pwdTextError, setPwdTextError] = useState("");

    const [snack, setSnack] = React.useState({});
    const [memberChoiceOpen, setMemberChoiceOpen] = React.useState(false);
    const [memberChoiceList, setMemberChoiceList] = React.useState([]);
    const [visiblePassword, setVisiblePassword] = React.useState(false);
    

    useEffect(() => {
        dispatch(toogleLoader())
        let promise = dispatch(retrieveSession());
        promise.then((response) => {
            if (response.data.users) {
                if (response.data.users.members.length === 1) {
                    if (response.data.connected) {
                        history.push("home")
                    }
                }
            }
            dispatch(toogleLoader())
        });

    }, [history, dispatch]);

    const validateEmail = (value) => {
        let error;
        if (value === "") {
            setEmailTextError(texts.login_required_field);
            setEmailError(true);
            error = true;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setEmailTextError(texts.login_email_invalide);
            setEmailError(true);
            error = true;
        } else {
            setEmailTextError('');
            setEmailError(false);
            error = false;
        }
        return error;
    }

    const handleFormChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const idInput = target.id;
        if (idInput === "vConnexionEmail") {
            validateEmail(value);
            setEmailConnexion(value)
        }
        if (idInput === "vConnexionPassword") {
            if (value.length >= 6) {
                setPwdTextError("");
                setPwdError(false);
            }
            setPwdConnexion(value)
        }
    };

    const logUserIn = () => {
        if (emailConnexion === "") {
            setEmailTextError(texts.filed_required);
            setEmailError(true);
        }
        if (pwdConnexion === "") {
            setPwdTextError(texts.filed_required);
            setPwdError(true);
        }

        var param = {
            vConnexionEmail: emailConnexion,
            vConnexionPassword: pwdConnexion,
        }
        http.post(`/portail/user/connexion`, param).then((response) => {
            switch (response.data.status) {
                case 0:
                    if (response.data.users.members.length > 1) {
                        setMemberChoiceList(response.data.users.members)
                        setMemberChoiceOpen(true)
                    } else {
                        history.push('/' + response.data.redirect);
                    }
                    break;
                case -10:
                    setSnack({ message: texts.error_pwd, show: true, severity: "error" })
                    break;
                case -20:
                    setSnack({ message: texts.error_member, show: true, severity: "error" })
                    break;
                case -30:
                    setSnack({ message: texts.error_account_activate, show: true, severity: "error" })
                    break;
                case -40:
                    setSnack({ message: texts.error_login, show: true, severity: "error" })
                    break;
                default:
                    break;
            }
        }, (error) => {
            console.log(error);
        });
    }

    const submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            logUserIn();
        }
    }

    const handleClickShowPassword = ()=>{
        setVisiblePassword(vp=>!vp);
    }


    return (
        !loading ? <Grid container style={{ marginTop: '5%' }}>
            <Grid item xs={1} sm={4}></Grid>
            <Grid item xs={10} sm={4}>
                <Card>
                    <CardContent >
                        <MySnackbar params={snack} />
                        <MemberChoice open={memberChoiceOpen} members={memberChoiceList} />
                        <Typography className={classes.alignTextCenter} variant="h3" component="h3">{texts.login_connexion}</Typography>
                        <br /><br />
                        <div>
                            <span style={{ fontSize: 18, fontWeight: 'bold' }} >
                                Connectez-vous au portail de l'Uni3 pour vous inscrire aux activités si vous êtes déjà adhérent ou cliquez sur "Devenir adhérent".
                            </span>
                        </div>
                        <br /><br />
                        <TextField
                            id="vConnexionEmail"
                            name="vConnexionEmail"
                            value={emailConnexion}
                            label={texts.login_username}
                            type="email"
                            variant="outlined"
                            fullWidth
                            required={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><EmailIcon /></InputAdornment>
                                ),
                            }}
                            onChange={handleFormChange}
                            error={emailError}
                            helperText={emailTextError}
                            onKeyDown={(e) => submitOnEnter(e)}
                        />
                        <br /><br />
                        <TextField
                            id="vConnexionPassword"
                            name="vConnexionPassword"
                            value={pwdConnexion}
                            label={texts.login_pdw}
                            type={!visiblePassword ? "password": 'text'}
                            variant="outlined"
                            fullWidth
                            required={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><LockIcon /></InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(() => {
                                            if (visiblePassword) {
                                                return (
                                                    <VisibilityOff onClick={handleClickShowPassword} className='iconePasswordVisible' />
                                                )
                                            }
                                            return <Visibility onClick={handleClickShowPassword} className='iconePasswordVisible' />;
                                        })()}
                                    </InputAdornment>
                                ),

                            }}
                            onChange={handleFormChange}
                            error={pwdError}
                            helperText={pwdTextError}
                            onKeyDown={(e) => submitOnEnter(e)}
                        />
                        <br /><br />
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={6}>
                                <Link to="/join"><Typography className={classes.alignTextLeft} variant="body2" >{texts.login_adhering}</Typography> </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/forgotPsw"><Typography className={classes.alignTextRight} variant="body2" >{texts.login_forgot_pwd}</Typography> </Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions >
                        <Button variant="contained" color="primary" fullWidth onClick={logUserIn} >{texts.login_btn_connexion}</Button>
                    </CardActions>
                    <br />
                </Card>
            </Grid>
            <Grid item xs={1} sm={4}></Grid>
        </Grid> : null
    )
}


export default ConnexionForm

