
import React from 'react';
import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import InfosMemberTab from './Tabs/InfosMemberTabs'

const useStyles = makeStyles((theme) => ({
    cardBackground: {
        background: "#564146",
        color:"#FFF"
    },
    gridContainer:{
        justifyContent: 'center',
        alignItems: 'center',
        flex:1
    },
    alignCenter:{
        textAlign: 'center'
    },
    alignRight:{
        textAlign: 'right'
    },
    label:{
        textAlign:"left", 
    },
    containerAlignementPadding:{
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        marginBottom: '0%'
    },
    input: {
        "& .MuiInputBase-root": {
            color: '#808080',
            cursor: 'not-allowed'
        },
        cursor: 'not-allowed'
    },
}));

const InfosMember = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMember =props.vSelectedMember;
   
    return (
        <Card>
            <CardContent className={classes.cardBackground}>
                <Grid container spacing={1}>
                    <Grid container className={classes.gridContainerstyle}>
                        <Grid item xs={5} >
                            <h2>{selectedMember.Nom} {selectedMember.Prenom}</h2>
                        </Grid>
                         {/*<Grid item xs={3} className={classes.alignCenter}>
                            <Grid container className={classes.containerAlignementPadding}>
                                <Grid item  xs={1} className={classes.label}>
                                    <Checkbox
                                        checked={selectedMemberInactive} 
                                        id="vFreeEntry"
                                        color="default"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {texts.member_inactive}
                                </Grid>
                            </Grid> 
                        </Grid>*/}
                        <Grid item xs={7} className={classes.alignRight}>
                            <h2>{texts.member_number}: {selectedMember.ID_Affiche}</h2>
                        </Grid>
                    </Grid> 
                </Grid>
            </CardContent>
            <CardActions>
                <InfosMemberTab 
                    vSelectedMemberID={selectedMember.ID}
                />  
            </CardActions>
        </Card>     
    )
}

export default InfosMember;