import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Grid, Tooltip, Fab, Dialog, DialogContent, DialogActions, Button, TextField, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import http from "../../http-common";
import TextTable_EN from '../../components/customComponent/textTable_EN.json';
import TextTable_FR from '../../components/customComponent/textTable_FR.json';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { CustomDialog } from '../../components/customDialog/CustomDialog'
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import CustomAutocomp from '../../components/customAutocomp/CustomAutocomp';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    filterDates: {
        paddingBottom: theme.spacing(2),
    },
    iconButton: {
        marginLeft: 15,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}));


export const Intervenants = () => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const langueCharger = texts.langueCharger;
    const [tableTexts, setTableTexts] = useState("")
    const [intervenantsList, setIntervenantsList] = useState([])//tableau données datatable
    const [selectedIntervenant, setSelectedIntervenant] = useState(null)//current row
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [snack, setSnack] = React.useState({});
    const [myDialog, setMyDialog] = React.useState({ show: false, title: "", body: "", extraButtons: null, subtitle: null, jsxComponent: null });
    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: 'Nom', direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(20)
    //gestion erreur champ obligatoire
    const [fieldError, setFieldError] = useState([false, false])//Nom, Prénom

    useEffect(() => {
        if (langueCharger === "FR") {
            setTableTexts(TextTable_FR);
        } else {
            setTableTexts(TextTable_EN);
        }
    }, [tableTexts, langueCharger]);

    useEffect(() => {
        setLoading(true)
        http.get('/portail/intervenant/getAll').then((response) => {
            setIntervenantsList(response.data.liste)
            setLoading(false)
        }, (error) => {
            console.log(error);
            setLoading(false)
        });
    
    }, [reload]);

    const handleLineSelection = (currentRowsSelected) => {
        setFieldError([false, false])
        let row = intervenantsList[currentRowsSelected[0].dataIndex]
        console.log(row)
        setSelectedIntervenant(row)
    };

    const handleChangeForm = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setSelectedIntervenant({ ...selectedIntervenant, [name]: value });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setSelectedIntervenant(null)
    }

    const saveRecord = () => {
        let fError = [false, false]
        if (selectedIntervenant.Nom === "") {
            fError[0] = true
        }
        if (selectedIntervenant.Prenom === "") {
            fError[1] = true
        }
        let checkError = fError.every(v => v === false);

        if (!checkError) {
            setFieldError(fError)
        } else {
            //SAVE
            setLoading(true)

            let params = { row: JSON.stringify(selectedIntervenant) };

            http.post('/portail/intervenant/save', params).then((response) => {
                if (response.data.error) {
                    setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                } else {
                    setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                    setReload(!reload)
                    setSelectedIntervenant(null)
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });
        }
    }

    const handleDelete = () => {
        let pTitle = texts.delete_confirm_btn;
        let pBody = texts.delete_confirm_btn_text;
        function deleteConfirm() {
            setLoading(true)
            let params = { row: JSON.stringify(selectedIntervenant) };

            http.post('/portail/intervenant/delete', params).then((response) => {
                if (response.data.error) {
                    setSnack({ message: texts[response.data.message], show: true, severity: "error" })
                } else {
                    setSnack({ message: texts[response.data.message], show: true, severity: "success" })
                    setReload(!reload)
                    setSelectedIntervenant(null)
                    setMyDialog({ show: false })
                }
                setLoading(false)
            }, (error) => {
                setLoading(false)
                console.log(error);
            });
        }
        let pExtraButtons = <Button onClick={deleteConfirm} style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }} variant="contained" type="button">{texts.delete_btn}</Button>
        setMyDialog({ show: true, title: pTitle, body: pBody, extraButtons: pExtraButtons, subtitle: null, jsxComponent: null })
    }

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
                download: false,
            }
        },
        {
            label: "Nom",
            name: "Nom",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: "Prénom",
            name: "Prenom",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: "Email",
            name: "Email",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: "Téléphone",
            name: "Telephone",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: "Description",
            name: "Description",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
            },
        },
        {
            label: texts.autoCompMember_label,
            name: "membre",
            options: {
                sortThirdClickReset: true,
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? value.text : ""
                )
            },
        },
    ];

    const options = {
        filter: false,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: handleLineSelection,
        responsive: 'simple',
        sortOrder: sortOrder,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        tableBodyHeight: 'auto',
        fixedHeader: true,
        viewColumns: false,
        downloadOptions: {
            filename: 'export_' + texts.title_intervenants + '.csv',
            separator: ';',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {
                //catégories
                copyData[index].data[6] = copyData[index].data[6] ? copyData[index].data[6].text:""
            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        //  onRowClick: handleLineSelection,
        textLabels: tableTexts,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        customToolbar: () => {
            const newRecord = () => {
                setFieldError([false, false])
                setSelectedIntervenant({ ID: 0, Nom:"", Prenom:"",Email :"",Telephone:"",Description:"" })
            }
            const element = <Tooltip title={"Nouvel adhérent"}>
                    <Fab className={classes.iconButton} size="small" color="primary" aria-label="add" onClick={newRecord}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            return (
                <MuiDatatableToolBar extraBtn={element} hideFilter={true} />
            );
        },
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
    };

    const handleAutocompChange = autocompName => (event, value) => {
        setSelectedIntervenant({ ...selectedIntervenant, [autocompName]: value });
    };

    const autoCompMember = {};
    autoCompMember.url = "/portail/enum/getMembers";
    autoCompMember.autoCompState = selectedIntervenant?selectedIntervenant.membre:null;
    autoCompMember.autoCompLabel = texts.autoCompMember_label
    autoCompMember.autoCompName = "membre";
    autoCompMember.multiple = false;
    autoCompMember.handleAutocompChange = handleAutocompChange;

    return (
        <React.Fragment>
            <CustomDialog params={myDialog} />
            <MySnackbar params={snack} />
            <LoadingIndicator open={loading} />
            <main className={classes.content}>
                <span className={classes.toolbar}> </span>
                <Grid container >
                    <Grid item sm={12} >
                        <MUIDataTable
                            title={texts.title_intervenants}
                            data={intervenantsList}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </main>
            {selectedIntervenant !== null &&
                <Dialog
                    // fullScreen
                    fullWidth={true}
                    maxWidth="lg"
                    open={selectedIntervenant !== null}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                <DialogTitle style={{ background: "#564146", color: "#FFFFFF" }}>{selectedIntervenant.ID>0 ? [selectedIntervenant.Nom, " ",selectedIntervenant.Prenom] : "Nouveau"}</DialogTitle>
                    <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="dense"
                                name="Nom"
                                label={texts.tableIntervenant_fieldNom}
                                required={true}
                                fullWidth
                                value={selectedIntervenant.Nom}
                                onChange={handleChangeForm}
                                error={fieldError[0]}
                                helperText={fieldError[0] ? texts.required_field : null}
                        />
                        </Grid>
                        <Grid item md={6} xs={12}>
                                <TextField
                                    margin="dense"
                                    name="Prenom"
                                    label={texts.tableIntervenant_fieldPrenom}
                                    required={true}
                                    fullWidth
                                    value={selectedIntervenant.Prenom}
                                    onChange={handleChangeForm}
                                    error={fieldError[1]}
                                    helperText={fieldError[1] ? texts.required_field : null}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="dense"
                                name="Email"
                                label={texts.tableIntervenant_fieldEmail}
                                fullWidth
                                value={selectedIntervenant.Email}
                                onChange={handleChangeForm}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="dense"
                                name="Telephone"
                                label={texts.tableIntervenant_fieldTelephone}
                                fullWidth
                                value={selectedIntervenant.Telephone}
                                onChange={handleChangeForm}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="dense"
                                name="Description"
                                label={texts.tableIntervenant_fieldDescription}
                                fullWidth
                                value={selectedIntervenant.Description}
                                onChange={handleChangeForm}
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid style={{ paddingTop: "12px" }} item md={6} xs={12}>
                            <CustomAutocomp params={autoCompMember} />
                        </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {selectedIntervenant !== null ? selectedIntervenant.ID > 0 ? <Button className={classes.btnClass} variant="contained" onClick={handleDelete} style={{ backgroundColor: '#9A0036', color: '#FFFFFF' }}>{texts.delete_btn}</Button> : null : null}
                        <Button className={classes.btnClass} variant="contained" onClick={handleClose}>Annuler</Button>
                        <Button className={classes.btnClass} variant="contained" onClick={saveRecord} color="primary">Enregistrer</Button>
                    </DialogActions> 
                </Dialog>}
        </React.Fragment>
    )

}

export default Intervenants;