import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import http from "../../../http-common";
import { Checkbox, Grid, TextField, FormControlLabel} from '@material-ui/core';
import LoadingIndicator from "../../loadingIndicator/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
    container: {
        fontSize:'13px'
    }, 
    containerAlignement: {
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        paddingBottom: '3%', 
    }, 
    containerAlignementWithoutPadding:{
        justifyContent: 'center',
        alignItems: 'center',
        flex:1,
        marginBottom: '-2%'

    },
    label:{
        textAlign:"right", 
        marginRight:'3%',
        color:"blue"
    }, 
    nonFullTextfield:{
         width:"50%"
    }, 
    propsInput: {
        cursor: 'not-allowed'
    }
}));

const  IdentityTab = (props) => {
    const classes = useStyles();
    const texts = useSelector(state => state.texts);
    const selectedMemberID =props.vSelectedMemberID;

    const [loadingInfos, setLoadingInfos] = useState(true);
    const [infosAdherent, setInfosAdherent] = useState({})
    const [ckUnsubNews, setCkUnsubNews] = useState(true);
    const [ckUnsubMail, setCkUnsubMail] = useState(true);

    useEffect(() => {
        setLoadingInfos(true)
        var param = {
            vMember: selectedMemberID,
        }
        http.post(`/portail/members/getIdentity`, param).then((response) => {
            setInfosAdherent(response.data)
            setCkUnsubNews(response.data.UnsuscribeNewsletter)
            setCkUnsubMail(response.data.UnsuscribeMailing)
            setLoadingInfos(false)
            // return;
        }, (error) => {
            console.log(error);
        });
    }, [selectedMemberID]);
  
    return (
        loadingInfos ? <LoadingIndicator open={loadingInfos } />
            :
        <div>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                        <TextField label={texts.label_title} id="vTitle" fullWidth disabled inputProps={{ className: classes.propsInput}} value={infosAdherent.Gender} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_name} id="vName" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_firstname} id="vFirstname" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Firstname} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_type}  id="vType" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Type} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_dateRegistration} id="vDateRegistration" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.DateRegistration} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_dateResignation} id="vDateResignation" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.DateResignation} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_email} id="vEmail" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Email} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_address1} id="vAddress1" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Address1} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_address2} id="vAddress2" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Address2} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_zip} id="vZip" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Zip} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_city} id="vCity" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.City} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_phone} id="vPhone" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Phone} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_mobile} id="vMobile" fullWidth disabled inputProps={{ className: classes.propsInput }} value={infosAdherent.Mobile} />
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ckUnsubMail}
                                name="unsubscribeEmail"
                                id="unsubscribeEmail"
                                color="primary"
                                disabled={true}
                            />
                        }
                        label={texts.label_unsubscribeMailing}
                        labelPlacement="start"
                    />
                </Grid> 
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ckUnsubNews}
                                name="unsuscribeNewsletter"
                                id="unsuscribeNewsletter"
                                color="primary"
                                disabled={true}
                            />
                        }
                        label={texts.label_unsubscribeNewsletter}
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_category} id="vCatType" disabled className={classes.nonFullTextfield} inputProps={{ className: classes.propsInput }} value={infosAdherent.CategoryCode} />
                    <TextField label="&nbsp;" id="vCat" fullWidth disabled className={classes.nonFullTextfield} inputProps={{ className: classes.propsInput }} value={infosAdherent.Category} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={texts.label_spouseNumber} id="vSpouseNum" disabled className={classes.nonFullTextfield} inputProps={{ className: classes.propsInput }} value={infosAdherent.NumSpouse} />
                    <TextField label="&nbsp;" id="vSpouseName" fullWidth disabled className={classes.nonFullTextfield} inputProps={{ className: classes.propsInput }} value={infosAdherent.NameSpouse} />
                </Grid>
            </Grid>
        </div>
    )
}
  
export default IdentityTab;
